import gql from 'graphql-tag';
import { TIMEZONE } from 'utils/constants/default';
import { clientsFragment } from 'api/graphql/v2/fragments/Client';
import { eventsFragments } from 'api/graphql/v2/fragments/Events';

export const GET_CLIENT_LIST = gql`
  query getClientsList($from: Int, $size: Int, $search: String, $sort: String) {
    clientsList(from: $from, size: $size, search: $search, sort: $sort) {
      clients {
        isActive
        id
        firstName
        lastName
        name
        profile {
          id
          dob
          gender
          phenoType
        }
        clinic {
          id
          name
          abbreviation
        }
      }
      count
    }
  }
`;

export const GET_CLIENT_Roster_List = gql`
  query getClientRoster(
    $size: Int
    $from: Int
    $clinic: [Int!]
    $status: [Boolean!]
    $availability: [String!]
    $languages: [String!]
    $programs: [String!]
    $additionalFilter: [AvailabilityFilterInput!]
    $search: Int
  ) {
    clientRoster(
      from: $from
      size: $size
      clinic: $clinic
      status: $status
      availability: $availability
      languages: $languages
      additionalFilter: $additionalFilter
      search: $search
      programs: $programs
    ) {
      count
      clients {
        id
        availabilityLastModificationDate
        isActive
        firstName
        petAllergies
        lastName
        restrictedProviders {
          id
          name
          displayName
          email
        }
        corticaPrograms {
          id
          programName
        }
        clientRosterNotes {
          id
          actionBy
          actionAt
          note
        }
        languages {
          id
          name
        }
        clinic {
          id
          abbreviation
        }
        profile {
          id
          gender
          dob
          homePhone
          personalEmail
          cellPhone
        }
        dayAvailabilities {
          id
          dayOfWeek
          startTime
          endTime
        }
      }
      clientCurrentProvidersJson
    }
  }
`;

export const ADD_AXON_CLIENT_NOTES = gql`
  mutation($note: AxonCarePlanNotesInput!) {
    addClientNotes(data: $note) {
      id
      note
      actionBy
      actionAt
      createdAt
      client {
        id
      }
    }
  }
`;

export const GET_CLIENT_INITIAL_LOAD_DATA = gql`
  query {
    getClientInitialLoadData {
      petAllergies {
        id
        value
      }

      providerLocations {
        id
        title
        color
        borderColor
      }
      filterList {
        corticaProgram {
          id
          programName
        }
        languages {
          id
          name
        }

        availabilityTime {
          title
        }

        clinicOptionsList {
          id
          name
          abbreviation
        }
      }
      filterSelections {
        id
        typeName
      }
    }
  }
`;

export const GET_CLIENT_EVENTS = gql`
  query (
    $clientId: Int!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    clientEvents(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
      timeZone: "${TIMEZONE}"
    ) {
    ...EventData
    }
  }
  ${eventsFragments.eventData}
`;

export const GET_CLINIC_CLIENTS = gql`
  query clinicClients($from: Int, $size: Int, $sort: String, $clinicId: Int!) {
    clinicClients(from: $from, size: $size, sort: $sort, clinicId: $clinicId) {
      clients {
        id
        firstName
        lastName
        name
        profile {
          id
          dob
          gender
          phenoType
        }
        clinic {
          id
        }
      }
      count
    }
  }
`;

export const GET_CLIENT_FORM_FIELDS = gql`
  query($id: Int!) {
    client(id: $id) {
      ...ClientData
      clinic {
        id
        name
      }
      addresses {
        ...ClientAddressData
      }
      profile {
        ...ClientProfile
      }
      usualProvider {
        id
        firstName
        lastName
      }
    }
  }
  ${clientsFragment.ClientData}
  ${clientsFragment.AddressData}
  ${clientsFragment.ClientProfile}
`;

export const GET_CLIENT_BASIC_DATA = gql`
  query($id: Int!) {
    client(id: $id) {
      ...ClientData
      profile {
        ...ClientProfile
      }
    }
  }
  ${clientsFragment.ClientData}
  ${clientsFragment.ClientProfile}
`;

export const GET_CLIENT_FORM_DATA = gql`
  {
    clinicsListWithProviders {
      id
      name
      abbreviation
      providers {
        id
        firstName
        lastName
      }
    }
    patientRelationships {
      id
      value
    }
    clientTypes {
      id
      value
      label
    }
  }
`;

export const GET_CLIENT_ADDRESSES = gql`
  query($clientId: Int!) {
    clientAddresses(clientId: $clientId) {
      id
      addressLine1
      addressLine2
      state
      city
      name
      zipCode
      country
      __typename @skip(if: true)
    }
  }
`;
export const GET_CLIENT_PROFILE = gql`
  query($id: Int!, $startDate: DateTime!, $endDate: DateTime! ,$clientIds: [Int!]!) {
    clientAvailabilityStatusOptions {
      id
      value
    }
    # todo: add waitlist and blocked dates
    clientProfile(id: $id) {
      ...ClientData
      name
      availabilityLastModificationDate
      profile {
        ...ClientProfile
      }
      clinic {
        id
        name
        abbreviation
      }
    }
    clientAvailability(
      clientIds: $clientIds
      timeZone: "${TIMEZONE}"
      startDate: $startDate
      endDate: $endDate
    ) {
      blockedDates {
        id
        startDate
        endDate
      }
      availabilityLastModificationDate
    }
    allAppointmentTypes(includeAdminTypes: false) {
      id
      title
      eventType {
        id
        name
      }
      appointmentSubTypes {
        id
        title
        DTSetting {
          id
          duration
        }
        MEDSetting {
          id
          duration
        }
      }
    }
  }
  ${clientsFragment.ClientData}
  ${clientsFragment.ClientProfile}
`;
export const GET_CLIENT_AVAILABILITY = gql`
  query($clientIds: [Int!]!, $startDate: DateTime!, $endDate: DateTime!) {
    clientAvailability(
      clientIds: $clientIds
      timeZone: "${TIMEZONE}"
      startDate: $startDate
      endDate: $endDate
    ) {
      availabilities{
        ...ClientAvailabilities
      }
      availabilityStatus
      blockedDates {
        id
        startDate
        endDate
      }
      availabilityLastModificationDate
    }
    clientAvailabilityStatusOptions{
      id
      value
    }
  
  }
  ${clientsFragment.clientAvailabilities}
`;

export const GET_CLIENT = gql`
  query($id: Int!) {
    clientProfile(id: $id) {
      ...ClientData
      name
      profile {
        ...ClientProfile
      }
      clinic {
        id
        name
        abbreviation
      }
    }
  }
  ${clientsFragment.ClientData}
  ${clientsFragment.ClientProfile}
`;

export const GET_CLIENT_AVAILABILITYV2 = gql`
  query($id: Int!) {
    clientDayAvailabilities(clientId: $id) {
      id
      dayOfWeek
      startTime
      endTime
      location
    }
  }
`;

export const GET_CLIENT_CLINIC_NETWORK = gql`
  query($id: Int!) {
    clientClinicNetwork(id: $id) {
      id
      abbreviation
    }
  }
`;
