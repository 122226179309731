import { Popover } from 'antd';
import { EditOutlined, FormOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import { waitListClientInfo } from 'model/v2/waitList';
import {
  WaitListPopOver,
  ActionPopOverStyle
} from 'views/components/waitlistPage/waitlist.styled';
import {
  WaitListPopOverAvailHelper,
  checkIsAbaAppointment,
  getCarePlanApptType
} from 'helpers/clientWaitlistHelper';
import {
  ROUTE_PARAMS,
  ROUTE_PATHS,
  TARGET_BLANK
} from 'utils/constants/routes';
import { Link } from 'react-router-dom';
import { appointmentTypeCheck } from 'helpers/clientWaitlistHelper';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';

interface Props {
  displayContent: string;
  clientInfo: waitListClientInfo;
  popOverType: string;
  providerInfo?: any;
  setSidebarData: any;
  sidebarData: any;
  setActiveTab?: (tab: string) => void;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  trigger?: 'hover' | 'focus' | 'click';
}

const PopOverComp: React.FC<Props> = ({
  displayContent,
  clientInfo,
  popOverType,
  providerInfo,
  setSidebarData,
  sidebarData,
  setActiveTab,
  setShowSidebar,
  trigger,
  ...restProps
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleAddNoteClick = () => {
    setShowSidebar(true);
    setActiveTab?.('notes');
    setSidebarData(sidebarData);
    setIsVisible(false);
  };

  const handleEditClick = () => {
    setShowSidebar(true);
    setSidebarData(sidebarData);
    setActiveTab?.('edit');
    setIsVisible(false);
  };

  let days: Record<number, string> = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  };
  const ClientDayAvailability = WaitListPopOverAvailHelper(days, clientInfo);
  let [isDisplayCompleteNotes, setIsCompleteCompleteNotes] = useState(false);

  const CheckIfDT = (carePlanApptType: any, ...ifNotMapped: any) => {
    let [carePlan_AppointmentType] = carePlanApptType;
    const [carePlanNotMapped] = ifNotMapped;
    let [AXON_Type] = carePlan_AppointmentType?.AxonType || [];

    return appointmentTypeCheck(
      carePlan_AppointmentType,
      carePlanNotMapped,
      AXON_Type
    );
  };

  const CheckIfABA = (carePlanApptType: any, ...ifNotMapped: any) => {
    let [carePlan_AppointmentType] = carePlanApptType;
    const [carePlanNotMapped] = ifNotMapped;
    let [AXON_Type] = carePlan_AppointmentType?.AxonType || [];

    return checkIsAbaAppointment(
      carePlan_AppointmentType,
      carePlanNotMapped,
      AXON_Type
    );
  };
  const providerClientsInfo = (
    clientData: any,
    type: string,
    isProvider?: boolean
  ) => {
    return (
      <>
        <div className="client_scroll_container">
          {Array.isArray(clientData) && clientData?.length > 0 ? (
            clientData.map((clientInfo: any, index: number) => {
              let client = clientInfo;
              let clientDob = client.dob;
              return (
                <div
                  className="provider_outer_wrap provider_current_info"
                  key={index}
                >
                  <div className="provider_main_Head">
                    <div>
                      <p className="main_Content provider_main_content">
                        {!isProvider ? (
                          `${client?.firstName} ${client?.lastName} (${client?.abbreviation})`
                        ) : (
                          <Link
                            className="schedule_link"
                            to={ROUTE_PATHS.PROVIDER + client?.id}
                            target={TARGET_BLANK}
                          >
                            {client?.displayName}
                          </Link>
                        )}
                      </p>
                    </div>
                    <div className="provider_container">
                      <p className="provider_address">
                        {client?.personalEmail || client?.email}
                      </p>
                    </div>
                  </div>
                  <div className="provider_sec_cont">
                    <div>
                      <p
                        className="provider_avail"
                        style={{ textAlign: 'right' }}
                      >
                        {!isProvider && `DOB: ${clientDob}`}
                      </p>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <p className="provider_employment">
                        {client?.phonePreference === 'Mobile'
                          ? client?.cellPhone
                          : client?.homePhone}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="provider_outer_wrap provider_current_info provider_employment">
              <p>No {type} available</p>
            </div>
          )}
        </div>
      </>
    );
  };

  const carePlan_ApptType = useMemo(() => {
    let isDT = CheckIfDT([clientInfo]);
    let [ABAMainApptType] = clientInfo?.AxonType || [];
    const Receving_Hours = clientInfo?.receiving;
    const Recommended_Hour = clientInfo?.recommended;
    const Requested_Hour = clientInfo?.requested;

    return getCarePlanApptType(
      isDT,
      clientInfo,
      ABAMainApptType,
      Receving_Hours,
      Recommended_Hour,
      Requested_Hour
    );
  }, [clientInfo]);

  let Notes = () => {
    return (
      <WaitListPopOver>
        <div className="Notes_Outer_Cont">
          <div className="Note_Title">Notes</div>
          <div
            className="Notes_Cont"
            style={{
              maxHeight: isDisplayCompleteNotes ? 'fit-content' : '112px',
              transition: isDisplayCompleteNotes
                ? 'opacity 0.5s ease-in-out'
                : 'none'
            }}
          >
            <span>
              <div
                style={{
                  width: '98%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {displayContent}
              </div>
            </span>
          </div>
          {displayContent !== 'N/A' && (
            <>
              {!isDisplayCompleteNotes ? (
                <div
                  className="View_Notes_Btn"
                  onClick={() => setIsCompleteCompleteNotes(prev => !prev)}
                >
                  <span>View all Patient Notes</span>
                </div>
              ) : (
                <>
                  <div
                    className="View_Notes_Btn"
                    onClick={() => setIsCompleteCompleteNotes(prev => !prev)}
                  >
                    <span>Hide Patient Notes</span>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </WaitListPopOver>
    );
  };

  let ProviderActions = () => {
    return (
      <ActionPopOverStyle>
        <div className="Action_cont provider_edit">
          <Link
            className="schedule_link"
            to={ROUTE_PATHS.PROVIDER + providerInfo?.providerId + '/edit'}
            // to={'/'}
            target={TARGET_BLANK}
          >
            <span className="Action_Schedule">
              <EditOutlined className="Action_Icon" />
              Edit Provider
            </span>
          </Link>
        </div>
        <Link
          className="schedule_link"
          to={ROUTE_PATHS.PROVIDER + providerInfo?.providerId + '/availability'}
          target={TARGET_BLANK}
        >
          <div
            className="Action_Schedule"
            style={{ paddingTop: '0px', paddingBottom: '0px' }}
          >
            <CalendarOutlined />
            <span style={{ marginLeft: '12px' }}>Manage Availability</span>
          </div>
        </Link>
      </ActionPopOverStyle>
    );
  };

  const clientProviderInfo = () => {
    let { restrictedProviders, currentProviders } = providerInfo;
    return (
      <WaitListPopOver>
        <div className="DayAvail_Outer_Cont">
          <div className="DayAvail_Head">
            <div>
              <span className="Avail_title">All Providers</span>
            </div>
            <div>
              <span className="updated_From">Updated from AXON</span>
            </div>
          </div>
          <div>
            <div className="provider_client_header">
              <span className="Avail_title provider_client_header">
                Current Providers (
                {currentProviders ? currentProviders?.length : 0})
              </span>
            </div>
            <div>
              {providerClientsInfo(currentProviders, 'current providers', true)}
            </div>
          </div>
          <div>
            <div className="provider_client_header">
              <span className="Avail_title provider_client_header">
                Do Not Cover Providers ({restrictedProviders?.length || 0})
              </span>
            </div>
            <div>
              {providerClientsInfo(
                restrictedProviders,
                'Do not cover providers',
                true
              )}
            </div>
          </div>
        </div>
      </WaitListPopOver>
    );
  };

  let ProviderClientsInfo = () => {
    return (
      <WaitListPopOver>
        <div className="DayAvail_Outer_Cont">
          <div className="DayAvail_Head">
            <div>
              <span className="Avail_title">All Clients</span>
            </div>
            <div>
              <span className="updated_From">Updated from AXON</span>
            </div>
          </div>
          <div>
            <div className="provider_client_header">
              <span className="Avail_title provider_client_header">
                Current Clients ({providerInfo?.currentClients?.length})
              </span>
            </div>
            <div>
              {providerClientsInfo(
                providerInfo?.currentClients,
                'current clients'
              )}
            </div>
          </div>
          <div>
            <div className="provider_client_header">
              <span className="Avail_title provider_client_header">
                Past Clients ({providerInfo?.pastClient?.length})
              </span>
            </div>
            <div>
              {providerClientsInfo(providerInfo?.pastClient, 'past clients')}
            </div>
          </div>
        </div>
      </WaitListPopOver>
    );
  };
  let Clientcontent = () => {
    return (
      <WaitListPopOver>
        <div className="main_Head">
          <div>
            <p className="main_Content">{displayContent}</p>
          </div>
          <div>
            <p className="personal_cont">{clientInfo?.client_mail}</p>
          </div>
        </div>
        <div className="main_Head border_underline">
          <div>
            <p className="dob">
              DOB:{'\u00A0'}
              {clientInfo.client_Dob}
            </p>
          </div>
          <div>
            <p className="personal_cont">{clientInfo?.client_phone}</p>
          </div>
        </div>

        <div className="inner_Head">
          <p style={{ paddingBottom: '4px' }}>Care Plans</p>

          <div
            className={`grid-container ${
              clientInfo?.client_carePlans.length > 7
                ? 'max-content-scroll'
                : ''
            }`}
          >
            {clientInfo?.client_carePlans.map((data, index) => {
              let clientSchedule = `${(CheckIfDT(
                data?.SalesForceMappedTherapy,
                data
              )
                ? data?.carePlans_DTReceivingUnits
                : data?.carePlans_axonReceivingHours) ||
                0} - ${data.recommended__c || 0} - ${data.requested__c || 0}
                `;
              return (
                <React.Fragment key={index}>
                  <div key={index} className="grid-item">
                    {data.eval_type__c || 'N/A'}
                  </div>
                  <div className="grid-item">
                    {data.therapy_type__c || 'N/A'}
                  </div>
                  <div className="grid-item">{data.status__c || 'N/A'}</div>
                  <div className="grid-item">{clientSchedule || 'N/A'}</div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="footer_cont">
          <div>
            <div className="">
              <span className="inner_Head">Cortica Clinic:</span>
              <span className="main_head_cont">
                {clientInfo?.client_clinic} Clinic
              </span>
            </div>
            <div className="">
              <span className="inner_Head">Creation Date:</span>
              <span className="main_head_cont">N/A</span>
            </div>
            <div className="">
              <span className="inner_Head">Order Date:</span>
              <span className="main_head_cont">N/A</span>
            </div>
          </div>
          <div>
            <div>
              <span className="inner_Head">Preferences</span>
            </div>
          </div>
        </div>
      </WaitListPopOver>
    );
  };

  const clientDayAvailabilityContent = () => {
    return (
      <WaitListPopOver>
        <div className="DayAvail_Outer_Cont">
          <div className="DayAvail_Head">
            <div>
              <span className="Avail_title">Availability</span>
            </div>
            <div>
              <span className="updated_From">Updated from AXON</span>
            </div>
          </div>
          <div>
            {Object.values(days).map((data: string, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div key={index} className="clientDayAvil_Cont">
                    <div className="dayOfWeek">{data}</div>
                    <div className="avail_Time">
                      {ClientDayAvailability
                        ? ClientDayAvailability[data]
                          ? ClientDayAvailability[data]
                              .map((time: any, index: number) =>
                                index === ClientDayAvailability[data].length - 1
                                  ? time
                                  : time + ',\u00A0'
                              )
                              .join('')
                          : ''
                        : []}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </WaitListPopOver>
    );
  };

  const ActionPopOver = () => {
    return (
      <ActionPopOverStyle>
        <div className="Action_cont">
          <Link
            className="schedule_link"
            to={
              ROUTE_PATHS.WAIT_LIST_NEW_APPOINTMENT +
              ROUTE_PARAMS.CLIENT +
              clientInfo?.clientId +
              ROUTE_PARAMS.WAITLIST_EVAL_TYPE +
              CheckIfDT([clientInfo]) +
              ROUTE_PARAMS.WAITLIST_IS_ABA +
              CheckIfABA([clientInfo]) +
              ROUTE_PARAMS.WAITLIST_SUBTYPE +
              carePlan_ApptType?.carePlanSubType +
              ROUTE_PARAMS.WAITLIST_PARENT_TYPE +
              carePlan_ApptType?.carePlanMainType +
              ROUTE_PARAMS.WAITLIST_NO_SESSIONS +
              carePlan_ApptType?.Sessions +
              ROUTE_PARAMS.GUARANTEED_SHIFT_BI_RSS +
              sidebarData.SmartScheduling_status
            }
            target={TARGET_BLANK}
          >
            <div className="Action_Schedule">
              <CalendarOutlined className="Action_Icon" /> Schedule Patient
            </div>
          </Link>
          <Link
            className="schedule_link"
            to={ROUTE_PATHS.CLIENT_AVAILABILITY + clientInfo?.clientId}
            target={TARGET_BLANK}
          >
            <div className="Action_Schedule">
              <ClockCircleOutlined className="Action_Icon" />
              View Full Availability
            </div>
          </Link>
          <div onClick={handleEditClick} style={{ cursor: 'pointer' }}>
            <span className="Action_Schedule">
              <EditOutlined className="Action_Icon" />
              Edit
            </span>
          </div>
          <div style={{ marginBottom: '10px' }}></div>

          <div onClick={handleAddNoteClick} style={{ cursor: 'pointer' }}>
            <span className="Action_Schedule">
              <FormOutlined className="Action_Icon" />
              Add a Note
            </span>
          </div>
        </div>
      </ActionPopOverStyle>
    );
  };
  const ProviderInfoPopOver = () => {
    return (
      <WaitListPopOver>
        <div className="provider_outer_wrap">
          <div className="provider_main_Head">
            <div>
              <p className="main_Content">{displayContent}</p>
            </div>
          </div>
          <div className="provider_sec_cont">
            <div>
              <p className="provider_employment">{providerInfo?.employment}</p>
            </div>
          </div>
        </div>

        <div className="inner_Head ">
          <p style={{ paddingBottom: '10px' }}>Provider Details</p>
        </div>
        <div className="Provider_basic_info">
          <div className="Ind_section">
            <span className="basic_info_title">Specality: </span>
            <span>{providerInfo?.speciality?.title}</span>
          </div>
          <div className="Ind_section">
            <span className="basic_info_title">Capacity Hours: </span>
            <span className="basic_collective_capacity">
              {providerInfo?.providerCapacity?.capacity}
            </span>
          </div>
          <div className="Ind_section">
            <span className="basic_info_title">Availability updated: </span>
            <span className=" basic_collective_capacity">
              {providerInfo?.availUpdated || 0}
            </span>
          </div>

          <div className="Ind_section">
            <span className="basic_info_title">Ramping %: </span>
            <span className="basic_collective_capacity">
              {providerInfo?.providerCapacity?.rampingPercentage || 0}
            </span>
            <span className="basic_info_title">Observable?: </span>
            <span className="basic_info_title">
              {`${providerInfo?.isObservable ? 'Yes' : 'No'}`}
            </span>
          </div>
        </div>
      </WaitListPopOver>
    );
  };

  let PopOverType: Record<string, any> = {
    Clientcontent: Clientcontent,
    clientDayAvailabilityContent: clientDayAvailabilityContent,
    ActionPopOver: ActionPopOver,
    Notes: Notes,
    ProviderInfoPopOver: ProviderInfoPopOver,
    ProviderClientsInfo: ProviderClientsInfo,
    ProviderActions: ProviderActions,
    clientProviderInfo: clientProviderInfo
  };

  return (
    <div className="clientPopOver">
      <Popover
        overlayStyle={{
          // maxWidth: '1000px',
          borderRadius: '20px'
        }}
        trigger={trigger || 'hover'}
        content={PopOverType[popOverType]}
        placement={'rightTop'}
        overlayClassName="clientPopOver"
        visible={isVisible}
        onVisibleChange={setIsVisible}
      >
        <div
          style={{
            height: '98%',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          onClick={() => setIsVisible(true)}
          {...restProps}
        >
          {displayContent}
        </div>
      </Popover>
    </div>
  );
};
export default React.memo(PopOverComp);
