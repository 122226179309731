import { IProvider, PROVIDER_STATUS_LIST } from 'model/v2';
import { MapProviderPetAllergies } from 'utils/common';

export const mapProviderFormDefaultValues = (provider?: IProvider) => {
  if (!provider) return {};
  const {
    status,
    appointmentTypes,
    providerCapacity,
    isObservable,
    telehealthLinks,
    assistantsInput,
    allowSelfScheduling,
    petAllergies,
    gender,
    address,
    corticaPrograms
  } = provider;

  return {
    ...provider,
    capacity:
      status === PROVIDER_STATUS_LIST.TERMINATED ||
      status === PROVIDER_STATUS_LIST.LEAVE_OF_ABSENCE
        ? 0
        : providerCapacity?.capacity,
    status: status ? status : PROVIDER_STATUS_LIST.ACTIVE,
    isObservable: isObservable ? isObservable : false,
    appointmentTypes: appointmentTypes?.map(item => item.id),
    rampingPercentage: providerCapacity?.rampingPercentage,
    assistantsInput: assistantsInput?.map(item => item),
    allowSelfScheduling: allowSelfScheduling ? 1 : 0,
    telehealthLinks: telehealthLinks,
    petAllergies: petAllergies
      ? MapProviderPetAllergies(petAllergies)
      : undefined,
    gender: gender,
    addressLine1: address?.addressLine1,
    addressLine2: address?.addressLine2 ? address?.addressLine2 : '',
    addressId: address?.id,
    city: address?.city,
    state: address?.state ? address?.state : undefined,
    zipCode: address?.zipCode,
    corticaPrograms: corticaPrograms?.map(item => item.id)
  };
};
