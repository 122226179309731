import gql from 'graphql-tag';
import { eventsFragments } from '../fragments/Events';
import { providerFragments, providerListResult } from '../fragments/Providers';
import { workingHours } from '../fragments/WorkingHours';
import { AvailabilityTemplateFragments } from '../fragments/AvailabilityTemplate';

export const GET_CLINIC_PROVIDERS = gql`
  query clinicProviders(
    $from: Int
    $size: Int
    $sort: String
    $clinicId: String!
    $status: String!
  ) {
    clinicProviders(
      from: $from
      size: $size
      sort: $sort
      clinicId: $clinicId
      status: $status
    ) {
      providers {
        id
        firstName
        lastName
        name
        displayName
        email
        mobilePhone
        clinic {
          id
        }
        speciality {
          id
          abbreviation
          title
          color
          department
        }
      }
      count
    }
  }
`;

export const IMPORT_PROVIDER_FROM_MS = gql`
  query($email: String!) {
    importProviderFromMs(email: $email) {
      ...Provider
    }
  }
  ${providerFragments.provider}
`;

export const GET_PROVIDERS_LIST = gql`
  query($from: Int, $size: Int, $search: String, $sort: String) {
    providersList(from: $from, size: $size, sort: $sort, search: $search) {
      ...ProviderListResult
    }
  }
  ${providerListResult}
`;

export const GET_ABBREVIATED_PROVIDERS_LIST = gql`
  query GetAbbreviatedProvidersList(
    $from: Int
    $size: Int
    $search: Int
    $clientSearch: Int
    $name: String
    $sort: String
    $clinic: [Int!]
    $specialty: [Int!]
    $status: [String!]
    $corticaProgram: [String!]
    $department: [String!]
    $additionalFilter: [AvailabilityFilterInput!]
  ) {
    providerRoaster(
      from: $from
      size: $size
      search: $search
      sort: $sort
      name: $name
      clinic: $clinic
      specialty: $specialty
      department: $department
      status: $status
      corticaProgram: $corticaProgram
      clientSearch: $clientSearch
      additionalFilter: $additionalFilter
    ) {
      count
      providers {
        providerList {
          ...Provider
        }
        providerRoasterNotes {
          id
          actionBy
          actionAt
          note
        }
        providerAvailabilities {
          ...ProviderDayAvailability
        }
        clientInfo {
          futureClient {
            id
            clinic_id
            clientId
            firstName
            lastName
            abbreviation
            personalEmail
            cellPhone
            homePhone
            phonePreference
            dob
            displayName
          }
          pastClient {
            id
            clinic_id
            clientId
            firstName
            lastName
            abbreviation
            personalEmail
            cellPhone
            homePhone
            phonePreference
            dob
            displayName
          }
        }
      }
    }
  }
  ${providerFragments.provider}
  ${AvailabilityTemplateFragments.ProviderDayAvailability}
`;

export const GET_PROVIDER_MEMBER = gql`
  query($id: Int!) {
    provider(id: $id) {
      ...Provider
    }
  }
  ${providerFragments.provider}
`;

export const PET_ALLERGIES = gql`
  query ProviderPetAllergies {
    ProviderPetAllergies {
      id
      value
    }
  }
`;

export const GET_PROVIDER_INITIAL_LOAD_DATA = gql`
  query {
    getProviderIntialLoadData {
      petAllergies {
        id
        value
      }
      providerLocations {
        id
        title
        color
        borderColor
      }
      filterList {
        clinicOptionsList {
          id
          name
          abbreviation
        }
        eventFilterStatus {
          id
          value
        }
        specialties {
          id
          abbreviation
          department
          title
        }
        corticaProgram {
          id
          programName
        }
        specialtiesDepartment
      }
      filterSelections {
        id
        typeName
      }
    }
  }
`;
export const GET_PROVIDER_PHOTO = gql`
  query($email: String!) {
    providerPhoto(email: $email)
  }
`;

export const GET_PROVIDER_OPTIONS_DATA = gql`
  query {
    allAppointmentTypes(includeAdminTypes: ${false}, sidebarState: ${false}) {
      id
      title
      eventType {
        id
        name
      }
    }
    clinicOptionsList {
      id
      name
      abbreviation
      address {
        id
        name
        state
      }
    }
    specialties {
      id
      title
      department
    }
    providerStatuses
    providerEmployments
  }
`;

export const GET_PROVIDER_PROFILE_CALENDAR = gql`
  query(
    $providerId: Int!
    $startDate: DateTime!
    $endDate: DateTime!
    $timeZone: String!
  ) {
    providerCalendarAvailabilities(id: $providerId, date: $startDate) {
      id
      dayOfWeek
      startTime
      endTime
      type
      location
      clinics {
        id
        abbreviation
      }
    }
    providerLocations {
      id
      title
      color
      borderColor
    }
    eventsByProvider(
      providerId: $providerId
      startDate: $startDate
      endDate: $endDate
      timeZone: $timeZone
    ) {
      ...ProviderEvent
    }
    providerAvailabilityBlocks(providerId: $providerId, date: $startDate) {
      ...WorkingHours
    }
  }
  ${eventsFragments.providerEvent}
  ${workingHours}
`;

export const GET_OBSERVERS = gql`
  query($status: String!) {
    getObservers(status: $status) {
      ...Provider
    }
  }
  ${providerFragments.provider}
`;

export const GET_PROVIDER_SUPERVISOR = gql`
  query($assistantId: Int!) {
    getSupervisor(assistantId: $assistantId) {
      ...Provider
    }
  }
  ${providerFragments.provider}
`;

export const GET_CLINIC_ASSISTANT_PROVIDERS = gql`
  query($clinicId: String!, $status: String!, $providerId: String!) {
    clinicAssistantProviders(clinicId: $clinicId, status: $status) {
      providers {
        id
        firstName
        lastName
        name
        displayName
        email
        mobilePhone
        clinic {
          id
        }
        speciality {
          id
          abbreviation
          title
          color
          department
        }
      }
    }
    getAssistantProvidersByProvider(providerId: $providerId) {
      providers {
        id
        firstName
        lastName
        name
        displayName
        email
        mobilePhone
      }
    }
  }
`;

export const ADD_AXON_PROVIDER_NOTES = gql`
  mutation($note: AxonCarePlanNotesInput!) {
    addProviderNotes(data: $note) {
      id
      note
      actionBy
      actionAt
      createdAt
      provider {
        id
      }
    }
  }
`;
export const GET_UNIQUE_CLIENTS_DATA = gql`
  query($specialityId: [Int!]!) {
    getUniqueClientsData(specialityId: $specialityId) {
      providerId
      clientCount
    }
  }
`;
