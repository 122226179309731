import styled from 'styled-components';

export const SmartWrapper = styled.div`
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #6f42f5;
    color: white;
  }
  .appt-category-type-selected {
    .ant-select-selector {
      background: #6f42f5;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-select-arrow {
      color: white;
    }
  }
  .appt-category-type {
    .ant-select {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
    }

    .ant-select-selector {
      border-radius: 5px !important;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #6f42f5;
      color: white;
    }
  }
`;
export const NewAppointmentFormContainer = styled.div`
  height: 100%;
  & > div {
    height: 100%;
  }
`;
export const SearchWrapper = styled.div`
  .icon {
    position: absolute;
    top: -4px;
    left: -110px;
    color: red;
  }
  .input {
    position: relative;
    padding-left: 24px;
    .ant-select-selection-search-input {
      color: green;
    }
  }
`;

export const NewAppointmentFormWrapperV2 = styled.div`
  padding: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  .column {
    height: 115% !important;
  }
  .ant-col {
    & > div {
      margin-bottom: 16px;
    }
  }
  .top-wrap {
    label,
    span,
    .optional {
      font-size: 14px;
      color: #415766;
      padding: 0;
      &.optional {
        display: none;
      }
    }
  }
  .ant-select-enabled {
    .ant-select-selection {
      border-color: #c9cfd3;
    }
  }
  .ant-select-open {
    .ant-select-selection {
      border-color: #6f4bf1 !important;
      box-shadow: none;
    }
  }
  .scrollable {
    overflow-y: overlay;
    overflow-x: hidden;
    flex: 1;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
        inset 1px 1px 0px rgba(0, 0, 0, 0);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
  }
  .section-title {
    font-size: 16px;
    font-weight: bold;
    color: #21303a;
    margin: 0 0px 12px 0px;

    width: 100%;
  }
  .generateSmartBtn {
    justify-content: center;
  }
`;

export const NewAppointmentFormWrapper = styled.div`
  padding: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  .ant-col {
    & > div {
      margin-bottom: 16px;
    }
  }
  .top-wrap {
    width: fit-content;
    margin-bottom: 7px;
    label,
    span,
    .optional {
      font-size: 14px;
      color: #415766;
      padding: 0;
      &.optional {
        display: none;
      }
    }
  }
  .ant-select-enabled {
    .ant-select-selection {
      border-color: #c9cfd3;
    }
  }
  .ant-select-open {
    .ant-select-selection {
      border-color: #6f4bf1 !important;
      box-shadow: none;
    }
  }
  .scrollable {
    overflow-y: overlay;
    overflow-x: hidden;
    flex: 1;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
        inset 1px 1px 0px rgba(0, 0, 0, 0);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
  }
  .section-title {
    font-size: 16px;
    font-weight: bold;
    color: #21303a;
    margin: 0 0 12px 0;
    width: 100%;
  }
  .section-title-type {
    font-size: 16px;
    font-weight: bold;
    color: #21303a;
    margin: 0 0 12px 0;
    width: 100%;
    margin-left: 25px;
  }
`;

export const ResultWrapperDT = styled.div``;

export const AppointmentFormHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 24px;
  label {
    font-size: 18px;
    font-weight: bold;
    color: #21303a;
  }
  .close.icon {
    margin: 0;
  }
`;

export const CarePlanWrapper = styled.div`
  margin-bottom: 20px;
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #6f42f5 !important;
  }

  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #6f42f5 !important;
  }

  .ui.radio.checkbox input:checked ~ label:after {
    background-color: #6f4bf1;
  }

  .ui.radio.checkbox input:focus:checked ~ label:after {
    background-color: #6f4bf1;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    cursor: pointer;
  }
  .grid {
    margin-left: 0px;
    width: 590px;
  }
  .rectangle {
    display: inline-block;
    width: 407px;
    height: 1px;
    background: #c9cfd3;
  }
  .appt-category-type-selected {
    .ant-select-selector {
      background: #6f42f5;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-select-arrow {
      color: white;
    }
  }
  .appt-category-type {
    .ant-select {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
    }

    .ant-select-selector {
      border-radius: 5px !important;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #6f42f5;
      color: white;
    }
  }
  .care-plan {
    margin: 13px 186px 13px 10px;
    background: #ffffff;
    font-weight: bold;
    font-size: 13px;
    color: #415766;
    width: 400px;
    height: 16px;
    padding: 0 !important;
  }
  .popup-box {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 250px;
    height: 75px;
    border: 1px solid #dfdfdf;
    background: #f8f8f8;
    left: 30%;
    bottom: 120%;
    margin-left: -5px;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .appoint {
    width: 141px;
    height: 17px;
    font-family: Lato;
    font-style: normal;
    color: #415766;
    margin-left: 24px;
  }

  .type-row {
    margin-left: 24px;
    margin-right: 21px;
    width: 368px;
  }
  .ques {
    color: red;
  }
  .arrow {
    margin-left: 369px;
  }
  .arr {
    background-color: #3865fe !important;
  }

  .appointment-category {
    display: inline-block;
    margin-right: 30px;
  }
  .appt-category-type-selected {
    .ant-select-selector {
      background: #6f42f5;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-select-arrow {
      color: white;
    }
  }
  .appt-category-type {
    .ant-select {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
    }

    .ant-select-selector {
      border-radius: 5px !important;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #6f42f5;
      color: white;
    }
  }

  .is-selected {
    margin-top: 9px;
    height: 16px;
    .ant-select-selector {
      background: #6f42f5;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-select-arrow {
      color: white;
      position: absolute;
      top: 18px;
    }
  }
  .radio-style {
    margin-top: 9px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #415766;
    width: 120px;
  }
  .smart-scheduling {
    display: inline-block;
    .ui.fitted.toggle.checkbox {
      label {
        &::after {
          width: 28px;
          height: 28px;
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05),
            0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
        &::before {
          width: 51px;
          height: 29px;
          border-radius: 15.5px;
          border: 1.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
      }
      &.checked {
        label {
          &::after {
            left: 22px;
          }
          &::before {
            background-color: #4cd964 !important;
          }
        }
      }
    }
  }
  .errorMsg {
    color: #cc3f3f;
    padding-left: 10px;
  }
`;

export const DTWrapper = styled.div`
  margin-bottom: 20px;
  .ant-select-selection-search {
    &.select {
      background-color: green !important;
    }
  }
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #6f42f5 !important;
  }

  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #6f42f5 !important;
  }

  .ui.radio.checkbox input:checked ~ label:after {
    background-color: #6f4bf1;
  }

  .ui.radio.checkbox input:focus:checked ~ label:after {
    background-color: #6f4bf1;
  }
  .grid {
    margin-left: 0px;
    width: 407px;
  }
  .rectangle {
    display: inline-block;
    width: 407px;
    height: 1px;
    background: #c9cfd3;
  }
  .appType {
    width: 369px;
    margin-left: 21px;
    margin-right: 20px;
  }
  .duration {
    min-width: 133px;
    max-width: 133px;
    margin-left: 30px;
  }
  .appoint {
    width: 346px;
    height: 16px;
    color: #415766;
    margin-left: 27px;
    margin-bottom: 53px;
  }
  .appointtype {
    width: 346px;
    height: 16px;
    padding-top: 10px;
    margin-left: 27px;
    margin-bottom: 53px;
  }
  .selector {
    margin-left: -12px;
    margin-top: 7px;
    width: 346px;
    height: 32px;
  }
  .session {
    margin-left: 13px;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 155px;
    height: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.00177778px;
    color: #21303a;
  }
  .popup-box {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 250px;
    height: 420px;
    border: 1px solid #dfdfdf;
    background: #f8f8f8;
    left: 30%;
    bottom: 120%;
    margin-left: -5px;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .lab {
    position: absolute;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    max-height: 70px;
  }
  .weekly {
    margin-top: 9px;
    width: 133px;
    .ant-select-selector {
      border-radius: 4px;
    }
  }
  .square {
    margin-bottom: 11px;
    border: 1px solid rgba(98, 107, 113, 0.496534);
    box-sizing: border-box;
    border-radius: 4px;
  }
  .freq {
    height: 32px;
    margin-left: 30px;
    margin-bottom: 94px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: red;
    min-width: 120px;
    max-width: 120px;
  }
  .prov {
    margin-left: 40px;
    width: 129px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
  }
  .daily-hours {
    margin-top: 9px;
    width: 129px;
    .ant-select-selector {
      border-radius: 4px;
    }
  }

  .timepicker-style {
    margin-top: 9px;
    width: 133px;
    .ant-picker {
      border-radius: 4px;
    }
  }
  .smart-scheduling {
    display: inline-block;
    .ui.fitted.toggle.checkbox {
      label {
        &::after {
          width: 28px;
          height: 28px;
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05),
            0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
        &::before {
          width: 51px;
          height: 29px;
          border-radius: 15.5px;
          border: 1.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
      }
      &.checked {
        label {
          &::after {
            left: 22px;
          }
          &::before {
            background-color: #4cd964 !important;
          }
        }
      }
    }
  }
  .start-column {
    min-width: 135px;
    max-width: 135px;
    position: absolute;
    left: 28px;
    .ant-picker {
      border-radius: 4px;
    }
  }
  .end-column {
    min-width: 130px;
    max-width: 130px;
    position: absolute;
    left: 60px;
    .ant-picker {
      border-radius: 4px;
    }
  }
  .datapicker-style {
    margin-top: 9px;
  }
  .row-style {
    position: absolute;
    left: 28px;
    padding: 0px !important;
    margin-bottom: 13px;
  }
  .column-style {
    min-width: 342px;
    max-width: 342px;
  }
`;
export const ABAWrapper = styled.div`
  margin-bottom: 20px
  height: 100%;
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #6f42f5 !important;
  }

  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #6f42f5 !important;
  }

  .ui.radio.checkbox input:checked ~ label:after {
    background-color: #6f4bf1;
  }

  .ui.radio.checkbox input:focus:checked ~ label:after {
    background-color: #6f4bf1;
  }
  .column-width {
    min-width: 113px;
    max-width: 113px;
    margin-right: 20px;
  }
  .grid {
    margin-left: 0px;
    width: 407px;
  }
  .rectangle {
    display: inline-block;
    width: 407px;
    height: 1px;
    background: #c9cfd3;
  }
  .appoint {
    width: fit-content;
    height: 17px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #415766;
    margin-left: 22px;
    margin-bottom: 30px;
  }
  .selector {
    margin-left: -12px;
    margin-top: 7px;
    width: 346px;
    height: 32px;
  }
  .type-row {
    margin-left: 24px;
    margin-right: 21px;
    width: 368px;
  }
 
  .session {
    margin-left: 13px;
    margin-top: 30px;
    margin-bottom: 10px;
    width: 155px;
    height: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.00177778px;
    color: #21303a;
  }
  .popup {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #3E5766;
    label{
      font-weight: bold;
    }
  }
  .popup-box {
    content: '';
    position: absolute;
    bottom: -50px;
    width: 270px;
    height: fit-content;
    border: 1px solid #dfdfdf;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    background: #f8f8f8;
    left: 30%;
    bottom: 30%;
    margin-left: -5px;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    li{
      label{
        font-weight: bold;
      }
    }
   
  }

  .lab {
    position: absolute;
    width: 97px;
    height: 16px;
    margin-left: 20px;
    margin-bottom: 15px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
  }
  .duration {
    width: 97px:
  }
  .offsite {
    margin-right: 25px;
    margin-top: 9px;
    margin-bottom: 7px;
    .ant-select-selector {
     border-radius: 4px;
     .ant-select-selection-search-input{
        text-align: center;   
     }
    }
    .ant-select-item-option-content{
      text-align: center;
    }
  }
  .offsite-error {
    margin-right: 25px;
    margin-top: 7px;
    margin-bottom: 9px;
    .ant-select-selector {
     background:#6F42F5;
     color:#FFFFFF;
     border-radius: 4px;
     .ant-select-selection-search-input{
        text-align: center;   
     }
    }
    .ant-select-arrow{
     color:white;
    }
  }
  .is-selected {
    margin-top: 9px;
    margin-right: 25px;
    margin-bottom: 7px;
    .ant-select-selector {
     background:#6F42F5;
     color:#FFFFFF;
     border-radius: 4px;
     .ant-select-selection-search-input{
        text-align: center;   
     }
    }
    .ant-select-arrow{
     color:white;
    }
     .ant-select-item-option-content{
      text-align: center;
    }
  }
  .square {
    margin-bottom: 11px;
    border: 1px solid rgba(98, 107, 113, 0.496534);
    box-sizing: border-box;
    border-radius: 4px;
  }
  .freq {
    margin-left: 23px;
    margin-bottom: 94px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #415766;
  }
  .prov {
    margin-left: 20px;
    margin-top: 21px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
  }
  
  .pay {
    margin-left: 20px;
    margin-right: 25px;
    margin-top: 9px;
  }
  .pay-box-selected {
    margin-left: 0px;
    margin-right: 25px;
    margin-top: 9px;
     .ant-select-selector {
     background:#6F42F5;
     color:#FFFFFF;
     border-radius: 4px;
    }
   .ant-select-arrow{
     color:white;
   }
  }
  .smart-scheduling {
    display: inline-block;
    .ui.fitted.toggle.checkbox {
      label {
        &::after {
          width: 28px;
          height: 28px;
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05),
            0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
        &::before {
          width: 51px;
          height: 29px;
          border-radius: 15.5px;
          border: 1.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
      }
      &.checked {
        label {
          &::after {
            left: 22px;
          }
          &::before {
            background-color: #4cd964 !important;
          }
        }
      }
    }
  }
`;

export const CategoryFormWrapper = styled.div`
  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background-color: #e82424;
  }
  .ui.toggle.checkbox .box:hover:before,
  .ui.toggle.checkbox label:hover:before {
    background-color: #e82424;
  }

  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #3caf05 !important;
  }
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #3caf05 !important;
  }

  .ui.radio.checkbox input:checked ~ label:after {
    background-color: #6f4bf1;
  }

  .ui.radio.checkbox input:focus:checked ~ label:after {
    background-color: #6f4bf1;
  }
  .grid {
    margin-left: 0px;
    width: 407px;
  }
  .rectangle {
    display: inline-block;
    width: 368px;
    margin-left: 21px;
    height: 1px;
    background: rgba(201, 207, 211, 0.35);
    border-radius: 4px;
  }
  .appt-category {
    .ant-select {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
    }

    .ant-select-selector {
      border-radius: 5px;
      border-color: #6f42f5;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #6f42f5;
      color: white;
    }
    &.extra-margin {
      margin-top: 10px;
    }
  }
  .smart-schedule {
    background: #ffffff;
    border: 1px solid rgba(60, 175, 5, 0.35);
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    margin: 21px 18px 0px 24px;
  }
  .manual-schedule {
    background: #ffffff;
    border: 1px solid #c9cfd3;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    margin: 21px 18px 0px 24px;
  }
  .smart-schedule-text {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 12px;
    color: #3e5766;
    margin: 12px 5px 12px 8px;
    width: 102px;
  }
  .enabled {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    padding-right: 8px;
    color: #3caf05;
  }
  .disabled {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    padding-right: 8px;
    color: #e82424;
  }
  .row-position {
    background: #ffffff;
    height: 40px;
    margin: 0px 24px 0px 9px;
  }
  .ant-select {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #3e5766;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #6f42f5;
  }

  .label-style {
    padding-left: 14px !important;
    margin-top: -5px !important;
  }
  .column-style {
    .ui.fitted.toggle.checkbox {
      margin-top: -5px;
    }
  }

  .search-box {
    border: 1px solid #c9cfd3;
    box-sizing: border-box;
    border-radius: 5px;
    width: 368px;
    height: 40px;
    text-align: center;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #3e5766;
  }
  .basic {
    position: absolute;
    margin: 13px 186px 13px 10px;
    background: #ffffff;
    font-weight: bold;
    font-size: 13px;
    color: #415766;
    width: 211px;
    height: 16px;
    padding: 0 !important;
  }
  .appoint {
    width: 141px;
    height: 17px;
    font-family: Lato;
    font-style: normal;
    color: #415766;
    margin-left: 24px;
  }
  .type-row {
    margin-left: 24px;
    margin-right: 21px;
    margin-top: 5px;
    padding-bottom: 0px !important;
    width: 368px;
  }
  .appointment-category {
    display: inline-block;
    margin-right: 30px;
  }
  .smart-scheduling {
    display: inline-block;
    .ui.fitted.toggle.checkbox {
      label {
        &::after {
          width: 28px;
          height: 28px;
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05),
            0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
        &::before {
          width: 51px;
          height: 29px;
          border-radius: 15.5px;
          border: 1.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
      }
      &.checked {
        label {
          &::after {
            left: 22px;
          }
          &::before {
            background-color: #4cd964 !important;
          }
        }
      }
    }
  }
`;
export const CategoryFormWrapperV2 = styled.div`
  margin-bottom: 20px
 
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #6f42f5 !important;
  }

  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #6f42f5 !important;
  }

  .ui.radio.checkbox input:checked ~ label:after {
    background-color: #6f4bf1;
  }

  .ui.radio.checkbox input:focus:checked ~ label:after {
    background-color: #6f4bf1;
  }
  .grid {
    margin-left: 0px;
    width: 407px;
  }
  .rectangle {
    display: inline-block;
    width: 407px;
    height: 1px;
    background: #c9cfd3;
  }
  .basic {
    position: absolute;
    margin: 13px 186px 13px 10px;
    background: #ffffff;
    font-weight: bold;
    font-size: 13px;
    color: #415766;
    width: 211px;
    height: 16px;
    padding: 0 !important;
  }
  .appoint {
    width: 141px;
    height: 17px;
    font-family: Lato;
    font-style: normal;
    color: #415766;
    margin-left: 24px;
  }
  .type-row {
    margin-left: 24px;
  }
  .appointment-category {
    display: inline-block;
    margin-right: 30px;
    margin-left: 25px;
    margin-bottom: -20px;
  }
  .smart-scheduling {
    display: inline-block;
    .ui.fitted.toggle.checkbox {
      label {
        &::after {
          width: 28px;
          height: 28px;
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05),
            0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
        &::before {
          width: 51px;
          height: 29px;
          border-radius: 15.5px;
          border: 1.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
      }
      &.checked {
        label {
          &::after {
            left: 22px;
          }
          &::before {
            background-color: #4cd964 !important;
          }
        }
      }
    }
  }
`;

export const FilterClientAvailabilityContentWrapper = styled.div`
  .header {
    position: absolute;
    height: 16px;
    left: 20px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #415766;
  }
  .start-column {
    min-width: 160px;
    max-width: 160px;
    position: absolute;
    left: 28px;
    .ant-picker {
      border-radius: 4px;
    }
  }
  .end-column {
    min-width: 128px;
    max-width: 128px;
    position: absolute;
    left: 73px;
    .ant-picker {
      border-radius: 4px;
    }
  }
  .datapicker-style {
    margin-top: 9px;
  }
  .row-style {
    position: absolute;
    left: 28px;
    padding: 0px !important;
  }
  .column-style {
    min-width: 342px;
    max-width: 342px;
  }
  .label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    &.part2 {
      color: #415766;
      font-weight: bold;
    }
  }
`;

export const AdditionalWrapper = styled.div`
  .label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.00177778px;
    color: #21303a;
    margin-left: 24px;
  }
  .label-date {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.00177778px;
    color: #21303a;
    margin-left: 10px;
  }
  .start-date {
    margin-left: 21px;
    width: 133px !important;
    .ant-picker {
      border-radius: 4px;
    }
  }
  .end-date {
    margin-left: 33px;
    width: 133px !important;
    .ant-picker {
      border-radius: 4px;
    }
  }

  .boxes {
    margin-top: 9px;
    width: 360px;
    .ant-select {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
    }
    .ant-select-selector {
      border-radius: 5px !important;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #6f42f5;
      color: white;
    }
  }
  .button {
    margin-left: 70px;
  }
  .datapicker-style {
    margin-top: 9px;
  }
`;
export const MaxProviderWrapper = styled.div`
  margin-left: 12px;
  margin-top: 9px;
  .ant-select-arrow {
    margin-top: 4px;
  }
  .inc {
    height: 16px;
    margin-right: 11px;
    .ant-select-selector {
      border-radius: 4px;
    }
  }
  .is-selected-max {
    height: 16px;
    margin-right: 11px;
    .ant-select-selector {
      background: #6f42f5;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-select-arrow {
      color: white;
      position: absolute;
      top: 8px;
    }
  }
  .provider {
    top: 6px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #415766;
  }
  .upto {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #415766;
    margin-top: 9px;
    min-width: 33px;
    max-width: 33px;
    margin-right: 10px;
  }
`;

export const AppointmentTypeFormWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  .appt-category-type-selected {
    .ant-select-selector {
      background: #6f42f5;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-select-arrow {
      color: white;
    }
  }
  .appt-category-type {
    padding-top: 8px;
    .ant-select {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
      padding-top: 10px;
    }
    .ant-select-arrow {
      position: absolute;
      height: 20px;
      left: 322px;
      top: 24px;
    }

    .ant-select-selector {
      border-radius: 5px !important;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #6f42f5;
      color: white;
    }
  }
  .addAdditionalProvidersText {
    float: right;
    color: #6f42f5;
    &:hover {
      cursor: pointer;
      text-shadow: 1px 1px rgb(0, 0, 0, 0.35);
    }
  }
  .disabledAddAdditionalProviderText {
    float: right;
    color: #a1a5a8;
    pointer-events: none;
  }
  .selectWithDeleteStyle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .trashIcon {
      position: relative;
      color: #e82424;
      font-size: 20px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .additionalProvidersList {
    list-style-type: none;
    padding: 0px;
    div {
      margin-bottom: 5px;
    }
    .disabled {
      color: #a1a5a8;
      pointer-events: none;
    }
  }
`;

export const AdditionalProviderFieldWrapper = styled.div`
  width: 100%;
  padding-right: 10px;
  .providerListItem {
    display: flex;
    align-items: center;
    height: 32px;
    background-color: #f4f4f2;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding-left: 10px;
  }
`;

export const ClientSelectWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  .client {
    padding-top: 8px;
    .ant-select {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
      padding-top: 10px;
    }

    .ant-select-selector {
      border-radius: 5px !important;
    }
    .ant-select-arrow {
      position: absolute;
      height: 20px;
      left: 322px;
      top: 24px;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #6f42f5;
      color: white;
    }
  }
`;

export const LocationFormWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  .appt-category-type {
    padding-top: 8px;
    .ant-select {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
      padding-top: 10px;
    }

    .ant-select-selector {
      border-radius: 5px !important;
    }
    .ant-select-arrow {
      position: absolute;
      height: 20px;
      left: 322px;
      top: 24px;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #6f42f5;
      color: white;
    }
  }
  .ant-row-flex {
    margin: 0 !important;
    .ant-col {
      padding: 0 !important;
    }
    &.name_save-btn {
      .ant-col:first-of-type {
        margin-right: 15px !important;
      }
      .ant-col:last-of-type {
        margin-top: 16.5px !important;
      }
    }
  }
  .location-first-row {
    display: flex;
    .ant-col {
      flex: 1;
    }
  }

  #save-appt-address {
    align-items: flex-end;
  }
  .ant-row-flex:nth-child(1) {
    .address-line-1 {
      width: calc(50% - 7.5px);
      margin-right: 7.5px;
    }
    .address-line-2 {
      width: calc(50% - 7.5px);
      margin-left: 7.5px;
    }
  }
  .ant-row-flex:nth-child(2),
  .ant-row-flex:nth-child(3) {
    .ant-col {
      flex: 1;
    }
    &.ant-row-flex:nth-child(2) {
      .ant-col:nth-child(2) {
        margin: 0 16px;
      }
    }
  }
  .category-wrapper {
    .ant-radio-group {
      display: flex;
      label {
        flex: 1;
      }
    }
  }
`;

export const ClinicFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  .appt-category-type {
    padding-top: 8px;
    .ant-select {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #3e5766;
      padding-top: 10px;
    }

    .ant-select-selector {
      border-radius: 5px !important;
    }
    .ant-select-arrow {
      position: absolute;
      height: 20px;
      left: 322px;
      top: 24px;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #6f42f5;
      color: white;
    }
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  .date-style {
    margin-top: 8px;
    width: 133px;
  }
  .time-style {
    margin-top: 8px;
    width: 97px;
  }
  .ant-picker {
    border-radius: 5px;
  }
  .ant-calendar-picker,
  .ant-time-picker {
    input {
      border-color: #c9cfd3;
    }
  }
  .date-first-row {
    display: flex;
    .ant-col:nth-child(1) {
      min-width: 133px;
      max-width: 133px;
      margin-right: 16px;
    }
    .ant-col:nth-child(2) {
      margin-right: 16px;
      width: 100px;
    }
    .ant-col:nth-child(3) {
      margin-left: 10px;
      margin-right: 16px;
      width: 100px;
    }
    .ant-col {
      .ant-time-picker-panel-inner {
        min-width: 168px;
      }
    }
  }
  .date-second-row {
    display: flex;
    .ant-col:nth-child(1) {
      width: 133px;
    }
  }
`;

export const NotesFormWrapper = styled.div`
  padding: 0 24px;
  .notes-style {
    margin-top: 4px;
  }
  .ant-input {
    min-height: 64px !important;
    max-height: 150px;
    height: unset;
    border-radius: 4px;
    border: solid 1px #c9cfd3;
    background-color: #fff;
    font-size: 14px;
    color: #415766;
    resize: vertical;
    &::placeholder {
      color: #919699;
    }
    &:focus {
      border-color: #6f4bf1;
      box-shadow: none;
    }
  }
`;

export const FrequencyFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 24px;
  .freq-style {
    margin-top: 8px;
  }
  .ant-picker {
    border-radius: 4px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }
  &.details-client-nonClinical {
    .payment-method-Wrapper {
      display: flex;
      flex-direction: row;
      flex: 1;
      .ant-col {
        flex: 1;
        width: 100%;
        &:first-of-type {
          margin-right: 7.5px;
        }
        &:last-of-type {
          margin-left: 7.5px;
        }
      }
    }
    .recurrence-pattern-wrapper {
      width: 100%;
      display: flex;
      gap: 10px;
    }
    .occurence-wrapper {
      width: 100%;
    }
    .endDate-wrapper {
      width: 100%;
    }
  }
  &.details-client-clinical,
  &.details-admin {
    .payment-method-Wrapper {
      grid-template-columns: 1fr 1fr;
      display: grid;
      width: 100%;
      gap: 10px;
      &.nopayment-method-wrapper {
        flex: 1;
        margin-right: 0;
      }
    }
    .recurrence-pattern-wrapper {
      width: 100%;
      display: flex;
      gap: 10px;
    }
    .occurence-wrapper {
      width: 100%;
    }
    .endDate-wrapper {
      order: 1;
      width: 100%;
      &.noDays-endDate-wrapper {
        width: 100%;
      }
    }
    .daysWrapper {
      margin-right: 16px;
      .ant-checkbox-group {
        margin-top: 5px;
      }
    }
  }
  .endDate-wrapper {
    .ant-calendar-picker {
      input {
        border-color: #c9cfd3;
      }
    }
  }
`;

interface Props {
  pageName?: string;
  isSideBarEdit?: Boolean;
}
export const DriveTimeMileageWrapper = styled.div<Props>`
  padding: ${props => (props.pageName === 'adminForm' ? '0 px' : '0 24px')};
  position: relative;
  .reimburse-mileage-text {
    font-weight: 600;
  }
  .driveTime_cls .ui.grid > .row {
    padding: 10px 0 10px 0 !important;
    z-index: 0;
  }
  .Admin_Wrapper img {
  }
  .driveTime_cls .row {
    z-index: 9 !important;
  }
  .Address-loader {
    display: grid;
    margin-top: 10px;
    grid-auto-flow: column;
    grid-auto-flow: column;
    grid-template-columns: 178px 60px;
    font-size: 13px !important;
    color: #506472;
  }
  .loader_inner_cont {
    padding-top: 2px;
  }
  .optional {
    display: none;
  }
  .ant-col > div {
    margin-bottom: 10px;
  }
  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background-color: #e82424;
  }
  .ui.toggle.checkbox .box:hover:before,
  .ui.toggle.checkbox label:hover:before {
    background-color: #e82424;
  }
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #3caf05 !important;
  }
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #3caf05 !important;
  }
  .ui.checkbox input:focus ~ .box:before,
  .ui.checkbox input:focus ~ label:before {
    background-color: #e82424 !important;
  }
  .mileage_loader {
    position: absolute;
    top: 60%;
    left: 85%;
  }
  .error-message {
    color: #cc3f3f;
  }
  .search-by-client-selected {
    height: 32px;
    width: 363px;
    position: relative;
    .ant-select-selector {
      .ant-select-selection-search-input {
        padding-left: 20px;
      }
      flex: 1;
      background-color: #fff;
      border: 1px solid #6f42f5;
      border-radius: 4px;
      padding: 0 10px 0 32px;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    .ant-select-dropdown {
      border-radius: 4px;
      background: #fff;
      z-index: 999;
      max-height: 216px !important;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      padding: 2px 0;
      border-color: #6f42f5;
      border-style: solid;
      border-width: 0 1px 1px 1px;
      overflow-y: overlay !important;
    }
  }
  .search-by-client {
    height: 32px;
    width: 363px;
    position: relative;
    .ant-select-selector {
      .ant-select-selection-search-input {
        padding-left: 20px;
      }
      flex: 1;
      background-color: #fff;
      border: 1px solid #c9cfd3;
      border-radius: 4px;
      padding: 0 10px 0 32px;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 600;
      color: #415766;
    }
    .ant-select-dropdown {
      border-radius: 4px;
      background: #fff;
      z-index: 999;
      max-height: 216px !important;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      padding: 2px 0;
      border-color: #6f42f5;
      border-style: solid;
      border-width: 0 1px 1px 1px;
      overflow-y: overlay !important;
    }
  }
  .client-Provider_wrapper {
    height: 32px;
    position: relative;
    .rbt-input {
      flex: 1;
      background-color: #fff;
      border: 1px solid #c9cfd3;
      border-radius: 4px;
      padding: 0 10px 0 32px;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #415766;
      &.focus {
        border-radius: 4px;
        border-color: #6f42f5;
        outline: none;
      }
      &::placeholder {
        font-size: 14px;
        font-weight: 600;
        color: #415766;
      }
    }
    .rbt-input-hint {
      display: none !important;
    }
  }
  .rbt-menu {
    border-radius: 4px;
    background: #fff;
    z-index: 999;
    max-height: 216px !important;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    padding: 2px 0;
    border-color: #6f42f5;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    overflow-y: overlay !important;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-moz-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-moz-scrollbar-track {
      background-color: transparent;
    }
    &::-moz-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-ms-scrollbar {
      height: 15px;
      width: 15px;
    }
    &::-ms-scrollbar-track {
      background-color: transparent;
    }
    &::-ms-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 50px;
      background-color: #c9cfd3;
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
        inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    .client-Provider_option {
      transition: all 0.1s ease-in-out;
      font-size: 14px;
      color: #000;
      padding: 7px 16px 7px 16px;
      &:hover {
        background-color: #f2f4f5;
      }
    }
    .client-Provider_results,
    .client-Provider_search {
      font-size: 14px;
      color: #000;
      padding: 7px 16px 7px 16px;
      cursor: default;
    }
    .client-Provider_loader {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 16px 7px 16px;
    }
  }
  .search-icon {
    position: absolute;
    z-index: 80;
    top: ${props => (props.pageName === 'adminForm' ? '49px' : '44px')};
    left: 10px;
  }
  .search-icon-provider {
    position: absolute;
    z-index: 80;
    top: 50px;
    left: 28px;
  }
  .admin-icon {
    top: ${props => (props.isSideBarEdit ? '50px' : '56px')};
  }

  .Provider_Wrapper {
    margin-top: 10px;
  }

  .mileage-style {
    margin-top: 4px;
  }
  .ant-input {
    height: unset;
    border-radius: 4px;
    border: solid 1px #c9cfd3;
    background-color: #fff;
    font-size: 14px;
    color: #415766;
    resize: vertical;
    &::placeholder {
      color: #919699;
    }
    &:focus {
      border-color: #6f4bf1;
      box-shadow: none;
    }
  }
`;

export const FooterFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #d8d8d8;
  padding: 10px 24px 2px 24px;
  .ant-col:nth-child(1) {
    > div {
      width: 50;
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-wrap {
        margin: 6px 0 0 0;
        label {
          font-size: 15px;
          color: #000;
        }
      }
      .ui.fitted.toggle.checkbox {
        label {
          align: left;
          &::after {
            width: 28px;
            height: 28px;
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05),
              0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            background-color: #fff;
          }
          &::before {
            width: 51px;
            height: 29px;
            border-radius: 15.5px;
            border: 1.5px solid rgba(0, 0, 0, 0.1);
            background-color: #fff;
          }
        }
        &.checked {
          label {
            &::after {
              left: 22px;
            }
            &::before {
              background-color: #4cd964 !important;
            }
          }
        }
      }
    }
  }
  .save-as-invisible-form-item {
    margin-right: auto;
  }
`;

export const OpeningsFormWrapper = styled.div`
  margin: 0 24px;
  position: relative;
  .generate-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 48px;
    border-radius: 8px;
    background-color: #6f4bf1;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    &:hover {
      border-color: unset;
    }
  }
  .error {
    margin: 5px 0 0 0;
    font-family: Roboto;
    font-size: 10px;
    font-weight: bold;
    line-height: normal;
    color: #cc3f3f;
  }
  .loader-wrap {
    padding: 35px 0;
    span {
      background-color: #6f4bf1;
    }
    p {
      margin: 19px 0 0;
      opacity: 0.8;
      font-size: 15px;
      color: #21303a;
    }
  }
  .recommendations-title {
    margin: 20px 0 17px 0;
    font-size: 16px;
    font-weight: bold;
    color: #21303a;
  }
  .recommendation-cards-wrapper {
    & > div {
      height: unset !important;
      min-height: 217px;
      max-height: 650px;
      &::-webkit-scrollbar {
        height: 15px;
        width: 15px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 50px;
        background-color: #c9cfd3;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0),
          inset 1px 1px 0px rgba(0, 0, 0, 0);
      }
      &::-moz-scrollbar {
        height: 15px;
        width: 15px;
      }
      &::-moz-scrollbar-track {
        background-color: transparent;
      }
      &::-moz-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 50px;
        background-color: #c9cfd3;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
          inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }
      &::-ms-scrollbar {
        height: 15px;
        width: 15px;
      }
      &::-ms-scrollbar-track {
        background-color: transparent;
      }
      &::-ms-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 50px;
        background-color: #c9cfd3;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
          inset 1px 1px 0px rgba(0, 0, 0, 0.05);
      }
    }
    .recommendation-card {
      margin-bottom: 16px;
      cursor: pointer;
      .card {
        box-shadow: none;
        padding: 16px;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #c9cfd3;
        .content {
          padding: 0;
          height: auto;
          float: unset;
          .header {
            display: flex;
            flex-direction: row;
            margin: 0 0 8px 0;
            opacity: 0.55;
            font-size: 18px;
            font-weight: bold;
            color: #21303a;
          }
          .description {
            margin: 0;
            p {
              margin: 0;
              opacity: 0.68;
              font-size: 15px;
              color: #21303a;
              line-height: 1.6;
              &:first-of-type,
              &:last-of-type {
                margin: 0 0 16px 0;
              }
            }
          }
          &.extra {
            border: none !important;
            .ant-select-selection {
              border: 1px solid #6f4bf1;
              opacity: 0.68;
            }
          }
        }
        &.raised {
          opacity: 1;
          border: 1.5px solid #6f42f5;
          .content {
            .header {
              opacity: 1;
            }
            .description {
              p {
                opacity: 1;
              }
            }
            &.extra {
              .ant-select-selection {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
`;

export const ApplyToFormWrapper = styled.div`
  .customRadioGroup {
    .ant-radio-wrapper-checked {
      span {
        font-weight: bold;
      }
    }
  }
  .apply-to,
  .reschedule {
    padding: 0 24px;
    margin: 20px 0;
    h3 {
      margin: 0 0 12px 0;
      font-size: 16px;
      font-weight: bold;
      color: #21303a;
    }
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      .ant-radio-wrapper {
        margin: 0 0 16px 0;
        font-size: 15px;
        color: #21303a;
      }
    }
  }
  .reschedule {
    .reason-section {
      margin-bottom: 16px;
      & > div {
        margin-top: 8px;
      }
    }
    .reschedule-Notes {
      & > div {
        margin-top: 8px;
      }
      .ant-input {
        border: 1px solid #c9cfd3;
        min-height: 56px;
        max-height: 150px;
        padding: 9px;
        resize: vertical;
        &:focus {
          box-shadow: none;
          border: 1px solid #6f4bf1;
        }
      }
    }
  }
  .label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.01em;
    margin-bottom: 22px;
    &.subHeader {
      color: #6f42f5;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 10px;
    }
  }
`;

export const ConflictsFooterWrapper = styled.div`
  display: 'flex';
  text-align: 'center';
  text-align: center;
  padding: 0.5em 0 1em;
  .buttons {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
`;

export const MileageFooterWrapper = styled.div`
  .mileage-header {
    margin-bottom: 0.5em !important;
  }
  .mileage-error {
    margin: 0 0 2em !important;
  }
`;

export const RescheduleWrapper = styled.div`
  .reschedule-message {
    margin-bottom: 0.7em !important;
  }
`;

export const MEDWrapper = styled.div`
  margin-bottom: 20px;
  .ant-select-selection-search {
    &.select {
      background-color: green !important;
    }
  }
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #6f42f5 !important;
  }

  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #6f42f5 !important;
  }

  .ui.radio.checkbox input:checked ~ label:after {
    background-color: #6f4bf1;
  }

  .ui.radio.checkbox input:focus:checked ~ label:after {
    background-color: #6f4bf1;
  }
  .grid {
    margin-left: 0px;
    width: 407px;
  }
  .rectangle {
    display: inline-block;
    width: 407px;
    height: 1px;
    background: #c9cfd3;
  }
  .appType {
    width: 369px;
    margin-left: 21px;
    margin-right: 20px;
  }
  .duration {
    min-width: 133px;
    max-width: 133px;
    margin-left: 30px;
  }
  .appoint {
    width: 346px;
    height: 16px;
    color: #415766;
    margin-left: 27px;
    margin-bottom: 53px;
  }
  .appointtype {
    width: 346px;
    height: 16px;
    padding-top: 10px;
    margin-left: 27px;
    margin-bottom: 53px;
  }
  .selector {
    margin-left: -12px;
    margin-top: 7px;
    width: 346px;
    height: 32px;
  }
  .session {
    margin-left: 13px;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 155px;
    height: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.00177778px;
    color: #21303a;
  }
  .popup-box {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 250px;
    height: 420px;
    border: 1px solid #dfdfdf;
    background: #f8f8f8;
    left: 30%;
    bottom: 120%;
    margin-left: -5px;
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .lab {
    position: absolute;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    max-height: 150px;
  }
  .weekly {
    margin-top: 9px;
    width: 133px;
    .ant-select-selector {
      border-radius: 4px;
    }
  }
  .square {
    margin-bottom: 11px;
    border: 1px solid rgba(98, 107, 113, 0.496534);
    box-sizing: border-box;
    border-radius: 4px;
  }
  .freq {
    height: 32px;
    margin-left: 30px;
    margin-bottom: 94px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: red;
    min-width: 120px;
    max-width: 120px;
  }
  .prov {
    margin-left: 40px;
    width: 129px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
  }
  .daily-hours {
    margin-top: 9px;
    width: 129px;
    .ant-select-selector {
      border-radius: 4px;
    }
  }

  .timepicker-style {
    margin-top: 9px;
    width: 133px;
    .ant-picker {
      border-radius: 4px;
    }
  }
  .smart-scheduling {
    display: inline-block;
    .ui.fitted.toggle.checkbox {
      label {
        &::after {
          width: 28px;
          height: 28px;
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05),
            0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
        &::before {
          width: 51px;
          height: 29px;
          border-radius: 15.5px;
          border: 1.5px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
      }
      &.checked {
        label {
          &::after {
            left: 22px;
          }
          &::before {
            background-color: #4cd964 !important;
          }
        }
      }
    }
  }
`;
export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .date-style {
    margin-top: 8px;
    width: 105px;
  }
  .time-style {
    margin-top: 8px;
  }
  .ant-picker {
    border-radius: 5px;
  }
  .ant-calendar-picker,
  .ant-time-picker {
    input {
      border-color: #c9cfd3;
    }
  }
  .date-first-row {
    // max-height: 80px;
    // margin-top: 12px;
    display: flex;
    justify-content: space-around !important;
    .ant-col:nth-child(1) {
      width: 100px;
      margin-right: 15px;
    }
    .ant-col:nth-child(2) {
      margin-right: 15px;
      width: 100px;
    }
    .ant-col:nth-child(3) {
      width: 100px;
    }
    .ant-col {
      .ant-time-picker-panel-inner {
        min-width: 168px;
      }
    }
  }
  .date-second-row {
    display: flex;
    .ant-col:nth-child(1) {
      width: 80px;
    }
  }
  .time-style input[type='time']::-webkit-calendar-picker-indicator {
    background: none !important;
    display: none;
  }
  .time-style input {
    border: 1px solid #6f42f5 !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    overflow: hidden;
    padding-left: 5px;
  }
  .date-style {
    border: 1px solid #6f42f5 !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    overflow: hidden;
    padding-left: 15px;
    width: 100px;
  }
  .date-style input::-webkit-calendar-picker-indicator {
    background: none !important;
    display: none !important;
  }
  .date-style span {
    display: none !important;
  }
  .MuiInput-input {
    background-color: #ffffff !important;
  }
`;

export const ImpactedObserversWrapper = styled.div`
  .impacted-observers-heading {
    font-size: 14px;
    font-family: Lato;
    line-height: 17px;
    font-weight: 700;
    margin-left: 15px;
    color: #6f42f5;
  }
  .observer-event-timing {
    margin-left: 15px;
  }
  .observer_alert {
    position: absolute;
    z-index: 1;
    left: 0%;
    right: 71.74%;
    top: 71.3%;
    bottom: 0%;
    height: 150px;
    width: 100%;
    border-radius: 0px;
    background: #ffffff;
  }
  .observer_alert .content {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 10px;
    padding: 0px 10px 20px 10px;
    background: #ffffff;
  }
  .observer_alert .closeButton {
    float: right;
    font-size: 20px;
    padding: 10px;
  }
  .observer_alert .attention {
    font-family: Lato;
    font-size: 20px;
    padding-top: 40px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
`;

export const TelehealthWrapper = styled.div`
  .hybrid-input {
    position: relative;
    .ant-col > div {
      min-height: 85px !important;
    }
    .ant-col > div {
      margin-bottom: 0 !important;
    }
    .drop-icon {
      bottom: 47px !important;
      position: relative;
      left: 325px !important;
      color: #6f4bf1;
    }
    .dropdown-wrapper {
      position: absolute;
      width: 100%;
      border: 1px solid #6f4bf1;
      border-top: none !important;
      border-radius: 3px;
      height: auto;
      margin-top: -39px;
      background-color: #ffffff;
      z-index: 100;
      transition-timing-function: ease-in;
      transition: 0.2s;
      // overflow-y: scroll;
      // overflow-x: hidden;
    }
    .rss-dropdown {
      overflow-x: scroll;
    }
    input {
      margin-top: 7px;
      border-color: #d9d9d9;
      border-radius: 3px;
    }
    input:focus {
      border-color: #6f4bf1;
    }
    .d-none {
      display: none !important;
    }
    .link-wrapper {
      width: 100% !important;
      align-items: center;
      height: 35px;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0px 5px;
      cursor: pointer;
      span {
        font-size: 14px;
      }
    }
    .link-wrapper:hover {
      background-color: #d3d3d3 !important;
      overflow: visible;
    }
  }

  .smart-input {
    position: relative;
    .ant-col > div {
      min-height: 85px !important;
    }
    .ant-col > div {
      margin-bottom: 0 !important;
    }
    .drop-icon {
      bottom: 47px !important;
      position: relative;
      left: 325px !important;
      color: #6f4bf1;
    }
    .dropdown-wrapper {
      position: absolute;
      width: 100%;
      border: 1px solid #6f4bf1;
      border-top: none !important;
      border-radius: 3px;
      height: auto;
      margin-top: -39px;
      background-color: #ffffff;
      z-index: 100;
      transition-timing-function: ease-in;
      transition: 0.2s;
      scrollbar-width: thin;
      font-size: 10px important;
    }
    input {
      margin-top: 7px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 3px;
      width: 100%;
      border-radius: 3px;
      height: 30px !important;
      font-size: 10px important;
    }
    .ant-input {
      font-size: 11px !important;
    }
    input:focus {
      border-color: #6f4bf1;
    }
    .d-none {
      display: none !important;
    }
    .link-wrapper {
      width: 100% !important;
      align-items: center;
      height: 30px;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0px 5px;
      cursor: pointer;
      span {
        font-size: 11px;
      }
    }
    .link-wrapper:hover {
      background-color: #d3d3d3 !important;
      overflow: visible;
    }
  }
`;
export const CarePlanWaitlistSSWrapper = styled.div`
  margin-left: 24px;
  .row {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
  .status-match,
  .status-guaranteed-shift-match {
    background-color: rgba(214, 240, 186, 0.6);
    padding: 6px;
    border-radius: 20px;
    max-width: fit-content;
    font-weight: 600;
  }
  .status-guaranteed-shift-match {
    margin-right: 5px;
  }
  .status-no-match {
    background-color: rgb(228, 186, 183);
    padding: 6px;
    border-radius: 20px;
    max-width: fit-content;
    color: rgb(125, 18, 18);
    font-weight: 600;
  }
  .status-unavailable,
  .status-accommodation {
    background-color: rgb(240, 242, 243);
    padding: 6px;
    font-size: 15px;
    border-radius: 20px;
    max-width: fit-content;
    color: rgb(50, 56, 62);
    font-weight: 600;
  }
  .status-inner-cont {
    padding: 4px;
  }
  .title {
    font-weight: bold;
  }
  .recommendation-date {
    padding-top: 5px;
  }
`;
