import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

interface Props {
  isStaff: Boolean;
}
export const WaitlistWrapper = styled.div<Props>`
.table-scroll-container {
  overflow-x: auto;
}


.table-scroll-container:active {
  cursor: move;
}
  .waitlist-select-container {
    display:flex
  }
 
  .waitlist-select-container .ant-select{
    width: 165px;
    height: 40px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    // background: var(--background-body, #FFF);
  }
  .ant-badge-count {
    margin-top: 0px !important; 
    right: 6px !important;
    top: 6px !important;
    padding-top: 3px !important;
    background: rgb(111, 66, 245) !important;
    height: 20px !important;
    width: 20px !important;
    border-radius: 50% !important
    display: flex;
    justify-content: center;
  }
  .ant-drawer-title {
    font-weight: 700 !important;
  }
  .Status_No-Match {
    background-color: rgba(228, 186, 183, 1);
    padding: 6px;
    border-radius: 20px;
    max-width: fit-content;
    color: #7D1212;
    font-weight: 600;
  }

  .Status_Unavailable , .Status_Accommodation{
    background-color: #F0F2F3;
    padding: 2px 6px;
    font-size: 15px;
    border-radius: 20px;
    max-width: fit-content;
    color: #32383E;
  font-weight: 600;
  }
.Status_Accommodation{
.ant-select-selection-item{
  color:#32383E;
  font-size:15px;
  font-weight: 600;
  }
}

 .Status_Inactive{
  background-color: rgba(228, 186, 183, 1);
    border-radius: 20px;
    max-width: fit-content;
    color: #7D1212;
    font-weight: 600;
    font-color: #2e652f;
    font-weight: 600;
    background-color:#f8e4e3;
    .ant-select-selector{
  .ant-select-selection-item{
  color:#7D1212;
  font-size:15px;
  font-weight: 600;
  }
  }
}

  .Status_Inner_Cont {
    padding: 4px;
    .ant-select-focused{
    border: none;
    }
    .ant-select{
    .ant-select-arrow{
    padding-top: 2px;
    }
    .ant-select-selector{
    background-color: transparent;
    box-shadow: none !important;
 
    border: none;
    .ant-select-selection-search{
    }
    }
    }
  }

  .Status_Active{
  background-color: rgba(214, 240, 186, 0.6);
    border-radius: 20px;
    max-width: fit-content;
     font-weight:600;
    font-color:#124e12;
   .ant-select-selector{
  .ant-select-selection-item{
  color:#124e12;
  font-size:15px;
  font-weight: 600;
  }
}
  }
  .Status_Match, .Status_Guaranteed-Shift-Match {
    background-color: rgba(214, 240, 186, 0.6);
    padding: 6px;
    border-radius: 20px;
    max-width: fit-content;
     font-weight:600;
    font-color:#124e12;
  }
    
  .staff-filters {
    max-width: 94vw !important;
    margin-left:0px !important;
    padding-left: 14px !important;
  }
  .ant-popover-inner {
    border-radius: 100px;
  }
  .ant-table-row {
    transition: background-color 0.8s ease;
  }
    .ant-select-selection-item{
    padding-left: 0px !important;
    }


  .ant-table-wrapper {
    min-width: 1850px;
    height: 100%;

    border: 1px solid rgba(201, 207, 211, 1);
    border-radius: 10px;
    .ant-table-content {
      &::before {
        width: 100%;
      }
    }
    .provider-select{
      width: 138px;
      }
    .provider-select-languages{
      width: 164px;
      }

 
    
      .provider-transportation{
      width: 110px;
      }
    .ant-skeleton-content {
      display: flex !important;
      justify-content: start !important;
    }
    .ant-table-content:after {
      position: fixed;
    }
    .ant-table-content{
      overflow-x: hidden !important;
      width: ${props => (props.isStaff ? '100%' : '100%')};
    }
      .ant-pagination{
      min-width: ${props => (props.isStaff ? '98%' : '100%')};
      }
    .ant-table-wrapper{
      overflow-x: hidden !important;
    }

    tbody tr td:first-child {
      padding-left: 20px !important;
    }
    table {
      thead tr > :first-child {
        background-size: 100% 3px;
        padding-left: 20px !important;
        background-position: 1px bottom;
        background-repeat: no-repeat;
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 1) 97%,
          transparent 50%,
          transparent
        );
        background-size: auto 2.8%;
        top: 0.5px;
        padding-bottom: 22px;
        text-align: center;
      }
      thead tr > :last-child {
        background-size: 100% 3px;
        background-position: 0px bottom;
        background-repeat: no-repeat;
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 1) 78%,
          transparent 50%,
          transparent
        );
        background-size: auto 2.8%;
        top: 0.5px;
        padding-bottom: 22px;
        text-align: center;
      }

      td {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        color: rgba(65, 87, 102, 1);
        text-align: start;
        max-width: 400px;
      }

         tr > :nth-last-child(2) {
        cursor: pointer;
      }
      .custom-provider-row > :nth-last-child(10) {
        min-width: 140px;
      }
       .custom-provider-row > :nth-last-child(4) {
        min-width: 120px;
      }

      .custom-provider-row > :nth-last-child(9) {
        min-width: 140px;
      }
      .custom-provider-row > :nth-last-child(7) {
        min-width: 104px;
        max-width: 140px;
      }
        .custom-client-row > :nth-last-child(6){
        min-width: 104px;
        max-width: 170px;
        }
     
      .ant-select-selection-placeholder{
        color: rgb(33, 48, 58);
      }

      .custom-provider-row > :nth-last-child(1){
         text-decoration: underline;
      }
      .ant-select-selector{
        border:1px solid #bfbbbb;
        border-radius:6px;
      }
      .ant-select-arrow {
        top:42%;
      }
      td:first-child, td:first-child a {
        color: rgba(105, 135, 202, 1);
      }
      td:nth-child(11) {
        text-decoration: underline;
      }
      .ant-table-thead tr > :last-child {
        padding-right: 14px;
      }
      tbody tr > :nth-last-child(2) {
        max-height: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        // width: 188px;
        max-width: 138px;
      }
      .ant-skeleton-title {
        width: 80% !important;
      }
      .ant-skeleton-content {
        display: block;
      }
      thead tr > :not(:first-child):not(:last-child) {
        background-size: 100% 3px;
        background-position: 0px bottom;
        background-repeat: no-repeat;
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 1) 100%,
          transparent 50%,
          transparent
        );
        background-size: auto 2.8%;
        top: 0.5px;
        // margin-left: 2px;
        padding-bottom: 22px;
      }
      tr {
        background: none;
      }
      border-collapse: collapse;
      border-bottom: 1px solid #e8e8e8;
      .ant-table-thead {
        tr {
          .ant-table-column-title {
            font-family: Lato;
            font-size: 18px;
            font-weight: bold;
            color: #21303a;
          }
          .ant-table-cell {
            padding-bottom: 21px;
            padding-top: 21px;
            font-size: 15px;
            font-weight: 500;
            line-height: 16.8px;
            color: black;
            font-family: Lato;
            text-align: start;
          }
          th {
            &:first-child {
              .ant-table-column-sorter {
                display: table-cell;
              }
            }
            // &:nth-child(2) {
            //   display: flex;
            // }
            padding-left: 9px;
            padding-right: 0;
          }
        }
      }
      .ant-table-row-expand-icon-cell {
        position: absolute;
        border: none;
      }
      td.ant-table-column-sort {
        background: inherit !important;
      }
      .ant-table-row-level-0 {
        background: #ffffff;
        position: relative;
        &:hover {
          .ant-table-row-expand-icon-cell {
            background: none;
          }
        }
        .ant-table-row-expand-icon-cell {
          position: absolute;
          z-index: 5;
          border: none;
          background: none;
          padding-left: 4px;
          text-align: left;
          font-size: 21px;
          color: ${colors.PURPLE};
        }
        &:nth-of-type(odd) {
          // background: #ececec;
        }
        .ant-btn {
          background: transparent;
          &:hover {
            background: ${colors.PURPLE};
          }
        }
        .ant-table-row-cell-break-word {
          word-break: normal;
          white-space: nowrap;
          .go-to-profile {
            margin-right: 100px;
            border: none;
            background: inherit;
            padding: 0;
            font-family: Lato;
            font-size: 14px;
            letter-spacing: 0.15px;
            color: ${colors.ACTIVE_URL};
            cursor: pointer;
            :hover {
              text-decoration: underline;
            }
          }
        }
      }
      .ant-table-row-level-0 {
        td {
          padding: 9px 0 8px 10px;
          box-sizing: border-box;
          border-top: none;
          border-bottom: none;
          border-color: #d5d5d5;

          &:nth-child(2) {
            // height: 50px;
            padding-left: 10px;
            padding-right: 13px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            .fa-file {
              font-size: 15px;
              color: ${colors.PURPLE};
              cursor: pointer;
            }
          }
          &:nth-child(1),
          :nth-child(9),
          :nth-child(10),
          :nth-child(11) {
            cursor: pointer;
          }
        }
        &:last-child {
          td {
            &.ant-table-row-expand-icon-cell {
              border-bottom: none;
            }
          }
        }
      }
      .ant-table-expanded-row-level-1 {
        height: 110px;
        border: 1px solid #c4c6c8;
      }
    }
  }
  .endMessage {
    text-align: center;
  }
  .Action_cont a {
    color: black !important;
  }
  .inner-wrap {
    max-width: 100%;
    padding: 16px 20px;
  }
  .inner-wrap .ant-table-wrapper {
    max-width: 100%;
  }
  .ant-table-cell .no-data{
    color: black;
    padding-bottom: 10px;
  }
  .ant-table-cell .clear-filters{
  font-weight: bold;
  cursor: pointer;
  }
  @media (min-width: 1600px) {
    .provider-select{
        width: 135px !important;
   
}
  .provider-select-languages{
      width: 164px !important;
      }
  .provider-transportation {
      width: 110px !important;
    }
}
`;

export const SelectDropDownWrapper = styled.div`
  // background-color: red;
  .provider-select-options .ant-select-item-option-state {
    display: none !important;
    background-color: rgb(111, 75, 241);
  }
  .ant-select-dropdown {
    width: 100%;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(111, 75, 241);
    border-color: rgb(111, 75, 241);
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgb(232 226 251);
  }
  .custom-dropdown-select {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
  }
  .ant-checkbox-inner {
    border-color: rgb(111, 75, 241);
  }
  .provider-search-text {
    font-size: 15px;
    text-align: center;
  }
  .Provider-select-header {
    font-size: 15px;
    color: #3b4f5c;
    font-weight: 600;
    padding: 6px 10px 0px 10px;
    border-bottom: 1px solid #c7c4c4;
    margin-bottom: 6px;
  }
  .selected-languages-outer-wrapper {
    background-color: white !important;
  }
  .selected-last-index {
    border-bottom: 1px solid #c7c4c4 !important;
  }
  .selected-first-index {
    margin-top: 6px;
  }
`;

export const WaitListPopOver = styled.div`
  max-width: 600px;
  border-radius: 10px;

  .provider_outer_wrap {
    display: flex;
    color: rgba(128, 128, 128, 1);
    font-size: 14px;
    border-bottom: 1px solid rgba(201, 207, 211, 1);
    padding-bottom: 10px;
    margin-right: 6px;
  }

  .client_scroll_container {
    max-height: 300px;
    overflow-y: scroll;
    margin-rigth: 6px;
    margin-bottom: 10px;
  }

  /* Custom scrollbar for WebKit browsers */
  .client_scroll_container::-webkit-scrollbar {
    width: 8px; /* Width of the entire scrollbar */
    height: 12px; /* Height of the entire scrollbar */
  }

  .client_scroll_container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .client_scroll_container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .provider_current_info {
    border-bottom: 1px solid white;
    justify-content: space-between;
  }

  .provider_client_header {
    margin-bottom: 10px;
  }

  .provider_avail {
    font-size: 14px;
    font-weight: 500;
    text-align: right;
  }

  .provider_container {
    margin-right: 20px;
    margin-top: 5px;
  }

  .provider_address {
    font-size: 13px;
    color: rgba(128, 128, 128, 1);
    font-weight: 400;
    margin-top: 6px;
  }

  .main_Head {
    display: flex;
    justify-content: space-between;
  }

  .Action_Icon {
    margin-right: 10px;
    font-size: 16px;
  }

  .DayAvail_Outer_Cont {
    min-width: 250px;
  }

  .Provider_basic_info {
    font-size: 13px;
    font-weight: 400;
  }

  .basic_info_title {
    font-weight: 500;
    font-size: 14px;
  }

  .basic_collective_capacity {
    margin-right: 10px;
  }

  .Ind_section {
    margin-bottom: 10px;
  }

  .Note_Title {
    padding: 0px 10px 5px 10px;
    border-bottom: 1px solid rgba(201, 207, 211, 1);
    font-weight: 600;
    font-size: 16px;
  }

  .Notes_Cont {
    max-width: 300px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 6px;
    line-height: 22px;
    transition: opacity 0.5s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 4px;
  }

  .View_Notes_Btn {
    color: #6784c5;
    cursor: pointer;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
  }

  .schedule_link {
    color: #3e5766;
  }

  .border_underline {
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(201, 207, 211, 1);
  }

  .main_Content {
    font-size: 18px !important;
    font-weight: 700;
    margin-right: 12px;
  }

  .provider_main_content {
    font-size: 15px !important;
    color: rgba(74, 112, 198, 1);
  }

  .Action_Schedule {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
    padding-bottom: 8px;
    cursor: pointer;
  }

  .Action_Schedule:hover {
    color: rgba(111, 66, 245, 1);
  }

  .personal_cont {
    font-size: 14px;
    font-weight: 500;
    color: rgba(74, 112, 198, 1);
  }

  .provider_employment {
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    margin-top: 6px;
  }

  .dob {
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
  }

  .inner_Head {
    font-size: 14px;
    font-weight: 700;
    margin-top: 15px;
    margin-right: 5px;
  }

  .main_head_cont {
    font-size: 14px;
    font-weight: 400;
    color: rgba(65, 87, 102, 1);
  }

  .footer_cont {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .Avail_title {
    font-size: 16px;
    font-weight: 600;
    margin-right: 30px;
  }

  .DayAvail_Head {
    margin-bottom: 10px;
    padding-bottom: 6px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(201, 207, 211, 1);
  }

  .updated_From {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .clientDayAvil_Cont {
    display: flex;
    margin-bottom: 16px;
  }

  .dayOfWeek {
    padding: 0px 10px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    width: 110px;
  }

  .avail_Time {
    font-size: 12px;
    font-weight: 400;
    color: rgba(65, 87, 102, 1);
    max-width: 404px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    font-size: 14px;
    font-weight: 400;
    color: rgba(65, 87, 102, 1);
  }

  .max-content-scroll {
    max-height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 0;
  }

  .max-content-scroll::-webkit-scrollbar {
    width: 4px;
  }

  .grid-item {
    border-bottom: 1px solid rgba(201, 207, 211, 0.35);
    padding: 10px;
  }
`;

export const ActionPopOverStyle = styled.div`
  border-radius: 10px;

  .Action_Icon {
    margin-right: 10px;
    font-size: 16px;
  }
  .provider_edit {
    margin-bottom: 10px;
  }

  .schedule_link {
    color: #3e5766;
  }

  .main_Content {
    font-size: 18px !important;
    font-weight: 700;
    margin-right: 12px;
  }
  .Action_Schedule {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
    padding-bottom: 8px;
    cursor: pointer;
  }
  .Action_Schedule:hover {
    color: rgba(111, 66, 245, 1);
  }

  .inner_Head {
    font-size: 14px;
    font-weight: 700;
    margin-top: 15px;
    margin-right: 5px;
  }

  .main_head_cont {
    font-size: 14px;
    font-weight: 400;
    color: rgba(65, 87, 102, 1);
  }
  .provider_main_Head {
    display: flex;
  }
`;

export const WaitList_Side_Menu = styled.div`
  .WaitList_Count {
    color: rgba(111, 66, 245, 1);
  }
  .Main_Menu {
    height: 100%;
    border-right: 1px solid rgba(221, 231, 238, 1);
    box-shadow: rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    // justify-content:space-between;
  }
  .SideMenu_Expandable {
    padding-right: 30px;
    text-align: end;
    margin: 8px 0px 20px 0px;
  }
  .main_head_cont {
    margin-bottom: 200px;
  }
  .waitList_schedule {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 10px 20px;
    border-bottom: 1px solid rgba(99, 107, 116, 0.3);
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(65, 87, 102, 1);
  }
  .expandable_Content {
    display: flex;
    justify-content: space-between;
    margin-left: 4px;
    margin: 20px 0px;
  }
  .expandable_Content p {
    text-align: start;
    margin-left: 4px;
    font-size: 14px;
    font-weight: 600;
  }
  .ant-menu-item {
    border-bottom: 1px solid rgba(99, 107, 116, 0.3);
  }
  .ant-menu {
    padding: 0px 8px;
  }
  .Client_List {
    font-size: 18px;
    font-weight: 600;
    margin-left: 4px;
  }
  .ant-menu-item-selected {
    color: rgba(111, 66, 245, 1);
  }
  .ant-menu-light .ant-menu-item:hover {
    color: rgba(111, 66, 245, 1) !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: white;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: white;
  }
  .Main_Menu svg {
    font-size: 20px;
    color: grey;
  }

  .Waitlist svg {
    color: #6f4bf1 !important;
  }
`;

export const WaitListFilterWrapper = styled.div<Props>`
  max-width: ${props => (!props.isStaff ? '100%' : '100%')};

  .Additional_Filter_Layout {
    display: flex;
    align-item: center;
    padding-top: 20px;
    cursor: pointer;
  }
     .client-wrap {
  .infinite-scroll-component__outerdiv{
  .infinite-scroll-component{
   .table-scroll-container{
    min-height: 185px;
    }
  }
  }
}
  .ind-table-scroll{
  p{
  position:relative;
  bottom: 4px;
  }
  }
  .filter-input_wrapper {
    max-width: 168px !important;
  }
  .staff-input_wrapper{
    width 100% !important;
  }

  .Additional_Filter_Title {
    color: rgba(111, 66, 245, 1);
    margin-top: 3px;
    margin-left: 4px;
  }
  img {
    object-fit: contain;
  }
  .dropdown-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0px 28px;
  }
  .dropdown-wrapper > div {
    width: 12%;
  }
    .dropdown-wrapper > div:nth-last-child(2) {
    width: 13%;
}
  .provider-dropdown-wrapper > div {
    width:  ${props => (!props.isStaff ? '16%' : '13.6%')};
  }
    

  @media screen and (min-width: 1440px) {
    .filter-input_wrapper {
      min-width: 100% !important;
      margin-right: 20px;
    }
    .dropdown-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
`;

//   .Additional_Filter_Layout {
//     display: flex;
//     align-item: center;
//     padding-top: 20px;
//     cursor: pointer;
//   }
//   .filter-input_wrapper {
//     max-width: 168px !important;
//   }
//   .staff-input_wrapper{
//     max-width 100% !important;
//   }

//   .Additional_Filter_Title {
//     color: rgba(111, 66, 245, 1);
//     margin-top: 3px;
//     margin-left: 4px;
//   }
//   img {
//     object-fit: contain;
//   }
//   .dropdown-wrapper {
//     display: flex;
//     justify-content: space-between;
//     padding: 0px 28px;
//   }
//   .dropdown-wrapper > div {
//     width: 13%;
//   }
//   .provider-dropdown-wrapper > div {
//     width: 16%;
//   }

//   @media screen and (min-width: 1440px) {
//     .filter-input_wrapper {
//       min-width: 100% !important;
//       margin-right: 20px;
//     }
//     .dropdown-wrapper {
//       display: flex;
//       justify-content: space-between;
//     }
//   }
// `;

export const DrawerWrapper = styled.div`
  height: 95%;
  overflow: auto;
  padding-left: 24px;
  padding-right: 24px;
  .ant-drawer-title {
    font-size: 28px !important;
    font-weight: 700 !important;
  }
  .Avail_Container {
    padding: 10px;
    min-height: 540px;
  }
  .AddtnlFilter_Avail_header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .Addtnl_Form_Content p:first-child {
    font-weight: 700;
    margin-bottom: 4px;
  }
  .footer_btn {
    display: flex;
    justify-content: space-between;
    width: 78%;
    // margin-right:100px;
  }
  .clear-btn:focus {
    color: black !important;
    border: 1px solid grey !important;
  }
  .clear-btn {
    border-radius: 4px;
    width: 125px;
    margin-right: 20px;
    margin-left: 10px;
  }
  .clear-btn:hover {
    color: black;
    border: 1px solid grey;
  }
  .AddtnlFilter_Avail {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    margin-bottom: 15px;
    padding-top: 10px;
    border-top: 1px solid #636b74;
  }
  .AddtnlFilter_Avail_first {
    border-top: none;
    padding-top: 0px;
  }
  .Addtnl_Form_Content {
    margin-top: 40px;
  }
  .AddtnlFilter_Title {
    font-size: 16px;
    font-weigth: 600;
    // font-family: Helvetica Neue;
  }
  .cursor {
    cursor: pointer;
  }
  .Addtnl_Form_Content {
    // margin-top: 40px;
  }
  .saved-filters_checkbox {
    padding-top: 10px;
  }
  .time-formItem {
    display: flex;
    margin-top: 12px;
  }
  .Btn_container {
    margin-top: auto;
  }
  .location_label .top-wrap label {
    font-weight: 700;
  }
  .filter_cls {
    margin-top: 0px;
  }
  input:checked ~ label:after {
    background: #6f4bf1;
    color: white !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    padding-top: 4px;
  }
  .ant-checkbox-inner::after {
    border: -2px solid #fff;
  }
  .ui.checkbox label:before {
    border-color: #6f4bf1;
  }
  .confirm-btn {
    background: #6f4bf1;
    color: white;
    border-radius: 6px;
    padding: 0px 18px 0px 18px;
    width: 125px;
  }
  .saved_filters_div {
    padding-top: 10px !important;
    label {
      &::before,
      &::after {
        border-radius: 500rem !important;
      }
    }
  }
  .saved_filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .saved-filters_checkbox {
      width: 100%;
      padding: 0px input {
        transition: all 0.5s ease-in-out;
      }
      input:checked ~ label:after {
        background-color: #6f4bf1 !important;
      }
      label {
        font-size: 15px;
        line-height: 1.27;
        color: #415766;
        transition: all 0.5s ease-in-out;
        padding-left: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 95%;
        &::before,
        &::after {
          width: 16px;
          height: 16px;
          border-color: #6f4bf1;
          transition: all 0.5s ease-in-out;
          border-radius: 2px;
          margin-top: 2px;
          margin-right: 10px;
        }
        &::after {
          padding-top: 2px;
          color: #fff;
          font-size: 11px;
          background-color: #6f4bf1;
        }
      }
    }
    .disabled_edit_btn {
      all: unset;
      font-size: 15px;
      cursor: auto;
      text-decoration: underline;
      white-space: nowrap;
      cursor: auto !important;
      opacity: 0.5;
      color: #000;
    }
    .edit_btn {
      all: unset;
      font-size: 15px;
      color: #0d77b5;
      cursor: pointer;
      text-decoration: underline;
      white-space: nowrap;
    }
    .delete_filter {
      font-size: 14px;
      color: rgb(196, 28, 28);
      padding-top: 10px;
    }
    .delete_btn {
      all: unset;
      font-size: 15px;
      color: #c41c1c;
      cursor: pointer;
      text-decoration: underline;
      white-space: nowrap;
    }
  }
  .error {
    color: #c41c1c;
  }
  .ant-btn[disabled] {
    background-color: #d9d9d9;
  }
`;

export const DrawerFooterWrapper = styled.div`
  .waitlist-sidebar-footer {
    border-top: 1px solid #636b74;
    padding-left: 24px;
  }
  .Btn_container {
    position: absolute;
    bottom: 12px;
  }
  .clear-btn {
    border-radius: 4px;
    width: 125px;
    margin-right: 20px;
    margin-left: 10px;
  }
  .confirm-btn {
    background: rgb(111, 75, 241);
    color: white;
    border-radius: 6px;
    padding: 0px 18px;
    width: 125px;
  }
`;
export const TitleWrapper = styled.div`
  min-height: 96px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8ecef;
  position: relative;
  h1 {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.38px;
  }
  .inner-wrap {
    box-sizing: border-box;

    padding: 16px 55px 16px 95px;
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after {
      display: none;
    }
  }
  .inner-wrap-waitList {
    box-sizing: border-box;

    padding: 16px 55px 16px 38px;
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after {
      display: none;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    > * {
      margin-left: 15px;
    }
  }
`;

export const WaitListTitleWrapper = styled.div`
  min-height: 96px;
  border-bottom: 1px solid #e8ecef;
  position: relative;
  h1 {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.38px;
  }
  .inner-wrap {
    box-sizing: border-box;

    padding: 16px 55px 16px 95px;
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after {
      display: none;
    }
  }
  .inner-wrap-waitList {
    box-sizing: border-box;
    padding: 16px 55px 16px 38px;
    position: relative;
    width: 94%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after {
      display: none;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    > * {
      margin-left: 15px;
    }
  }
`;

export const ExpandedRow = styled.div`
  display: flex;
  margin-left: 31%;
  .left,
  .right {
    display: flex;
    justify-content: space-between;
    &_lColumn,
    &_rColumn {
      display: flex;
      flex-direction: column;
      span {
        padding-bottom: 6px;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0.13px;
        color: ${colors.AVATAR_INITIAL};

        &.with_underline {
          text-align: left;
          text-decoration: underline;
        }
      }
    }
    &_lColumn {
      span {
        font-weight: 600;
      }
    }
  }
  .left {
    width: 193px;
    .request {
      font-weight: 600;
    }
  }
  .center {
    display: flex;
    justify-content: center;
    width: 43px;
    line-height: 54px;
    font-size: 54px;
  }
  .right {
    margin-top: 23px;
    width: 165px;
  }
`;
