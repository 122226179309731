import { useReactiveVar } from '@apollo/client';
import {
  convertFilteredToSearched,
  isAttendeeInSearched
} from 'helpers/calendarHelper';
import useEventsFetcherHook from 'hooks/eventsFetcherHook';
import { isEqual } from 'lodash';
import { IPhantomEvent } from 'model/calendar/events';
import { ICalendarResource } from 'model/calendar/filters';
import { calendarGroup } from 'model/calendar/groups';
import {
  IClient,
  IFullAppointmentType,
  IProvider,
  ISmartResultCard,
  ISmartResultItemForm
} from 'model/v2';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useRef
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  mainCalendarPrefDayVar,
  plannerCalendarPrefViewVar,
  removePhantomEvents,
  updateCalendarPref
} from 'utils/cache/calendar';
import {
  calendarEventsPrefVar,
  calendarFiltersPrefVar
} from 'utils/cache/filters';

import { formatProviderDisplayName } from 'utils/format';

import { mapSmartEventToPhantomEvent } from 'utils/mappers/response/phantomEvents';
import { EVENTS_FETCHING_ACTIONS, plannerContext } from '..';
import {
  useAddPhantomEvents,
  useAddProviderToCalendar,
  useApptTypesMap
} from './CustomPhantomHooks';
import moment from 'moment';
import { useRemoveProviderFromCalendar } from '../FormPhantomEvents/CustomPhantomHooks';

interface Props {
  visibleResults: boolean;
  providersMap: Map<number, IProvider>;
  client: IClient;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<SetStateAction<ICalendarResource[]>>;
  apptTypes: IFullAppointmentType[];
  hasSingleDT: any;
  adminTypes: IFullAppointmentType[];
  openingCardsStatus?: boolean;
  openingCards?: ISmartResultCard[];
  selectedCards?: Set<number>;
}

const SmartPhantomEvents: React.FC<Props> = ({
  providersMap,
  paginationArray,
  setPaginationArray,
  visibleResults,
  apptTypes,
  client,
  hasSingleDT,
  adminTypes,
  openingCardsStatus,
  openingCards,
  selectedCards
}) => {
  const { removeAllProvidersFromCalendar } = useRemoveProviderFromCalendar();
  const methods = useFormContext();
  const selectedGroupRef = useRef<ICalendarResource[] | null>();
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const calendarView = useReactiveVar(plannerCalendarPrefViewVar);
  const filters = useReactiveVar(calendarFiltersPrefVar);
  const appTypesMap = useApptTypesMap(apptTypes);
  const { setIsLoading, setIsCalendarLoading, isCalendarLoading } = useContext(
    plannerContext
  );
  const adminTypesMap = useApptTypesMap(adminTypes);
  const results: ISmartResultItemForm[] | undefined = useWatch({
    control: methods.control,
    name: 'results'
  });
  const smartEventRef = useRef(results);
  if (!isEqual(smartEventRef.current, results)) {
    smartEventRef.current = results;
  }
  const { getStaffEvents } = useEventsFetcherHook(
    selectedGroupRef && selectedGroupRef.current
      ? selectedGroupRef.current
      : undefined,
    setIsLoading,
    setIsCalendarLoading,
    isCalendarLoading,
    EVENTS_FETCHING_ACTIONS.APPLY_SEARCH,
    setPaginationArray,
    paginationArray,
    [],
    selectedGroupRef.current as ICalendarResource[]
  );

  const addPhantomEvents = useAddPhantomEvents();
  const selectedCardDate: (moment.MomentInput | undefined)[] = [];
  const { addProviderToCalendar } = useAddProviderToCalendar(
    getStaffEvents,
    filters,
    calendarView,
    calendarDate
  );
  useMemo(
    () => {
      if (!smartEventRef.current || !visibleResults) {
        return;
      }
      const uniqueProviderIds = new Set<number>();
      const selectedProviders: ICalendarResource[] = [];
      const selectedCardsArr = Array.from(selectedCards!);
      smartEventRef.current!.forEach(
        (event: ISmartResultItemForm, index: number) => {
          if (event.isChecked) {
            if (selectedCardsArr[selectedCardsArr.length - 1] === index)
              selectedCardDate.push(
                moment(event.startTime).format('YYYY-MM-DD')
              );
            uniqueProviderIds.add(event.provider?.id!);
          }
        }
      );
      if (uniqueProviderIds.size > 0) removeAllProvidersFromCalendar();
      for (const providerId of uniqueProviderIds) {
        const currentProvider = providersMap.get(providerId);
        const selectedProvider: ICalendarResource = {
          ...currentProvider,
          id: currentProvider?.id!,
          displayName: formatProviderDisplayName(currentProvider),
          attendeeType: calendarGroup.provider
        };
        if (isAttendeeInSearched(providerId) && selectedProvider) {
          const shouldFetchEvents = convertFilteredToSearched(selectedProvider);
          if (!shouldFetchEvents) continue;
        }

        selectedProviders.push(selectedProvider);
      }
      if (selectedProviders.length !== 0)
        selectedGroupRef.current = selectedProviders;

      addProviderToCalendar(selectedProviders, selectedCardDate[0]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      smartEventRef.current,
      visibleResults,
      addProviderToCalendar,
      providersMap,
      openingCardsStatus,
      openingCards
    ]
  );
  useMemo(() => {
    updateCalendarPref(moment(selectedCardDate[0]));
  }, [smartEventRef.current, openingCards]);

  useMemo(() => {
    if (!smartEventRef.current) {
      return;
    }
    setTimeout(() => {
      const currentEvents = calendarEventsPrefVar();
      const phantomEvents: IPhantomEvent[][] = [];
      smartEventRef.current!.forEach((event: ISmartResultItemForm) => {
        if (!event.isChecked) {
          return;
        }
        const mappedPhantomEvents = mapSmartEventToPhantomEvent(
          event,
          calendarView,
          calendarDate,
          client,
          appTypesMap,
          providersMap,
          hasSingleDT,
          adminTypesMap
        );
        phantomEvents.push(mappedPhantomEvents);
      });
      const allPhantomEvents = phantomEvents.flat(1);
      if (allPhantomEvents.length > 0 && visibleResults) {
        addPhantomEvents(currentEvents, allPhantomEvents);
      } else {
        removePhantomEvents(calendarEventsPrefVar());
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calendarDate,
    calendarView,
    smartEventRef,
    visibleResults,
    appTypesMap,
    providersMap,
    smartEventRef.current,
    openingCards
  ]);
  return <></>;
};

export default React.memo(SmartPhantomEvents);
