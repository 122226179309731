import { GET_CLINIC_LIST_WITH_STAFF } from 'api/graphql/queries/clinics';
import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { getApptType } from 'utils/common';
import { NO_PREFERENCE_OPTION } from 'utils/constants/default';
import {
  CLINIC_PROVIDERS,
  CLINIC_TYPES,
  LOCATION_TYPE_LIST
} from 'utils/constants/lists';
import { formatAttendeeNameV2, formatClinicHubOption } from 'utils/format';
import { toProviderOption } from 'utils/mappers/form';
import { alphabeticalSort } from 'utils/sort';
import { useAppointmentTypes } from '../../newAppointmentHooks';
import { PROVIDER_STATUS_LIST } from 'model/v2';

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

function getFormatedClinicType(clinicType) {
  return ` - ${capitalize(clinicType)}`;
}

function getClinicFormatedOption(clinic) {
  return {
    value: clinic.id,
    label: `${clinic.clinicName} (${clinic.clinicAbbreviation})${
      clinic.clinicType ? getFormatedClinicType(clinic.clinicType) : ''
    }`
  };
}

function checkAppointmentTypeProviderV2(provider, apt) {
  if (
    apt &&
    apt.id &&
    provider.status !== PROVIDER_STATUS_LIST.TERMINATED &&
    provider.status !== PROVIDER_STATUS_LIST.LEAVE_OF_ABSENCE
  ) {
    // check if the provider has this appointment type
    return provider.appointmentTypes?.some(a => a.id === apt.id);
  }
  return false;
}

function CheckTelehealthProvider(
  providerTeleHealth,
  clientState,
  clinicName,
  referenceArray,
  provider
) {
  providerTeleHealth.filter(Providertele => {
    if (
      (Providertele.includes('All') && Providertele.includes(clientState)) ||
      Providertele.includes(clinicName)
    ) {
      referenceArray.push(provider);
    }
  });
}

function checkAppointmentTypeProvider(provider, apt) {
  if (apt && apt.id) {
    // check if the provider has this appointment type
    return provider.appointmentTypes.some(a => a.id === apt.id);
  }
  return false;
}

function setClinicStaffV2(clinic, appointmentType, referenceArray) {
  clinic?.providers?.forEach(provider => {
    if (
      checkAppointmentTypeProviderV2(provider, appointmentType) ||
      !appointmentType
    ) {
      referenceArray.push(provider);
    }
  });
}
function setClinicStaffForRegistredProvider(
  clinic,
  clientClinic,
  appointmentType,
  referenceArray
) {
  clinic?.providers?.forEach(provider => {
    if (provider.telehealth) {
      if (
        checkAppointmentTypeProvider(provider, appointmentType) ||
        !appointmentType
      ) {
        CheckTelehealthProvider(
          provider.telehealth,
          clientClinic.clinicState,
          clientClinic.name,
          referenceArray,
          provider
        );
      }
    }
  });
}

function setClinicStaff(clinic, appointmentType, referenceArray) {
  clinic.staffList.forEach(provider => {
    // check if provider has this appointment type
    if (
      checkAppointmentTypeProvider(provider, appointmentType) ||
      !appointmentType
    ) {
      referenceArray.push(provider);
    }
  });
}

function getClinicPriority(clinic, homeClinic) {
  const homeId = homeClinic?.id;
  const homeHub = homeClinic?.clinicHub?.id;
  const clinicId = clinic?.id;
  const clinicHub = clinic?.clinicHub?.id;

  if (
    !homeHub &&
    ((!clinicHub && homeId === clinicId) || clinicHub === homeId)
  ) {
    // Home is Hub
    return 1;
  } else if (!!homeHub && clinicId === homeHub) {
    // Home is Spoke finding Hub
    return 1;
  } else if (!!homeHub && !!clinicHub && homeHub === clinicHub) {
    // Two Spokes same Hub
    return 1;
  }
  return -1;
}

export function getCurrentClinicsOptionsV2(
  client,
  clinicsMap,
  appointmentType,
  clinicPreference,
  smart
) {
  const staffList = [];
  const allClinics = [];
  const clientClinic = clinicsMap.get(client?.clinic?.id);
  clinicsMap.forEach(clinic => {
    allClinics.push({
      ...clinic,
      priority: getClinicPriority(clinic, clientClinic)
    });
  });
  if (clinicPreference === CLINIC_PROVIDERS[0].id && clientClinic) {
    // Home Clinic
    setClinicStaffV2(clientClinic, appointmentType, staffList);
  } else if (clinicPreference === CLINIC_PROVIDERS[1].id && clientClinic) {
    // Home Clinic Network
    allClinics.forEach(clinic => {
      if (clinic.priority > 0) {
        setClinicStaffV2(clinic, appointmentType, staffList);
      }
    });
    // Registred Provider Network
  } else if (clinicPreference === CLINIC_PROVIDERS[3].id && clientClinic) {
    allClinics.forEach(clinic => {
      setClinicStaffForRegistredProvider(
        clinic,
        clientClinic,
        appointmentType,
        staffList
      );
    });
  } else if (clinicPreference === CLINIC_PROVIDERS[2].id) {
    // All Clinics
    allClinics.forEach(clinic =>
      setClinicStaffV2(clinic, appointmentType, staffList)
    );
  }

  const clinicOptionsList = allClinics
    .sort((a, b) => {
      if (b.priority > a.priority) return 1;
      if (b.priority < a.priority) return -1;
      return alphabeticalSort(a.name, b.name);
    })
    .map(x => formatClinicHubOption(x));
  // Sort Staff
  staffList.sort((a, b) => alphabeticalSort(a.firstName, b.firstName));
  const filteredStaffList = (clinicPreference === CLINIC_PROVIDERS[0].id &&
  smart
    ? [NO_PREFERENCE_OPTION, ...staffList]
    : staffList
  ).map(p => {
    const clinicAbbreviation =
      clinicPreference !== CLINIC_PROVIDERS[0].id
        ? clinicsMap.get(p.clinic?.id)?.abbreviation
        : null;

    const label = formatAttendeeNameV2(
      p.name,
      clinicAbbreviation,
      appointmentType?.title
    );
    return {
      value: p.id,
      label: p.id === NO_PREFERENCE_OPTION.id ? p.value : label,
      displayName: p.displayName
    };
  });
  return {
    clinicOptionsList,
    filteredStaffList
  };
}

function getCurrentClinicsOptions(
  locationType,
  clientClinicId,
  clinics,
  appointmentType,
  clinicPreference
) {
  const clinicOptionsList = [];
  const filteredStaffList = [];
  const clinicsDropdownAvalability = locationType === LOCATION_TYPE_LIST[0].id;
  let clinicsDropdownDisabled = true;

  // fetch client clinic
  const clientClinic = clinics?.find(cl => cl.id === clientClinicId);

  // In clinic hub and spoke epic
  const _false = false;
  if (
    locationType === LOCATION_TYPE_LIST[0].id &&
    Array.isArray(clinics) &&
    clientClinic &&
    _false
  ) {
    // 1) fetch current clinic

    let hubId;
    if (clientClinic?.clinicType === CLINIC_TYPES[0].id) {
      hubId = clientClinicId;
      clinicsDropdownDisabled = false;
    } else if (clientClinic?.clinicType === CLINIC_TYPES[1].id) {
      hubId = clientClinic.clinicHub;
      clinicsDropdownDisabled = false;
    }

    if (hubId) {
      clinics?.forEach(clinic => {
        // clinic belongs to local network
        if (clinic.id === hubId || clinic.clinicHub === hubId) {
          clinicOptionsList.push(getClinicFormatedOption(clinic));
          setClinicStaff(clinic, appointmentType, filteredStaffList);
        }
      });
    } else {
      // in clinic will be disabled but current client clinic will be shown as selected
      clinicOptionsList.push(getClinicFormatedOption(clientClinic));
      setClinicStaff(clientClinic, appointmentType, filteredStaffList);
    }
  } else if (
    // case off site and telehealth which depends on the clinic preference
    Array.isArray(clinics) &&
    clientClinic
  ) {
    if (clinicPreference !== CLINIC_PROVIDERS[0].id) {
      //  all clinics case
      clinics.forEach(clinic =>
        setClinicStaff(clinic, appointmentType, filteredStaffList)
      );
    } else {
      // home clinic case
      setClinicStaff(clientClinic, appointmentType, filteredStaffList);
    }
  }
  // sort clinics by name
  clinicOptionsList.sort((a, b) => alphabeticalSort(a.label, b.label));

  // sort staff list by alphabetical
  filteredStaffList.sort((a, b) =>
    alphabeticalSort(a.displayName, b.displayName)
  );

  return {
    clinicOptionsList,
    clinicsDropdownAvalability,
    clinicsDropdownDisabled,
    filteredStaffList
  };
}

export const useClinicsAndStaffFilters = (
  apptTypeTitle,
  isClinical,
  clinicPreference,
  clientClinicId,
  locationType,
  smart = true
) => {
  const { loading, data, error } = useQuery(GET_CLINIC_LIST_WITH_STAFF);
  const { apptTypes } = useAppointmentTypes();

  // get current appointment type for filtering the staff
  const clinicsMap = useMemo(() => {
    const clinicsMap = new Map();

    data?.clinicsWithoutRoomsWithoutClients.forEach(cl => {
      clinicsMap.set(cl.id, cl);
    });

    return clinicsMap;
  }, [data]);

  const {
    clinicOptionsList,
    // clinicsDropdownAvalability, // should be to show or hide clinics dropdown
    clinicsDropdownDisabled,
    filteredStaffList
  } = useMemo(() => {
    const appointmentType = getApptType(
      { title: apptTypeTitle },
      apptTypes || []
    );
    return getCurrentClinicsOptions(
      locationType,
      clientClinicId,
      data?.clinicsWithoutRoomsWithoutClients,
      appointmentType,
      clinicPreference
    );
  }, [
    data,
    clientClinicId,
    locationType,
    apptTypes,
    apptTypeTitle,
    clinicPreference
  ]);

  const staffList = useMemo(() => {
    return data?.clinicsWithoutRoomsWithoutClients
      ? data.clinicsWithoutRoomsWithoutClients.reduce(
          (staffList, currentClinic) => {
            return [...staffList, ...currentClinic.staffList];
          },
          []
        )
      : [];
  }, [data]);

  return {
    loading,
    error,
    staffList,
    clinics: data?.clinicsWithoutRoomsWithoutClients || [],
    clinicsDropdownAvalability: false, //TODO: should return to return the variable later
    clinicOptionsList,
    clinicsDropdownDisabled,
    filteredStaffList: toProviderOption(
      filteredStaffList,
      apptTypeTitle,
      clinicsMap,
      clinicPreference,
      smart
    )
  };
};
