import { Button, Select } from 'lib/ui';
import { Input, Flex, Row, Col } from 'antd';
import React from 'react';
import NotesIcon from 'assets/img/icon-notes-chat.svg';
import { Avatar, Badge, Space } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_CARE_PLAN } from 'api/graphql/mutations/careplan';
import WaitListDropDown from 'views/components/waitlistPage/components/waitListDropDown';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '../routes';
import { TARGET_BLANK } from '../routes';
import { DecimalInput } from 'lib/ui/input/input';
import { EventSessionType } from 'model/eventType';

export const waitListSSStatus = {
  0: 'No-Match',
  1: 'Match',
  2: 'Unavailable',
  3: 'Guaranteed-Shift-Match',
  null: 'Unavailable'
};

export const WAITLIST_COLUMNS = (selectedItem, handleSchedule) => [
  {
    title: 'Patient',
    dataIndex: 'patient',
    sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    sortDirections: ['descend', 'ascend'],
    width: '12.5%'
  },
  {
    title: 'Home Clinic',
    dataIndex: 'clinicName',
    sorter: (a, b) => a.clinicName.localeCompare(b.clinicName),
    sortDirections: ['descend', 'ascend'],
    width: '13.2%'
  },
  {
    title: 'Appt. Type',
    dataIndex: 'appointmentType',
    sorter: (a, b) => a.appointmentType.localeCompare(b.appointmentType),
    sortDirections: ['descend', 'ascend'],
    width: '13.2%'
  },
  {
    title: 'Appt. Sub Type',
    dataIndex: 'appointmentSubType',
    sorter: (a, b) => a.appointmentSubType.localeCompare(b.appointmentSubType),
    sortDirections: ['descend', 'ascend'],
    width: '13.2%'
  },
  {
    title: 'To Fulfill',
    dataIndex: 'toFulfill',
    sorter: (a, b) => a.toFulfill.localeCompare(b.toFulfill),
    sortDirections: ['descend', 'ascend'],
    width: '10.7%'
  },
  {
    title: 'Availability',
    dataIndex: 'clientAvailability',
    sorter: (a, b) => a.clientAvailability.localeCompare(b.clientAvailability),
    sortDirections: ['descend', 'ascend'],
    width: '10.7%'
  },
  {
    title: 'Waiting',
    dataIndex: 'waiting',
    render: text => (text ? text : 'N/A'),
    sorter: (a, b) => a.waiting.localeCompare(b.waiting),
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'ascend',
    width: '10.7%'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (_text, record) => {
      return (
        record.canSchedule && (
          <Button
            className="secondary"
            onClick={e => {
              handleSchedule(e, record);
            }}
            loading={selectedItem?.key === record.key}
            disabled={selectedItem && selectedItem?.key !== record.key}
          >
            Schedule
          </Button>
        )
      );
    }
  }
];

const isTableLoader = content => {
  if (typeof content === 'string') return true;
  else return false;
};

export const CURRENT_WAITLIST_COLUMNS = (
  waitListReasons,
  PopOverComp,
  WaitListPopOver,
  setShowSidebar,
  setSidebarData,
  waitListDataLoading,
  handleNoteAdded,
  setActiveTab,
  showSidebar,
  handleWaitListUpdate,
  requestedOptions
) => {
  const cpStatusPendingOptions = [
    {
      value: 'Pending Scheduling',
      label: 'Pending Scheduling'
    },
    {
      value: 'Waitlisted',
      label: 'Waitlisted'
    }
  ];
  return [
    {
      title: 'Patient Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        let { isLoaderView } = record;
        const textData = (
          <Link
            className="schedule_link"
            to={ROUTE_PATHS.CLIENT_AVAILABILITY + record?.clientId}
            target={TARGET_BLANK}
          >
            {text}
          </Link>
        );
        return !isLoaderView ? (
          <WaitListPopOver>
            <PopOverComp
              displayContent={textData}
              clientInfo={record}
              popOverType={'Clientcontent'}
              placement="rightBottom"
              setShowSidebar={setShowSidebar}
            ></PopOverComp>
          </WaitListPopOver>
        ) : (
          <div>{text}</div>
        );
      }
    },
    {
      title: 'Waitlist Date',
      dataIndex: 'waitListDate'
    },
    {
      title: 'Care Plan Status',
      dataIndex: 'cpStatus',
      render: (text, record) => {
        let { requested } = record;
        return (
          //   text === 'Pending Scheduling' ? (
          //   <div className="waitlist-select-container">
          //     <WaitListDropDown
          //       value={text}
          //       options={cpStatusPendingOptions}
          //       onChange={value => {
          //         handleWaitListUpdate(value, record, 'cpStatus', requested);
          //       }}
          //     ></WaitListDropDown>
          //   </div>
          // ) : (
          <div>{text}</div>
          // )
        );
      }
    },
    {
      title: 'Waitlist Reason',
      dataIndex: 'waitListReason',
      render: (text, record) => {
        let { isLoaderView, requested } = record;
        return (
          //   !isLoaderView ? (
          //   <div className="waitlist-select-container">
          //     <WaitListDropDown
          //       value={text}
          //       options={waitListReasons?.map(reason => ({
          //         value: reason.title,
          //         label: reason.title
          //       }))}
          //       onChange={value => {
          //         handleWaitListUpdate(
          //           value,
          //           record,
          //           'waitlistReason',
          //           requested
          //         );
          //       }}
          //     ></WaitListDropDown>
          //   </div>
          // ) : (
          <div>{text}</div>
          // )
        );
      }
    },
    {
      title: 'Smart Scheduling',
      dataIndex: 'rss',
      render: (text, record) => {
        return (
          <div className={`Status_${text}`}>
            <span className={text !== 'Unavailable' ? `Status_Inner_Cont` : ''}>
              {' '}
              {typeof text === 'string' ? text.split('-').join(' ') : text}
            </span>
          </div>
        );
      }
    },
    {
      title: 'Receiving',
      dataIndex: 'receiving',
      render: (text, record) => {
        return <div style={{ paddingLeft: '10px' }}>{text}</div>;
      }
    },
    {
      title: 'Recommended',
      dataIndex: 'recommended',
      render: (text, record) => {
        return <div style={{ paddingLeft: '10px' }}>{text}</div>;
      }
    },
    {
      title: 'Requested',
      dataIndex: 'waitListRequested',
      render: (text, record) => {
        let { isLoaderView, waitListReason, ifDTAppt } = record;

        return !isLoaderView ? (
          <>
            <div style={{ display: 'flex' }}>
              {customWaitlistRequestedIp({
                defaultValue: text,
                handleWaitListUpdate: handleWaitListUpdate,
                restProps: {
                  waitListReason: waitListReason,
                  record: record,
                  updateType: 'waitlistReason'
                }
              })}

              <div style={{ paddingLeft: '5px', paddingTop: '8px' }}>
                {ifDTAppt ? EventSessionType.DT : EventSessionType.ABA}/week
              </div>
            </div>
          </>
        ) : (
          <div>{text}</div>
        );
      }
    },
    {
      title: 'Service',
      dataIndex: 'service'
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Avail. Updated',
      dataIndex: 'availUpdated',
      render: (text, record) => {
        let { isLoaderView } = record;
        return !isLoaderView ? (
          <WaitListPopOver>
            <PopOverComp
              displayContent={text}
              clientInfo={record}
              popOverType={'clientDayAvailabilityContent'}
              placement="rightBottom"
            ></PopOverComp>
          </WaitListPopOver>
        ) : (
          <div>{text}</div>
        );
      }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      render: (text, record) => {
        const threshold = 99;
        const displayCount =
          record.notesCount === 0
            ? '+'
            : record.notesCount > threshold
            ? `${threshold}+`
            : record.notesCount;
        let { isLoaderView } = record;
        return !isLoaderView ? (
          <Badge
            offset={[-1, 0]}
            style={{ background: '#6F42F5' }}
            size="small"
            count={displayCount}
          >
            <img
              alt="notesicon"
              src={NotesIcon}
              onClick={() => {
                record.notes = record.notes.sort(
                  (a, b) => new Date(b.actionAt) - new Date(a.actionAt)
                );
                setShowSidebar(true);
                setSidebarData(record);
                setActiveTab('notes');
              }}
              style={{
                paddingLeft: 3,
                paddingRight: 3,
                width: '34px',
                height: '34px',
                filter: 'brightness(110%)'
              }}
            />
          </Badge>
        ) : (
          <div>{text}</div>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      render: (text, record) => {
        let { isLoaderView } = record;
        return !isLoaderView ? (
          <WaitListPopOver>
            <PopOverComp
              displayContent={text}
              trigger={'click'}
              clientInfo={record}
              popOverType={'ActionPopOver'}
              placement="rightBottom"
              setShowSidebar={setShowSidebar}
              sidebarData={record}
              setSidebarData={setSidebarData}
              onNoteAdded={handleNoteAdded}
              waitListReasons={waitListReasons}
              setActiveTab={setActiveTab}
              showSidebar={showSidebar}
            ></PopOverComp>
          </WaitListPopOver>
        ) : (
          <div>{text}</div>
        );
      }
    }
  ];
};

export const customWaitlistRequestedIp = data => {
  let {
    defaultValue,
    handleWaitListUpdate,
    restProps,
    isEditFlow,
    componentRestProps
  } = data;
  return (
    <DecimalInput
      min={0}
      style={{ width: '120px' }}
      value={defaultValue ? String(parseFloat(defaultValue)) : defaultValue}
      {...componentRestProps}
      type="number"
      style={{ borderRadius: 9 }}
      onChange={e => {
        let value = e.target.value;
        if (isEditFlow) handleWaitListUpdate({ value });
        else {
          if (value !== '') {
            value = Number(e.target.value);
          } else value = null;
          let { waitListReason, record, updateType } = restProps;
          handleWaitListUpdate(waitListReason, record, updateType, value);
        }
      }}
    />
  );
};

export const WAITLIST_POPOVER_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'date',
    width: '21%'
  },
  {
    title: 'Author',
    dataIndex: 'author',
    width: '26.4%'
  },
  {
    title: 'Note',
    dataIndex: 'note',
    width: '52.6%'
  }
];
