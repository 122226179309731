export const availabilityColorsMap = () => {
  const availabilityColorsMap = new Map();
  availabilityColorsMap.set('clinicandtelehealth', 'rgba(98, 214, 200, 0.23)');
  availabilityColorsMap.set('telehealth', 'rgba(214, 153, 98, 0.2)');
  availabilityColorsMap.set('clinic', 'rgba(155, 248, 84, 0.2)');
  availabilityColorsMap.set('offsite', 'rgba(243, 197, 222, 0.2)');
  availabilityColorsMap.set('anyavailability', 'rgba(165, 138, 246, 0.2)');
  availabilityColorsMap.set('lunch', 'rgba(214, 194, 98, 0.2)');
  availabilityColorsMap.set('notes', 'rgba(98, 141, 214, 0.23)');
  availabilityColorsMap.set('meeting', 'rgba(208, 98, 214, 0.2)');
  availabilityColorsMap.set('dt eval slot', 'rgba(164, 219, 179, 0.4)');
  availabilityColorsMap.set(
    'aba assessment: parent interview',
    'rgba(255, 190, 56, 0.6)'
  );
  availabilityColorsMap.set('mp neuro eval', 'rgba(42, 91, 93, 0.5)');
  availabilityColorsMap.set('welcome visit', 'rgba(18, 47, 78, 0.2)');
  availabilityColorsMap.set('care plan meeting', 'rgba(247, 235, 224, 0.7)');
  availabilityColorsMap.set('adir', 'rgba(76, 0, 51, 0.5)');
  availabilityColorsMap.set('adir follow up', 'rgba(150, 77, 79, 0.5)');
  availabilityColorsMap.set('ados', 'rgb(10, 0, 30, 0.5)');
  availabilityColorsMap.set('cim', 'rgba(22, 24, 23, 0.3)');
  availabilityColorsMap.set('admin work', 'rgb(185, 203, 221, 0.4)');
  availabilityColorsMap.set('md care sessions', 'rgba(137, 241, 232, 0.4)');
  availabilityColorsMap.set('working', 'rgba(137, 241, 232, 0.4)');
  availabilityColorsMap.set('parent training', 'rgba(190, 109, 183, 0.5)');
  availabilityColorsMap.set('eeg read', 'rgb(22, 245, 137, 0.2)');
  availabilityColorsMap.set(
    'aba assessment: observation',
    'rgb(250, 212, 172, 0.6)'
  );
  availabilityColorsMap.set('match 1', 'rgba(252, 186, 3, 0.2)');
  availabilityColorsMap.set('match 2', 'rgba(111, 252, 3, 0.2)');
  return availabilityColorsMap;
};
