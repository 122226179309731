import { Clinic } from 'model/clinic';
import { ILookup } from 'model/lookup';
import {
  IClientProfile,
  IClinic,
  IFullAppointmentType,
  IProviderSpecialty,
  ISmartForm
} from 'model/v2';
import { CorticaProgram } from 'model/v2/corticaPrograms';
import { Moment } from 'moment';
import {
  DefaultTagFilterOption,
  internalFilters
} from 'utils/constants/calendarFilters';
import { IRestrictedProviders } from 'model/client';

export interface ICalendarFilterOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface ICalendarFilter {
  clinics: ICalendarFilterOption[];
  locations?: ICalendarFilterOption[];
  programs?: ICalendarFilterOption[];
  transportation?: ICalendarFilterOption[];
  corticaProgram?: ICalendarFilterOption[];
  departments?: ICalendarFilterOption[];
  specialities?: ICalendarFilterOption[];
  smartSchedulingStatus?: ICalendarFilterOption[];
  savedFilterName?: string;
  internals?: ICalendarFilterOption[] | internalFilters[];
  status?: ICalendarFilterOption[];
  waitListReason?: ICalendarFilterOption[];
  therapyType?: ICalendarFilterOption[];
  serviceType?: ICalendarFilterOption[];
  clientAvailability?: ICalendarFilterOption[];
  dayOfWeeks?: ICalendarFilterOption[];
  additionalFilter?: any;
  days?: ICalendarFilterOption[];
  startTime?: any;
  endTime?: any;
  language?: ICalendarFilterOption[];
}

export enum ClientFilterType {
  clinic = 'clinic',
  restriction = 'restriction',
  language = 'language'
}

export enum ProviderFilterType {
  corticaProgram = 'corticaProgram',
  status = 'status',
  specialities = 'specialities'
}

export enum FilterPlaceHolder {
  language = 'Languages'
}

export interface IProviderFilterOption {
  clinicOptionsList: IClinicFilterData[];
  corticaProgram: ICorticaPrograms[];
  specialtiesDepartment: string[];
  eventFilterStatus: IProvideStatus[];
  specialities: ICalendarFilterOption[];
  status: IProvideStatus[];
}
interface IProvideStatus {
  id: number | string;
  value?: string;
  title?: string;
}

export interface ICorticaPrograms {
  id?: number;
  programName?: string;
}

export interface IRecurringSSFilter {
  dayOfWeeks: ICalendarFilterOption[];
  locations: ICalendarFilterOption[];
  providers: Set<number>;
  clinics: ICalendarFilterOption[];
  specialities: ICalendarFilterOption[];
  transportation?: ICalendarFilterOption[];
  isFiltersApplied?: boolean;
}

export interface IFilter {
  clinics: string[];
  locations: string[];
  programs: string[];
  departments: string[];
  specialities: string[];
  savedFilterName?: string;
  internals: internalFilters[];
}

// Filter lists fetched from BE
export interface ICalendarFilterData {
  clinicOptionsList: IClinicFilterData[];
  specialtiesDepartment?: string[];
  specialties?: ISpecialityFilterData[];
  eventFilterStatus?: ILookup[];
  corticaProgram?: ICorticaPrograms[];
  status?: IProvideStatus[];
  languages?: IClinicFilterData[];
  restrictedProviders?: IRestrictedProviders[];
}

export interface waitListFilterList {
  WaitListFilter: {
    clinicOptionsList: IClinicFilterData[];
    waitListAvailabilityTime: {
      title: string;
    };
    waitListReasons: {
      title: string;
    };
    waitListStatus: {
      title: string;
    };
    waitListTheraphyTypies: {
      title: string;
    };
    waitListServices: {
      title: string;
    };
    smartSchedulingStatus: {
      title: string;
    };
  };
}

export interface ICalendarFilterDataV2 {
  calenderFilters: ICalendarFilterData;
}
export interface ISpecialityFilterData {
  id: string;
  abbreviation: string;
  title?: string;
  department: string;
}

export interface IClinicFilterData {
  id: string;
  clinicName?: string;
  clinicAbbreviation: string;
}
export interface IStaffResource {
  id: string;
  displayName: string;
  clinicId?: string;
}

//TODO: TYPE OF CLIENTS
export interface IClientResource {
  id: string;
  displayName: string;
  clinicId?: string;
}

export interface ICalendarResource {
  //id is string can be providerid:day
  id: number;
  displayName: string;
  attendeeType: string;
  email?: string;
  speciality?: IProviderSpecialty;
  baseId?: string;
  clinic?: IClinic;
  appointmentTypes?: IFullAppointmentType[]; //determine this IFulleventType
  name?: string;
  status?: string;
  profile?: IClientProfile;
  telehealthLinks?: string[];
  corticaPrograms?: CorticaProgram[];
}

export interface ISchedularFilterData {
  getAllFiltersWithUserId: ISchedularFilter[];
}

export interface ISchedularFilter {
  id: number;
  name: string;
  userId: string;
  isDefault: boolean;
  filterSelections: IFilterSelection[];
}

export interface IFilterSelection {
  id: number;
  selectedId?: number;
  type: IFilterType;
  clinics?: Clinic;
  specialities?: any;
  language?: any;
}

export interface IFilterType {
  typeName: string;
}

// End of V2

//
export interface ISavedFilterOptions extends ICalendarFilter {
  name?: string;
  id?: number;
  isDefault?: boolean;
}

export interface ISavedFilter extends IFilter {
  name?: string;
}

export interface ITagFilterForm {
  name: string;
  isDefault: DefaultTagFilterOption;
}

export interface EventSelectOptions {
  key: number;
  value: string;
  text: string;
}

export interface IRecurringReqData {
  startDate: Moment | Date | undefined;
  weeklySessions: number;
  data: ISmartForm | undefined;
}

export interface IFilterSelectionType {
  [key: string]: number;
}
