import { useReactiveVar } from '@apollo/client';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  GET_STAFF_CANCELED_EVENTS,
  GET_STAFF_EVENTS,
  GET_CLIENT_CANCELED_EVENTS,
  GET_CLIENT_EVENTS_AND_AVAILABILITY,
  GET_CLIENT_DELETED_EVENTS,
  GET_STAFF_DELETED_EVENTS
} from 'api/graphql/v2/queries/Events';
import { GET_FILTERED_PROVIDERS } from 'api/graphql/v2/queries/Filters';
import {
  getClientsGroups,
  getCurrentPaginationArray,
  getGroupsToFetch,
  getProvidersGroups,
  isPhantomEvent,
  mapKeysToArray
} from 'helpers/calendarHelper';
import { applyFilters } from 'helpers/filterHekper';
import { EVENTS_FETCHING_ACTIONS } from 'pages/MainCalendarPage';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { View } from 'react-big-calendar';
import { ICalendarLoading } from 'model/calendar';
import { ICalendarEvent, IPhantomEvent, Status } from 'model/calendar/events';
import { IFilteredProvidersData } from 'model/calendar/eventsFetcher';
import { ICalendarResource } from 'model/calendar/filters';
import { calendarGroup } from 'model/calendar/groups';
import { customizeRange } from 'utils/builders/calendar';
import {
  calendarSidebarVar,
  mainCalendarPrefDayVar,
  plannerCalendarPrefViewVar,
  views
} from 'utils/cache/calendar';
import {
  calendarEventsPrefVar,
  calendarFiltersPrefVar,
  clientsResourcesPrevVar,
  filteredProvidersResourcesPrevVar,
  providerResourcesPrevVar,
  searchedClientsPrevVar,
  searchedProvidersPrefVar
} from 'utils/cache/filters';
import {
  CALENDAR_MAX_PAGE_SIZE,
  internalFilters,
  MIN_PER_PAGE
} from 'utils/constants/calendarFilters';
import {
  getClientCanceledEventsVariables,
  getClientEventsVariables,
  getStaffCanceledEventsVariables,
  getStaffEventsVariables
} from 'utils/mappers/request/events';
import { getUnavailableEvents } from 'utils/mappers/response/events';
import {
  mapABAAssesmentObservationSlotsToCalendar,
  mapABAAssesmentParentInterviewSlotsToCalendar,
  mapADIRFollowUpSlotsToCalendar,
  mapADIRSlotsToCalendar,
  mapCarePlanMeetingSlotsToCalendar,
  mapCIMSlotsToCalendar,
  mapEEGReadSlotsToCalendar,
  mapEvalSlotsToCalendar,
  mapAdminWorkSlotsToCalendar,
  mapMDCareSessionSlotsToCalendar,
  mapMPNeuroEvalSlotsToCalendar,
  mapParentTrainingSlotsToCalendar,
  mapToCalendarEventsV2,
  mapWelcomeVisitSlotsToCalendar,
  mapADORSlotsToCalendar,
  mapSlotsToCalendar
} from 'utils/mappers/response/eventsV2';
import { sortAttendees } from 'utils/sort';
import { IClient } from 'model/v2';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// _SIMPLE_DAY_MAP_REVERSE and _mapWorkingHoursOutput were copied from backend
// as part of migrating away from workingBlocks and performing the translation
// from dayAvailability to workingBlocks within the frontend. The data transforms
// that they do are ONLY used to generate "unavailable" events for the planner

// because these functions were originally written with backend Types, and the
// original eventsFetcherHook code had many 'any' types involved in storing the
// output of the workingHours calculations, I have typecast to `any` liberally
const _SIMPLE_DAY_MAP_REVERSE = {
  1: 'mon',
  2: 'tues',
  3: 'wed',
  4: 'thurs',
  5: 'fri',
  6: 'sat',
  0: 'sun'
};
const _mapWorkingHoursOutput = (workingHours: any[]) => {
  const output = {
    workingHours: {
      sun: [] as string[],
      mon: [] as string[],
      tues: [] as string[],
      wed: [] as string[],
      thurs: [] as string[],
      fri: [] as string[],
      sat: [] as string[]
    }
  };

  workingHours.forEach(item => {
    const key: number = item.dayOfWeek!;
    // @ts-ignore
    output.workingHours![_SIMPLE_DAY_MAP_REVERSE[key]]!.push({
      id: item.id,
      startTime: item.startTime,
      endTime: item.endTime
    });
  });

  return output;
};
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

/**
 * @param selectedResources  added resource through search
 * @param setIsLoading set the loading state
 * @param action The action that tiggered fetchong of events
 * @param clientsToFetch clients in current page
 * @param providersToFetch providers in current page
 * @param page number on calendar
 * @param setIsCalendarLoading set loading state for UI
 * @param isCalendarLoading loading state for UI
 */

export const useEventsFetcherHook = (
  selectedResources: ICalendarResource[] | undefined = undefined,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setIsCalendarLoading: React.Dispatch<React.SetStateAction<ICalendarLoading>>,
  isCalendarLoading: ICalendarLoading,
  action: string | undefined = undefined,
  setPaginationArray: React.Dispatch<React.SetStateAction<ICalendarResource[]>>,
  paginationArray: ICalendarResource[] = [],
  clientsToFetch: ICalendarResource[] = [],
  providersToFetch: ICalendarResource[] = [],
  page: number = 0,
  incrementPage: undefined | (() => void) = undefined,
  removedGroups: number[] = [],
  setPage: React.Dispatch<React.SetStateAction<number>> | undefined = undefined
) => {
  const filters = useReactiveVar(calendarFiltersPrefVar);
  const events = useReactiveVar(calendarEventsPrefVar);
  const providers = useReactiveVar(providerResourcesPrevVar);
  const clients = useReactiveVar(clientsResourcesPrevVar);
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);
  const calendarView = useReactiveVar(plannerCalendarPrefViewVar);
  const searchedProviders = useReactiveVar(searchedProvidersPrefVar);
  const searchedClients = useReactiveVar(searchedClientsPrevVar);
  const sidebarState = useReactiveVar(calendarSidebarVar);

  const view = useMemo(() => {
    if (calendarView === views.PLANNER_DAY) return views.DAY;
    else if (calendarView === views.PLANNER_WEEK) return views.WEEK;
    else return calendarView;
  }, [calendarView]);

  const [filteredProviders, setFilteredProviders] = useState<
    ICalendarResource[] | undefined
  >(undefined);

  const [canceledFilterPrev, setCanceledFilterPrev] = useState<boolean>(false);
  const [deletedFilterPrev, setDeletedFilterPrev] = useState<boolean>(false);

  //Fetch Clients Events
  const [
    getClientEvents,
    { data: selectedClientEvents, loading: loadingClientEvents }
  ] = useLazyQuery(GET_CLIENT_EVENTS_AND_AVAILABILITY, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      //1- map events to calendar
      const { finalEvents } = mapToCalendarEventsV2(
        selectedClientEvents.clientsPlannerEvents || [],
        calendarGroup.client,
        calendarView,
        calendarDate
      );
      const selectedClient = selectedResources ? selectedResources : [];

      //2- get unavailable events
      let unAvailableEvents: ICalendarEvent[] = [];

      selectedClientEvents?.clientsDayAvailabilities?.forEach(
        (client: IClient) => {
          const clientUnavailableEvents = getUnavailableEvents(
            client.dayAvailabilities,
            customizeRange(calendarDate, view as View),
            client.id,
            calendarGroup.client,
            calendarView,
            calendarDate,
            true
          );
          //TODO: Hanlde unavailable events interface
          unAvailableEvents = unAvailableEvents.concat(
            clientUnavailableEvents as any
          );
        }
      );

      //3-set clients groups
      clientsResourcesPrevVar(
        getClientsGroups(action, clientsToFetch, clients, selectedClient)
      );

      // 4-set clients events
      calendarEventsPrefVar(
        events.concat(finalEvents).concat(unAvailableEvents)
      );

      //5-set loading UI state
      setIsCalendarLoading({
        ...isCalendarLoading,
        isLoadingClientsEvents: false
      });
    },
    onError: () =>
      setIsCalendarLoading({
        ...isCalendarLoading,
        isLoadingClientsEvents: false,
        errorLoadingEvents: true
      })
  });

  //Fetch Staff Events
  //TODO : Check added by search have appointmentTypes object
  const [
    getStaffEvents,
    { data: providersData, loading: loadingStaffEvents }
  ] = useLazyQuery(GET_STAFF_EVENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      console.log(providersData, 'providersData here');
      const isPendingFilter = (filters.internals as internalFilters[])?.includes(
        internalFilters.pending
      );
      const selectedProvider = selectedResources ? selectedResources : [];
      //Remove searched from pagination array to handle duplcate fetch after converting filtered to search
      if (action === EVENTS_FETCHING_ACTIONS.APPLY_SEARCH) {
        if (setPaginationArray) {
          setPaginationArray(
            paginationArray?.filter(
              x => !selectedProvider?.some(provider => x.id === provider.id)
            ) || []
          );
        }
      }

      let providersToFilter =
        action === EVENTS_FETCHING_ACTIONS.APPLY_FILTERS
          ? filteredProviders || []
          : providersToFetch || [];
      //const providerActiveData: any = providersData?.providersPlannerEvents.provider.status !== 'Inactive';
      const { finalEvents, providersLocStatus } = mapToCalendarEventsV2(
        providersData?.providersPlannerEvents || [],
        calendarGroup.provider,
        calendarView,
        calendarDate,
        providersToFilter,
        isPendingFilter
      );

      //2- Apply FE filters ( pending and location)
      const map: Record<string, any> | null = providersLocStatus;

      const locPendingFilteredProviders = applyFilters(
        providersToFilter,
        filters?.locations!,
        isPendingFilter,
        map,
        searchedProviders
      );

      //3 - Fetch following pages
      const hasMoreToFetch =
        (page + 1) * CALENDAR_MAX_PAGE_SIZE(calendarView) <
        paginationArray.length;

      if (
        locPendingFilteredProviders?.length < MIN_PER_PAGE(view) &&
        hasMoreToFetch
      ) {
        if (incrementPage) incrementPage();
      }

      //5- set provider groups
      const providersList = getProvidersGroups(
        selectedProvider,
        providers,
        locPendingFilteredProviders,
        searchedProviders,
        action
      );
      providerResourcesPrevVar(providersList);

      //6- Get unavailable events
      let unAvailableEvents: any[] = [];
      const providerTemplatesWithAvailabilities =
        providersData.providerWithOpenAvailability
          .providertemplatewithavailability;

      const mappedWorkingHours: {
        providerId: string;
        workingHours?: Record<string, unknown>;
      }[] = [];

      providerTemplatesWithAvailabilities.forEach((provider: any) => {
        const providerMappedWorkingHours = _mapWorkingHoursOutput(
          provider?.availability?.dayAvailabilities || []
        );
        mappedWorkingHours.push({
          ...providerMappedWorkingHours,
          providerId: provider.id
        });
      });
      mappedWorkingHours.forEach((providerWorkingHours: any) => {
        const provider = providerTemplatesWithAvailabilities.find(
          (provider: any) =>
            Number(provider.id) === Number(providerWorkingHours.providerId)
        );
        if (provider) {
          const providerClinic = provider.clinic;
          const providerTz = providerClinic.timezone;
          const providerUnavailableEvents = getUnavailableEvents(
            providerWorkingHours.workingHours,
            customizeRange(calendarDate, view as View),
            providerWorkingHours.providerId,
            calendarGroup.provider,
            calendarView,
            calendarDate,
            false,
            providerTz
          );
          //TODO: Handle interface
          unAvailableEvents = unAvailableEvents.concat(
            providerUnavailableEvents as any
          );
        }
      });
      let calendarEvents = finalEvents.concat(unAvailableEvents as any);

      // const selectedProvider = selectedResources ? selectedResources : [];
      let availableSlotEvents: any[] = [];
      let dayAvailabilitesExist: { dayAvailabilities: any } | null;
      let FinalCalendarEvents: any[] = [];
      providersData.providerWithOpenAvailability.providertemplatewithavailability.map(
        (eachProviderAvailability: {
          availability: { dayAvailabilities: any };
          id: any;
        }) => {
          dayAvailabilitesExist = eachProviderAvailability.availability;
          if (dayAvailabilitesExist !== null) {
            let eachProviderEvalSlots = [];
            eachProviderAvailability.availability.dayAvailabilities?.map(
              (eachSlot: { providerId: any }) => {
                eachSlot.providerId = eachProviderAvailability.id;
              }
            );
            eachProviderEvalSlots =
              eachProviderAvailability.availability.dayAvailabilities;

            let eachProviderAvailableSlotEvents: any[] = [];

            eachProviderAvailableSlotEvents.push(eachProviderEvalSlots);

            const filteredEvalSlots = eachProviderAvailableSlotEvents.map(
              evalSlot => {
                return evalSlot.filter(
                  (slot: { type: string }) =>
                    slot.type === ('DT Eval Slot' || 'evalslot')
                );
              }
            );
            const filteredWelcomeVisitSlots = eachProviderAvailableSlotEvents.map(
              welcomeVisit => {
                return welcomeVisit.filter(
                  (slot: { type: string }) => slot.type === 'Welcome Visit'
                );
              }
            );
            const filtererAIDRFollowUpSlots = eachProviderAvailableSlotEvents.map(
              aidrFollowUpSlot => {
                return aidrFollowUpSlot.filter(
                  (slot: { type: string }) => slot.type === 'ADIR Follow Up'
                );
              }
            );
            const filtererCIMSlots = eachProviderAvailableSlotEvents.map(
              aidrFollowUpSlot => {
                return aidrFollowUpSlot.filter(
                  (slot: { type: string }) => slot.type === 'CIM'
                );
              }
            );
            const filteredABAAssesmentSlots = eachProviderAvailableSlotEvents.map(
              abaassesment => {
                return abaassesment.filter(
                  (slot: { type: string }) =>
                    slot.type === 'ABA Assessment: Parent Interview'
                );
              }
            );
            const filteredMPNeuroEvalSlots = eachProviderAvailableSlotEvents.map(
              mpNeuroEval => {
                return mpNeuroEval.filter(
                  (slot: { type: string }) => slot.type === 'MP Neuro Eval'
                );
              }
            );
            const filteredCarePlanMeetingSlots = eachProviderAvailableSlotEvents.map(
              carePlanMeeting => {
                return carePlanMeeting.filter(
                  (slot: { type: string }) => slot.type === 'Care Plan Meeting'
                );
              }
            );
            const filteredADIRSlots = eachProviderAvailableSlotEvents.map(
              adir => {
                return adir.filter(
                  (slot: { type: string }) => slot.type === 'ADIR'
                );
              }
            );
            const filteredADORSlots = eachProviderAvailableSlotEvents.map(
              adir => {
                return adir.filter(
                  (slot: { type: string }) => slot.type === 'ADOS'
                );
              }
            );
            const filteredMDCareSessions = eachProviderAvailableSlotEvents.map(
              mdCareSession => {
                return mdCareSession.filter(
                  (slot: { type: string }) => slot.type === 'MD Care Sessions'
                );
              }
            );
            const filteredAdminWork = eachProviderAvailableSlotEvents.map(
              mdAdmin => {
                return mdAdmin.filter(
                  (slot: { type: string }) => slot.type === 'Admin Work'
                );
              }
            );
            const filteredParentTrainingSlots = eachProviderAvailableSlotEvents.map(
              welcomeVisit => {
                return welcomeVisit.filter(
                  (slot: { type: string }) => slot.type === 'Parent Training'
                );
              }
            );
            const filteredEEGReadSlots = eachProviderAvailableSlotEvents.map(
              welcomeVisit => {
                return welcomeVisit.filter(
                  (slot: { type: string }) => slot.type === 'EEG Read'
                );
              }
            );
            const filteredABAAssesmentObservationSlots = eachProviderAvailableSlotEvents.map(
              welcomeVisit => {
                return welcomeVisit.filter(
                  (slot: { type: string }) =>
                    slot.type === 'ABA Assessment: Observation'
                );
              }
            );
            const filteredMatch1Slots = eachProviderAvailableSlotEvents.map(
              match1Sessions => {
                return match1Sessions.filter(
                  (slot: { type: string }) => slot.type === 'Match 1'
                );
              }
            );
            const filteredMatch2Slots = eachProviderAvailableSlotEvents.map(
              match1Sessions => {
                return match1Sessions.filter(
                  (slot: { type: string }) => slot.type === 'Match 2'
                );
              }
            );
            //FEATURE_FLAG FOR EVAL SLOTS ON PLANNER
            if (getFeatureAvailability(FEATURES.EVALSLOTS_TO_PLANNER)) {
              const evalSlots = mapEvalSlotsToCalendar(
                filteredEvalSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(evalSlots);
              const welcomeVisitSlots = mapWelcomeVisitSlotsToCalendar(
                filteredWelcomeVisitSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(
                welcomeVisitSlots
              );
              const aidrFollowUpSlots = mapADIRFollowUpSlotsToCalendar(
                filtererAIDRFollowUpSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(
                aidrFollowUpSlots
              );
              const CIMSlots = mapCIMSlotsToCalendar(
                filtererCIMSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(CIMSlots);
              const ABAAssesmentSlots = mapABAAssesmentParentInterviewSlotsToCalendar(
                filteredABAAssesmentSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(
                ABAAssesmentSlots
              );
              const MPNeuroEvalsSlots = mapMPNeuroEvalSlotsToCalendar(
                filteredMPNeuroEvalSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(
                MPNeuroEvalsSlots
              );
              const carePlanMeetingSlots = mapCarePlanMeetingSlotsToCalendar(
                filteredCarePlanMeetingSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(
                carePlanMeetingSlots
              );
              const adirSlots = mapADIRSlotsToCalendar(
                filteredADIRSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(adirSlots);
              const adorSlots = mapADORSlotsToCalendar(
                filteredADORSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(adorSlots);
              const mdcareSessionsSlots = mapMDCareSessionSlotsToCalendar(
                filteredMDCareSessions,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(
                mdcareSessionsSlots
              );
              const parentTrainingSlots = mapParentTrainingSlotsToCalendar(
                filteredParentTrainingSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(
                parentTrainingSlots
              );
              const adminWorkSlots = mapAdminWorkSlotsToCalendar(
                filteredAdminWork,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(adminWorkSlots);
              const eegReadSlots = mapEEGReadSlotsToCalendar(
                filteredEEGReadSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(eegReadSlots);
              const aBAAssesmentObservationSlots = mapABAAssesmentObservationSlotsToCalendar(
                filteredABAAssesmentObservationSlots,
                calendarGroup.provider,
                calendarView,
                calendarDate,
                providersData
              );
              availableSlotEvents = availableSlotEvents.concat(
                aBAAssesmentObservationSlots
              );
            }
            const match1Slots = mapSlotsToCalendar(
              filteredMatch1Slots,
              calendarGroup.provider,
              calendarView,
              calendarDate,
              providersData,
              'Match 1',
              'rgba(252, 186, 3, 0.2)'
            );
            availableSlotEvents = availableSlotEvents.concat(match1Slots);
            const match2Slots = mapSlotsToCalendar(
              filteredMatch2Slots,
              calendarGroup.provider,
              calendarView,
              calendarDate,
              providersData,
              'Match 2',
              'rgba(111, 252, 3, 0.2)'
            );
            availableSlotEvents = availableSlotEvents.concat(match2Slots);
            FinalCalendarEvents = calendarEvents.concat(availableSlotEvents);
            const filteredIds = locPendingFilteredProviders.map(s => s.id);
            calendarEventsPrefVar(
              FinalCalendarEvents.filter(
                e =>
                  filteredIds.includes(parseInt(e.baseId!)) ||
                  searchedProviders.has(parseInt(e.baseId!))
              ).concat(events)
            );
            setFilteredProviders([]);
          } else {
            const filteredIds = locPendingFilteredProviders.map(s => s.id);
            if (FinalCalendarEvents.length > 0) {
              calendarEventsPrefVar(
                FinalCalendarEvents.filter(
                  e =>
                    filteredIds.includes(parseInt(e.baseId!)) ||
                    searchedProviders.has(parseInt(e.baseId!))
                ).concat(events)
              );
              setFilteredProviders([]);
            } else {
              calendarEventsPrefVar(
                calendarEvents
                  .filter(
                    e =>
                      filteredIds.includes(parseInt(e.baseId!)) ||
                      searchedProviders.has(parseInt(e.baseId!))
                  )
                  .concat(events)
              );
              setFilteredProviders([]);
            }
          }
        }
      );
      //9 - set calendar UI loading state
      setIsCalendarLoading({
        ...isCalendarLoading,
        isLoadingFilteredProviders: false,
        isLoadingStaffEvents: false
      });
    },
    onError: () => {
      setIsCalendarLoading({
        ...isCalendarLoading,
        isLoadingFilteredProviders: false,
        isLoadingStaffEvents: false,
        errorLoadingEvents: true
      });
    }
  });

  // fetch canceled events only for providers
  const [
    getStaffCanceledEvents,
    { data: providersDataCanceledEvents }
  ] = useLazyQuery(GET_STAFF_CANCELED_EVENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: async () => {
      const isPendingFilter = (filters.internals as internalFilters[])?.includes(
        internalFilters.pending
      );
      const { finalEvents } = mapToCalendarEventsV2(
        providersDataCanceledEvents?.providersCanceledEvents || [],
        calendarGroup.provider,
        calendarView,
        calendarDate,
        [],
        isPendingFilter
      );
      //set calendar events
      let calendarEvents = events.concat(finalEvents);
      calendarEventsPrefVar(calendarEvents);

      if (filteredProviders?.length === 0) {
        setIsCalendarLoading({
          ...isCalendarLoading,
          isLoadingStaffEvents: false,
          isLoadingFilteredProviders: false
        });
      }
    }
  });

  //Fetch Clients Canceled Events
  const [
    getClientCanceledEvents,
    { data: clientCanceledEvents }
  ] = useLazyQuery(GET_CLIENT_CANCELED_EVENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      const { finalEvents } = mapToCalendarEventsV2(
        clientCanceledEvents?.clientsCanceledEvents || [],
        calendarGroup.client,
        calendarView,
        calendarDate
      );
      let calendarEvents = events.concat(finalEvents);
      calendarEventsPrefVar(calendarEvents);

      if (filteredProviders?.length === 0) {
        setIsCalendarLoading({
          ...isCalendarLoading,
          isLoadingStaffEvents: false,
          isLoadingFilteredProviders: false
        });
      }
    }
  });

  const [
    getStaffDeletedEvents,
    { data: providersDataDeletedEvents }
  ] = useLazyQuery(GET_STAFF_DELETED_EVENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: async () => {
      const isPendingFilter = (filters.internals as internalFilters[])?.includes(
        internalFilters.pending
      );

      const { finalEvents } = mapToCalendarEventsV2(
        providersDataDeletedEvents?.providersDeletedEvents || [],
        calendarGroup.provider,
        calendarView,
        calendarDate,
        [],
        isPendingFilter
      );
      //set calendar events
      let calendarEvents = events.concat(finalEvents);
      calendarEventsPrefVar(calendarEvents);

      if (filteredProviders?.length === 0) {
        setIsCalendarLoading({
          ...isCalendarLoading,
          isLoadingStaffEvents: false,
          isLoadingFilteredProviders: false
        });
      }
    }
  });
  const [getClientDeletedEvents, { data: clientDeletedEvents }] = useLazyQuery(
    GET_CLIENT_DELETED_EVENTS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const { finalEvents } = mapToCalendarEventsV2(
          clientDeletedEvents?.clientsDeletedEvents || [],
          calendarGroup.client,
          calendarView,
          calendarDate
        );
        let calendarEvents = events.concat(finalEvents);
        calendarEventsPrefVar(calendarEvents);

        if (filteredProviders?.length === 0) {
          setIsCalendarLoading({
            ...isCalendarLoading,
            isLoadingStaffEvents: false,
            isLoadingFilteredProviders: false
          });
        }
      }
    }
  );

  //Fetch Filtered Providers list
  const [
    getFilteredProviders,
    { data, loading: loadingFilteredProviders }
  ] = useLazyQuery<IFilteredProvidersData>(GET_FILTERED_PROVIDERS, {
    variables: {
      clinics: filters.clinics.map(x => x.value),
      programs: filters.programs?.map(x => x.value),
      departments: filters.departments!.map(x => x.value),
      specialities: filters.specialities!.map(x => x.value)
    },
    fetchPolicy: 'no-cache',

    onCompleted: async () => {
      const isCanceledFilter = (filters.internals as internalFilters[])?.includes(
        internalFilters.canceled
      );
      const isDeletedFilter = (filters.internals as internalFilters[])?.includes(
        internalFilters.deleted
      );
      //1- keep only events associated with searched attendees/phantom events
      const filteredEvents = events.filter(
        e =>
          // searchedProviders.has(e.resourceId!) ||
          searchedProviders.has(parseInt(e.baseId!)) ||
          e.groupType === calendarGroup.client ||
          isPhantomEvent(e as IPhantomEvent)
      );

      calendarEventsPrefVar(filteredEvents);

      //2- Remove any incoming group that is already in searched
      let filteredExculingSearched = data?.filteredProviders
        .filter(p => searchedProviders.get(p.id) === undefined)
        .sort((p1, p2) => sortAttendees(p1, p2));

      //3- set current pagination array starting from filtered results
      const currentPaginationArray = getCurrentPaginationArray(
        clients,
        providers,
        paginationArray,
        removedGroups,
        action,
        filteredExculingSearched || [],
        searchedProviders,
        searchedClients
      );

      //4- reset page to 0
      if (setPage) setPage(0);
      if (setPaginationArray) setPaginationArray(currentPaginationArray || []);

      //5- set filtered providers
      filteredProvidersResourcesPrevVar(filteredExculingSearched);

      //6- get clients/providers to fetch
      const clientsToFetch = getGroupsToFetch(
        currentPaginationArray,
        0,
        calendarView
      ).clients;

      const providersToFetch = getGroupsToFetch(
        currentPaginationArray,
        0,
        calendarView
      ).providers;

      setFilteredProviders(providersToFetch);

      //7- Fetch events
      if (providersToFetch?.length > 0) {
        getStaffEvents(
          getStaffEventsVariables(
            providersToFetch,
            calendarDate,
            calendarView,
            isCanceledFilter,
            isDeletedFilter
          )
        );
        setIsCalendarLoading({
          ...isCalendarLoading,
          isLoadingFilteredProviders: false,
          isLoadingStaffEvents: false
        });
      }

      if (filteredExculingSearched?.length === 0) {
        providerResourcesPrevVar(
          providers?.filter((p: any) => searchedProviders.has(p.id))
        );
      }

      if (clientsToFetch?.length > 0) {
        getClientEvents(
          getClientEventsVariables(
            clientsToFetch,
            calendarDate,
            calendarView,
            isCanceledFilter,
            isDeletedFilter
          )
        );
      }

      //8- Handle cancel filter
      if (isCanceledFilter && !canceledFilterPrev) {
        setCanceledFilterPrev(isCanceledFilter);

        const displayedSearchedProviders = [];
        const providerIds = providers.map(provider => provider.id);
        for (const searchedProvider of searchedProviders) {
          if (providerIds.includes(searchedProvider[1].id)) {
            displayedSearchedProviders.push(searchedProvider[1].id);
          }
        }

        const providerPromises = [];

        while (displayedSearchedProviders.length > 0) {
          const chunk = displayedSearchedProviders.splice(
            0,
            CALENDAR_MAX_PAGE_SIZE(calendarView)
          );
          providerPromises.push(
            getStaffCanceledEvents(
              getStaffCanceledEventsVariables(
                chunk,
                calendarDate,
                calendarView,
                isCanceledFilter,
                isDeletedFilter
              )
            )
          );
        }
        await Promise.all(providerPromises);

        const clientsPromises = [];
        const clientsIds = mapKeysToArray(searchedClients);
        while (clientsIds.length > 0) {
          const chunk = clientsIds.splice(
            0,
            CALENDAR_MAX_PAGE_SIZE(calendarView)
          );
          clientsPromises.push(
            getClientCanceledEvents(
              getClientCanceledEventsVariables(
                chunk,
                calendarDate,
                calendarView
              )
            )
          );
        }
        await Promise.all(clientsPromises);
      }

      //9- Handle delete filter
      if (isDeletedFilter && !deletedFilterPrev) {
        setDeletedFilterPrev(isDeletedFilter);

        const displayedSearchedProviders = [];
        const providerIds = providers.map(provider => provider.id);
        for (const searchedProvider of searchedProviders) {
          if (providerIds.includes(searchedProvider[1].id)) {
            displayedSearchedProviders.push(searchedProvider[1].id);
          }
        }

        const providerPromises = [];

        while (displayedSearchedProviders.length > 0) {
          const chunk = displayedSearchedProviders.splice(
            0,
            CALENDAR_MAX_PAGE_SIZE(calendarView)
          );
          providerPromises.push(
            getStaffDeletedEvents(
              getStaffCanceledEventsVariables(
                chunk,
                calendarDate,
                calendarView,
                isCanceledFilter,
                isDeletedFilter
              )
            )
          );
        }
        await Promise.all(providerPromises);

        const clientsPromises = [];
        const clientsIds = mapKeysToArray(searchedClients);
        while (clientsIds.length > 0) {
          const chunk = clientsIds.splice(
            0,
            CALENDAR_MAX_PAGE_SIZE(calendarView)
          );
          clientsPromises.push(
            getClientDeletedEvents(
              getClientCanceledEventsVariables(
                chunk,
                calendarDate,
                calendarView,
                isCanceledFilter,
                isDeletedFilter
              )
            )
          );
        }
        await Promise.all(clientsPromises);
      }

      // Remove internal filters
      if (!isCanceledFilter || !isDeletedFilter) {
        if (!isCanceledFilter && isDeletedFilter) {
          setCanceledFilterPrev(isCanceledFilter);
          calendarEventsPrefVar(
            filteredEvents.filter(e => e.isDelete !== false)
          );
        } else if (!isDeletedFilter && isCanceledFilter) {
          setDeletedFilterPrev(isDeletedFilter);

          calendarEventsPrefVar(
            filteredEvents.filter(e => e.isDelete !== true)
          );
        } else if (!isDeletedFilter && !isCanceledFilter) {
          setDeletedFilterPrev(isDeletedFilter);
          setCanceledFilterPrev(isCanceledFilter);
          calendarEventsPrefVar(
            filteredEvents.filter(
              e => e.isDelete !== true && e.status !== Status.canceled
            )
          );
        }
        setIsCalendarLoading({
          ...isCalendarLoading,
          isLoadingStaffEvents: providersToFetch?.length > 0 ? true : false,
          isLoadingFilteredProviders: false
        });
      }
    },
    onError: () => {
      console.log(sidebarState, 'sidebarState');
      setIsCalendarLoading({
        ...isCalendarLoading,
        isLoadingFilteredProviders: false,
        errorLoadingEvents: true
      });
    }
  });

  useEffect(() => {
    if (isCalendarLoading) {
      const newCalendarLoadingState =
        loadingFilteredProviders ||
        loadingStaffEvents ||
        loadingClientEvents ||
        isCalendarLoading.isLoadingFilteredProviders ||
        isCalendarLoading.isLoadingStaffEvents ||
        isCalendarLoading.isLoadingClientsEvents ||
        isCalendarLoading.errorLoadingEvents;
      setIsLoading(newCalendarLoadingState);
    }
  }, [
    setIsLoading,
    isCalendarLoading,
    loadingFilteredProviders,
    loadingStaffEvents,
    loadingClientEvents
  ]);

  return {
    getFilteredProviders,
    getStaffEvents,
    getClientEvents
  };
};

export default useEventsFetcherHook;
