import React, { useEffect, useState } from 'react';
// import { Select } from 'lib/ui';
import CheckCircleOutlined from 'assets/img/CheckCircleOutlined.svg';
import InfoOutlined from 'assets/img/InfoOutlined.png';
import { customWaitlistRequestedIp } from 'utils/constants/tables/waitlist';
import { EventSessionType } from 'model/eventType';

interface EditProps {
  sidebarData: any;
  requestedValue: string;
  setRequestedValue: (value: any) => void;
  recommendedValue: string;
  selectedWaitListReason: string;
  setSelectedWaitListReason: (value: string) => void;
  waitListReasons: any;
  handleSaveEdit: () => void;
  handleCancelEdit: () => void;
  isSavingInProgress: boolean;
  editSavedMessage: boolean;
  setIsSavingInProgress: (value: boolean) => void;
}

const Edit: React.FC<EditProps> = ({
  sidebarData,
  requestedValue,
  setRequestedValue,
  recommendedValue,
  // selectedWaitListReason,
  // setSelectedWaitListReason,
  // waitListReasons,
  handleSaveEdit,
  handleCancelEdit,
  isSavingInProgress,
  editSavedMessage,
  setIsSavingInProgress
}) => {
  const [isDirty, setIsDirty] = useState({
    requestedValue: false,
    waitListReason: false
  });
  const [requestedValueError, setRequestedValueError] = useState('');
  // const [waitListReasonError, setWaitListReasonError] = useState('');
  const anyFieldIsDirty = Object.values(isDirty).some(dirty => dirty);
  const [retryCounts, setRetryCounts] = useState({
    requestedValue: 0,
    waitListReason: 0
  });

  useEffect(() => {
    setIsDirty({ requestedValue: false, waitListReason: false });
  }, [editSavedMessage]);

  const handleRequestedValueChange = ({ value }: { value: string }) => {
    if (value === '') {
      setRequestedValue(null);
    } else setRequestedValue(value);
    setIsDirty(prev => ({ ...prev, requestedValue: true }));
    setRequestedValueError('');
    setRetryCounts(prev => ({ ...prev, requestedValue: 0 }));
  };
  const handleSaveButtonClick = async () => {
    try {
      await handleSaveEdit();
      setRequestedValueError('');
      // setWaitListReasonError('');
      setRetryCounts({ requestedValue: 0, waitListReason: 0 });
    } catch (error) {
      if (error instanceof Error) {
        // Update error states based on which fields were last edited
        if (isDirty.requestedValue) {
          // Assuming you track 'dirtiness' for individual fields
          const newCount = retryCounts.requestedValue + 1;
          setRequestedValueError(
            newCount >= 2
              ? 'Unable to save, please cancel and try again.'
              : 'Unable to save, please try again.'
          );
          setRetryCounts(prev => ({ ...prev, requestedValue: newCount }));
        }
        //   if (isDirty.waitListReason) {
        //     const newCount = retryCounts.waitListReason + 1;
        //     setWaitListReasonError(
        //       newCount >= 2
        //         ? 'Unable to save, please cancel and try again.'
        //         : 'Unable to save, please try again.'
        //     );
        //     setRetryCounts(prev => ({ ...prev, waitListReason: newCount }));
        //   }
      } else {
        const genericMessage = 'An unexpected error occurred';
        setRequestedValueError(genericMessage);
        // setWaitListReasonError(genericMessage);
      }
    } finally {
      setIsSavingInProgress(false); // Turn off loading state regardless of success or failure
    }
  };

  // const handleWaitListReasonChange = (value: string) => {
  //   setSelectedWaitListReason(value);
  //   setIsDirty(prev => ({ ...prev, waitListReason: true }));
  //   setWaitListReasonError('');
  //   setRetryCounts(prev => ({ ...prev, waitListReason: 0 }));
  // };
  return (
    <>
      {editSavedMessage && (
        <div className="notes-saved-div">
          <img src={CheckCircleOutlined} alt="" />
          <span className="note-saved-message">Edit successful</span>
        </div>
      )}
      <div className="Edit-carePlan-Info" style={{ display: 'flex' }}>
        <div>
          <div>
            <span className="sidebar-careplan">Care Plan</span>
          </div>
          <div>
            <span className="sidebar-careplan">Status</span>
          </div>
        </div>
        <div>
          <div>
            <span className="sidebar-careplan sidebar-careplan-name">
              {sidebarData?.cpName}
            </span>
          </div>
          <div>
            <span className="sidebar-careplan sidebar-careplan-name">
              {sidebarData?.cpStatus}
            </span>
          </div>
        </div>
      </div>
      <div className="sidebar-careplan-requested">Requested</div>
      <div className="select-container">
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          {customWaitlistRequestedIp({
            isEditFlow: true,
            handleWaitListUpdate: handleRequestedValueChange,
            defaultValue: requestedValue
          })}
          <div style={{ margin: '10px 0px 0px 5px' }}>
            {sidebarData?.ifDTAppt ? EventSessionType.DT : EventSessionType.ABA}
            /week
          </div>
        </div>
        {requestedValueError && (
          <div className="error-msg-edit">
            {' '}
            <img src={InfoOutlined} />
            {requestedValueError}
          </div>
        )}
      </div>
      {!requestedValueError && (
        <div
          className="sidebar-careplan-recommended"
          style={{ fontStyle: 'italic' }}
        >
          Recommended {recommendedValue === 'null' ? '0' : recommendedValue}{' '}
          {sidebarData?.ifDTAppt ? EventSessionType.DT : EventSessionType.ABA}{' '}
          /week
        </div>
      )}
      {/* <div className="sidebar-careplan-requested">Waitlist Reason:</div>
      <div className="select-container">
        <Select
          className="custom-dropdown"
          value={selectedWaitListReason || ''}
          onChange={(value: string) => handleWaitListReasonChange(value)}
          options={waitListReasons?.map((reason: { title: string }) => ({
            value: reason.title,
            label: reason.title
          }))}
          disabled={isSavingInProgress}
          style={{
            width: '200px',
            height: '40px',
            display: 'inline-flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderRadius: '200px',
            background: 'var(--background-body, #FFF)',
            borderColor: waitListReasonError ? '#C41C1C' : 'default', // Change border color conditionally
            color: waitListReasonError ? '#C41C1C' : 'inherit' // Change text color conditionally
          }}
        />
      </div>
      {waitListReasonError && (
        <div className="error-msg-edit">
          {' '}
          <img src={InfoOutlined} />
          <span>{waitListReasonError}</span>
        </div>
      )} */}

      <div className="sidebar-footer">
        <div className="create-note-buttons">
          <button
            className={`cancel-note-button ${isSavingInProgress &&
              'save-note-button-disabled'}`}
            onClick={handleCancelEdit}
            disabled={isSavingInProgress}
          >
            Cancel
          </button>
          <button
            className={`save-note-button ${(isSavingInProgress ||
              !anyFieldIsDirty) &&
              'save-note-button-disabled'}`}
            onClick={handleSaveButtonClick}
            disabled={!anyFieldIsDirty || isSavingInProgress}
          >
            {isSavingInProgress ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </>
  );
};

export default Edit;
