import gql from 'graphql-tag';

export const providerFragments = {
  simpleProvider: gql`
    fragment Provider on Provider {
      id
      firstName
      lastName
      displayName
      name
      mobilePhone
      email
      telehealth
      isActive
      isObservable
      crId
      athenaId
      status
      employment
      speciality {
        id
        color
        abbreviation
        title
        department
      }
      appointmentTypes {
        id
      }
      clinic {
        id
      }
      providerCapacity {
        id
        capacity
        bookedHours
        bookedSessions
        availability
        effectiveStartDate
      }
      allowSelfScheduling
      corticaPrograms {
        id
        programName
      }
    }
  `,
  provider: gql`
    fragment Provider on Provider {
      id
      firstName
      lastName
      displayName
      name
      mobilePhone
      email
      telehealth
      isActive
      petAllergies
      gender
      isObservable
      onAccommodation
      isTestProvider
      crId
      athenaId
      msId
      status
      employment
      transportation
      address {
        addressLine1
        addressLine2
        id
        city
        state
        zipCode
      }
      speciality {
        id
        title
        abbreviation
        color
        department
      }
      appointmentTypes {
        id
        title
        headerColor
      }
      clinic {
        id
        name
        abbreviation
        timezone
        address {
          id
          name
          state
        }
      }
      providerCapacity {
        id
        capacity
        bookedHours
        bookedSessions
        availability
        effectiveStartDate
        rampingPercentage
      }
      telehealthLinks
      allowSelfScheduling
      telehealthLinks
      corticaPrograms {
        id
        programName
      }
      salesForceId
    }
  `,
  staffListProvider: gql`
    fragment StaffListProvider on Provider {
      id
      name
      status
      speciality {
        id
        title
        abbreviation
        department
      }
      clinic {
        id
        name
        abbreviation
      }
    }
  `,
  EventProvider: gql`
    fragment EventProvider on Provider {
      id
      name
      speciality {
        id
        title
      }
      clinic {
        id
        name
        abbreviation
        address {
          id
          name
          state
        }
      }
    }
  `,
  providerRoaster: gql`
    fragment Provider on Provider {
      id
      firstName
      lastName
      displayName
      name
      mobilePhone
      email
      telehealth
      isActive
      petAllergies
      gender
      status
      employment
      address {
        addressLine1
        addressLine2
        id
        city
        state
        zipCode
      }
      speciality {
        id
        title
        abbreviation
        color
        department
      }
      clinic {
        id
        name
        abbreviation
        timezone
        address {
          id
          name
          state
        }
      }
      providerCapacity {
        id
        capacity
        bookedHours
        bookedSessions
        availability
        effectiveStartDate
        rampingPercentage
      }
      corticaPrograms {
        id
        programName
      }
    }
  `
};

export const providerClient = gql`
  fragment ProviderClient on Client {
    id
    client {
      id
      firstName
      lastName
      profile {
        id
        dob
        personalEmail
        phonePreference
        cellPhone
        homePhone
      }
      clinic {
        id
        abbreviation
      }
    }
  }
`;
export const providerListResult = gql`
  fragment ProviderListResult on ProviderListResult {
    providers {
      ...Provider
    }
    count
  }
  ${providerFragments.provider}
`;

export const providerListAbbreviatedResult = gql`
  fragment ProviderListAbbreviatedResult on ProviderListResult {
    providers {
      ...StaffListProvider
    }
    count
  }
  ${providerFragments.staffListProvider}
`;
