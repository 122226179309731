import React from 'react';
import { Grid } from 'semantic-ui-react';
import { CarePlanWaitlistSSWrapper } from '../style';
import Skeleton from '@material-ui/lab/Skeleton';
import { ICarePlanFieldsForSmartScheduling } from 'model/v2';
import moment from 'moment';
import { waitListSSStatus } from 'utils/constants/tables/waitlist';

interface Props {
  isWaitlistDataLoading: boolean;
  carePlanWaitlistData: ICarePlanFieldsForSmartScheduling;
  isAba: boolean;
}
const CarePlanWaitlistSSInfo: React.FC<Props> = ({
  isWaitlistDataLoading,
  carePlanWaitlistData,
  isAba
}) => {
  const recommendationDate = carePlanWaitlistData?.createdDate
    ? moment(carePlanWaitlistData?.createdDate).format('MM/DD/YYYY')
    : 'N/A';
  const rss =
    carePlanWaitlistData?.SmartScheduling_status === null
      ? waitListSSStatus.null
      : waitListSSStatus[carePlanWaitlistData?.SmartScheduling_status];
  const receivingValue = isAba
    ? carePlanWaitlistData?.axonReceivingHours || 0
    : carePlanWaitlistData?.DTReceivingUnits || 0;
  return (
    <CarePlanWaitlistSSWrapper>
      <Grid>
        {rss?.toLocaleLowerCase() !== 'guaranteed-shift-match' ? (
          <>
            <Grid.Row>
              <Grid.Column width={5}>
                {!isWaitlistDataLoading ? (
                  <>
                    <div className={`status-${rss?.toLocaleLowerCase()}`}>
                      <span className="status-inner-cont">
                        {' '}
                        {rss?.split('-').join(' ')}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <Skeleton
                      variant="text"
                      children={
                        <div className="status-match">
                          <span className="status-inner-cont"> Match</span>
                        </div>
                      }
                    />
                  </>
                )}
              </Grid.Column>
              <Grid.Column width={10}>
                {!isWaitlistDataLoading ? (
                  <>
                    <div className="recommendation-date">
                      <span className="title">Recommendation Date:</span>{' '}
                      {recommendationDate}
                    </div>
                  </>
                ) : (
                  <>
                    <Skeleton
                      variant="text"
                      children={
                        <div className="recommendation-date">
                          <span className="title">Recommendation Date:</span>{' '}
                          <span>{recommendationDate}</span>
                        </div>
                      }
                    />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </>
        ) : (
          <>
            <Grid.Row>
              <Grid.Column width={10}>
                {!isWaitlistDataLoading ? (
                  <>
                    <div className={`status-${rss?.toLocaleLowerCase()}`}>
                      <span className="status-inner-cont">
                        {' '}
                        {rss?.split('-').join(' ')}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <Skeleton
                      variant="text"
                      children={
                        <div className="status-match">
                          <span className="status-inner-cont"> Match</span>
                        </div>
                      }
                    />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>
                {!isWaitlistDataLoading ? (
                  <>
                    <div className="recommendation-date">
                      <span className="title">Recommendation Date:</span>{' '}
                      {recommendationDate}
                    </div>
                  </>
                ) : (
                  <>
                    <Skeleton
                      variant="text"
                      children={
                        <div className="recommendation-date">
                          <span className="title">Recommendation Date:</span>{' '}
                          <span>{recommendationDate}</span>
                        </div>
                      }
                    />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        <Grid.Row>
          <Grid.Column>
            {!isWaitlistDataLoading ? (
              <>
                {' '}
                <div>
                  <span className="title">Care Plan Status: </span>{' '}
                  <span>{carePlanWaitlistData?.status__c || 'N/A'}</span>
                </div>
              </>
            ) : (
              <>
                <Skeleton
                  variant="text"
                  children={
                    <div>
                      <span className="title">Care Plan Status: </span>{' '}
                      <span>Waitlisted</span>
                    </div>
                  }
                />
              </>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {!isWaitlistDataLoading ? (
              <>
                <div>
                  <span className="title">Waitlist Reason: </span>{' '}
                  <span>
                    {carePlanWaitlistData?.waitlisted_reason__c || 'N/A'}
                  </span>
                </div>
              </>
            ) : (
              <>
                <Skeleton
                  variant="text"
                  children={
                    <div>
                      <span className="title">Waitlist Reason: </span>{' '}
                      <span>Provider Availability</span>
                    </div>
                  }
                />
              </>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            {!isWaitlistDataLoading ? (
              <>
                <div>
                  <span className="title">Receiving: </span>{' '}
                  <span>{receivingValue}</span>
                </div>
              </>
            ) : (
              <>
                <Skeleton
                  variant="text"
                  children={
                    <div>
                      <span className="title">Receiving: </span> <span>0</span>
                    </div>
                  }
                />
              </>
            )}
          </Grid.Column>
          <Grid.Column width={6}>
            {!isWaitlistDataLoading ? (
              <>
                <div>
                  <span className="title">Recommended: </span>{' '}
                  <span>{carePlanWaitlistData?.recommended__c || 0}</span>
                </div>
              </>
            ) : (
              <>
                <Skeleton
                  variant="text"
                  children={
                    <div>
                      <span className="title">Recommended: </span>{' '}
                      <span>0</span>
                    </div>
                  }
                />
              </>
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            {!isWaitlistDataLoading ? (
              <>
                <div>
                  <span className="title">Requested: </span>
                  <span>{carePlanWaitlistData?.requested__c}</span>
                </div>
              </>
            ) : (
              <>
                <Skeleton
                  variant="text"
                  children={
                    <div>
                      <span className="title">Requested: </span> <span>0</span>
                    </div>
                  }
                />
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CarePlanWaitlistSSWrapper>
  );
};
export default React.memo(CarePlanWaitlistSSInfo);
