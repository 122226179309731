import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import {
  ICalendarFilterOption,
  IRecurringSSFilter
} from 'model/calendar/filters';
import CalendarFilter from '../../../ui/calendarFilter';
import { SearchWrapper } from 'components/calendar/calendarFiltersAndSearch/style';
import searchIcon from 'assets/img/bars-search-search-bar.svg';
import { IClinic, IProvider, ISmartResultCard } from 'model/v2';
import ProviderSelectorSS from 'pages/MainCalendarPage/AppointmentForm/ProviderFilter/ProviderSelectorSS';
import { ISpecialty } from 'model/specialty';
import { DAY_OF_WEEKS } from 'utils/constants/lists';
import { TRANSPORTATION } from 'utils/constants/default';

interface Props {
  handleSearchedValues: (
    list: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  defaultDayOfWeeks: ICalendarFilterOption[];
  defaultLocations: ICalendarFilterOption[];
  setDefaultDayOfWeeks: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  setDefaultLocations: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  setFilterDropdownExpand: Dispatch<SetStateAction<any>>;
  smartResultsData: ISmartResultCard[];
  selectedProvidersList: Set<number>;
  setSelectedProvidersList: Dispatch<SetStateAction<Set<number>>>;
  checkedFilters: IRecurringSSFilter;
  setCheckedFilters: Dispatch<SetStateAction<IRecurringSSFilter>>;
  setDefaultClinics: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  deafultClinics: ICalendarFilterOption[];
  setDeafultSpecialities: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  deafultSpecialities: ICalendarFilterOption[];
  availableDayOfWeeks?: string[];
  availableLocations?: string[];
  setDefaultTransportation?: Dispatch<SetStateAction<ICalendarFilterOption[]>>;
  defaultTransportation?: ICalendarFilterOption[];
  isAba?: boolean;
}

const RecurringSSFilter: React.FC<Props> = ({
  handleSearchedValues,
  setCheckedFilters,
  defaultDayOfWeeks,
  setDefaultDayOfWeeks,
  defaultLocations,
  setDefaultLocations,
  setFilterDropdownExpand,
  smartResultsData,
  selectedProvidersList,
  setSelectedProvidersList,
  setDefaultClinics,
  deafultClinics,
  setDeafultSpecialities,
  deafultSpecialities,
  availableDayOfWeeks,
  availableLocations,
  checkedFilters,
  setDefaultTransportation,
  defaultTransportation,
  isAba
}: Props) => {
  const providersData = smartResultsData?.map(result => result.provider);
  const filteredProviders: IProvider[] = Array.from(
    new Set(providersData?.map(provider => provider?.id))
  )?.map(
    providerId =>
      providersData?.find(provider => provider?.id === providerId) as IProvider
  );
  const clinicData = smartResultsData
    ?.filter(result => result.isInClinic)
    .map(result => result.clinic);
  const filteredClinics: ICalendarFilterOption[] = Array.from(
    new Set(clinicData?.map(clinic => clinic?.id))
  )
    ?.map(
      clinicId => clinicData?.find(clinic => clinic?.id === clinicId) as IClinic
    )
    .map(clinic => ({
      label: clinic.name!,
      value: clinic.id!.toString()
    }));
  const specialityData = smartResultsData.map(
    result => result.provider.speciality
  );
  const filteredSpecialities: ICalendarFilterOption[] = Array.from(
    new Set(specialityData?.map(speciality => speciality?.abbreviation))
  )
    ?.map(
      abbreviation =>
        specialityData?.find(
          speciality => speciality?.abbreviation === abbreviation
        ) as ISpecialty
    )
    .map(speciality => ({
      label: speciality.abbreviation!,
      value: speciality.abbreviation!.toString()
    }));
  const transportationOptions: ICalendarFilterOption[] = TRANSPORTATION;
  const searchedClinics = useMemo(() => {
    return handleSearchedValues(filteredClinics);
  }, [handleSearchedValues, filteredClinics]);
  const searchedSpecialities = useMemo(() => {
    return handleSearchedValues(filteredSpecialities);
  }, [handleSearchedValues, filteredClinics]);
  const locations: ICalendarFilterOption[] = [
    {
      label: 'Telehealth',
      value: '1',
      disabled: availableLocations ? !availableLocations?.includes('1') : false
    },
    {
      label: 'In-Clinic',
      value: '2',
      disabled: availableLocations ? !availableLocations?.includes('2') : false
    },
    {
      label: 'Offsite',
      value: '4',
      disabled: availableLocations ? !availableLocations?.includes('4') : false
    }
  ];
  const searchedLocations = useMemo(() => {
    return handleSearchedValues(locations);
  }, [handleSearchedValues, locations]);
  let dayOfWeeks: ICalendarFilterOption[] = DAY_OF_WEEKS;
  dayOfWeeks = dayOfWeeks.map(dayOfWeek => {
    return {
      label: dayOfWeek.label,
      value: dayOfWeek.value,
      disabled: availableDayOfWeeks
        ? !availableDayOfWeeks?.includes(dayOfWeek.value)
        : false
    };
  });
  useEffect(() => {
    if (checkedFilters.isFiltersApplied) return;
    const selectedDayOfWeeks: ICalendarFilterOption[] = [];
    dayOfWeeks.map(dayOfWeek => {
      if (availableDayOfWeeks?.includes(dayOfWeek.value)) {
        selectedDayOfWeeks.push(dayOfWeek);
      }
    });
    if (checkedFilters.dayOfWeeks.length === 0)
      setDefaultDayOfWeeks(selectedDayOfWeeks);
  }, [availableDayOfWeeks, setDefaultDayOfWeeks, checkedFilters]);
  const searchedDayOfWeeks = useMemo(() => {
    return handleSearchedValues(dayOfWeeks);
  }, [handleSearchedValues, dayOfWeeks]);
  const notChecked = useCallback(
    (list: ICalendarFilterOption[], option: string): boolean => {
      return (
        list?.filter(listOption => listOption.value === option).length === 0
      );
    },
    []
  );

  const onLocationChange = useCallback(
    (checkedValues: CheckboxValueType[]) => {
      const selectedLocations: ICalendarFilterOption[] = [];
      locations.map(location => {
        if (checkedValues.includes(location.value)) {
          selectedLocations.push(location);
        }
      });
      setDefaultLocations(selectedLocations);
    },
    [setDefaultLocations, notChecked]
  );

  const onDayOfWeekChange = useCallback(
    (checkedValues: CheckboxValueType[]) => {
      const selectedDayOfWeeks: ICalendarFilterOption[] = [];
      dayOfWeeks.map(dayOfWeek => {
        if (checkedValues.includes(dayOfWeek.value)) {
          selectedDayOfWeeks.push(dayOfWeek);
        }
      });
      setDefaultDayOfWeeks(selectedDayOfWeeks);
    },
    [setDefaultDayOfWeeks, notChecked]
  );
  const onClinicChange = useCallback(
    (checkedValues: CheckboxValueType[]) => {
      const selecteClinics: ICalendarFilterOption[] = [];
      filteredClinics.map(clinic => {
        if (checkedValues.includes(clinic.value)) {
          selecteClinics.push(clinic);
        }
      });
      setDefaultClinics(selecteClinics);
    },
    [setDefaultClinics, notChecked]
  );
  const onTransportationChange = useCallback(
    (checkedValues: CheckboxValueType[]) => {
      const selectedValues: ICalendarFilterOption[] = [];
      transportationOptions.map(transportation => {
        if (checkedValues.includes(transportation.value)) {
          selectedValues.push(transportation);
        }
      });
      if (setDefaultTransportation) setDefaultTransportation(selectedValues);
    },
    [setDefaultTransportation, notChecked]
  );
  const onSpecialityChange = useCallback(
    (checkedValues: CheckboxValueType[]) => {
      const selecteSpecialities: ICalendarFilterOption[] = [];
      filteredSpecialities.map(speciality => {
        if (checkedValues.includes(speciality.value)) {
          selecteSpecialities.push(speciality);
        }
      });
      setDeafultSpecialities(selecteSpecialities);
    },
    [setDeafultSpecialities, notChecked]
  );
  const onApply = useCallback(() => {
    setCheckedFilters(prev => ({
      ...prev,
      locations: defaultLocations,
      dayOfWeeks: defaultDayOfWeeks,
      providers: selectedProvidersList,
      clinics: deafultClinics,
      specialities: deafultSpecialities,
      transportation: defaultTransportation,
      isFiltersApplied: true
    }));
    setFilterDropdownExpand((prevState: any) => ({
      ...prevState,
      filter: false
    }));
  }, [
    setCheckedFilters,
    defaultLocations,
    defaultDayOfWeeks,
    selectedProvidersList,
    deafultClinics,
    deafultSpecialities,
    defaultTransportation
  ]);
  const clearAll = useCallback(() => {
    setDefaultLocations([]);
    setDefaultDayOfWeeks([]);
    setDefaultClinics([]);
    setDeafultSpecialities([]);
    if (setDefaultTransportation) setDefaultTransportation([]);
    setSelectedProvidersList(() => {
      const newSet: Set<number> = new Set();
      return newSet;
    });
  }, [
    setDefaultLocations,
    setDefaultDayOfWeeks,
    setSelectedProvidersList,
    setDefaultTransportation
  ]);
  useEffect(() => {
    setDefaultLocations(prev =>
      prev.filter(location => availableLocations?.includes(location.value))
    );
  }, [availableLocations, setDefaultLocations]);
  return (
    <>
      <div className="scrollable_list">
        <SearchWrapper style={{ padding: '0px 5px' }}>
          <img
            src={searchIcon}
            className="search-icon"
            alt=""
            style={{ left: '10px' }}
          />
          <ProviderSelectorSS
            allProviders={filteredProviders || []}
            selectedProvidersList={selectedProvidersList}
            setSelectedProvidersList={setSelectedProvidersList}
          />
        </SearchWrapper>
        <CalendarFilter
          setCheckedList={setDefaultLocations}
          checkedList={defaultLocations}
          listOptions={searchedLocations}
          length={locations.length}
          title="Location"
          handleChange={onLocationChange}
        />

        <CalendarFilter
          setCheckedList={setDefaultDayOfWeeks}
          checkedList={defaultDayOfWeeks}
          listOptions={searchedDayOfWeeks}
          length={dayOfWeeks.length}
          title="Day Of Week"
          handleChange={onDayOfWeekChange}
        />
        <CalendarFilter
          setCheckedList={setDefaultClinics}
          checkedList={deafultClinics}
          listOptions={searchedClinics}
          length={filteredClinics.length}
          title="Clinics"
          handleChange={onClinicChange}
        />
        {isAba! && (
          <>
            <CalendarFilter
              setCheckedList={setDefaultTransportation!}
              checkedList={defaultTransportation!}
              listOptions={transportationOptions}
              length={transportationOptions.length}
              title="Transportation"
              handleChange={onTransportationChange}
            />
          </>
        )}
        <CalendarFilter
          setCheckedList={setDeafultSpecialities}
          checkedList={deafultSpecialities}
          listOptions={searchedSpecialities}
          length={filteredSpecialities.length}
          title="Specialities"
          handleChange={onSpecialityChange}
        />
      </div>
      <div className="calender-filters_actions">
        <span onClick={clearAll}>Clear all</span>
        <button
          onClick={onApply}
          disabled={false}
          className={'highlightButton'}
        >
          Apply
        </button>
      </div>
    </>
  );
};

export default React.memo(RecurringSSFilter);
