import React from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LOG_ERROR = `
  mutation LogError($message: String!, $context: LogErrorContext!) {
    logError(message: $message, context: $context)
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.logErrorToBackend(error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  async logErrorToBackend(error, errorInfo) {
    const logPayload = {
      message: error.message, // Error message
      stack: error.stack, // JavaScript stack trace
      componentStack: errorInfo.componentStack, // React component stack
      timestamp: new Date().toISOString(), // Timestamp
      route: window.location.pathname, // Current route
      userAgent: navigator.userAgent // Browser info
    };

    const variables = {
      message: logPayload.message,
      context: {
        stack: logPayload.stack,
        componentStack: logPayload.componentStack,
        timestamp: logPayload.timestamp,
        route: logPayload.route,
        userAgent: logPayload.userAgent
      }
    };

    fetch(process.env.REACT_APP_AWS_GRAPHQL_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: LOG_ERROR,
        variables
      })
    })
      .then(response => response.json())
      .then(data => {
        if (!data || data.errors) {
          console.error(
            'Failed to log error to backend:',
            data.errors || 'Unknown error'
          );
        } else if (data?.logError) {
          console.log('ErrorBoundary error logged successfully');
        }
      })
      .catch(loggingError => {
        console.error('Error during error logging fetch:', loggingError);
      });
  }

  render() {
    if (this.state.errorInfo) {
      toast.error('Error happened, Please contact administrator');
      return (
        <>
          <Redirect to="/planner" />
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
