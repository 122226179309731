import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import { useMutation } from 'react-apollo';
import { Button, Icon } from 'semantic-ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  APPOINTMENT_CATEGORY,
  GROUPED_CANCEL_OPTIONS
} from 'utils/constants/lists';
import { CANCEL_ADD_ADMIN_SCHEMA } from 'utils/validators/calendar';
import CancelReasonDropdown from 'pages/MainCalendarPage/AppointmentModals/CancelModal/CancelReasonDropdown';
import CancelNoteTextArea from 'pages/MainCalendarPage/AppointmentModals/CancelModal/CancelNoteTextArea';
import {
  selectedItemsToCancelAddAdminVar,
  updateCalendarPref
} from 'utils/cache/calendar';
import Content from 'views/components/ui/content';
import { useCancellationReasons } from 'hooks/CancellationReasonsHook';
import { EventType, ICalendarEvent } from 'model/calendar/events';
import {
  ADD_EVENTS,
  CANCEL_BULK_EVENTS,
  UPDATE_EVENT
} from 'api/graphql/v2/mutations/Event';
import { IEvent } from 'model/v2';
import { CancelModalWrapper } from 'pages/MainCalendarPage/AppointmentModals/style';
import BulkCancelWarn from './BulkCancelWarn';
import CancelAttendeeRow from 'pages/MainCalendarPage/AppointmentView/AttendeesView/CancelAttendeeRow';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { PURPLE } from 'utils/constants/theme';
import { teal } from '@material-ui/core/colors';
import { formatAttendeeName } from 'utils/format';
import { formatDates } from 'pages/MainCalendarPage/AppointmentView/TimeView';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import {
  ADMIN_DIRECT_APPT_IDS,
  ADMIN_IN_DIRECT_APPT_IDS
} from 'utils/constants/appointmentsTypes';

interface Props {
  onClose: () => void;
  saving: boolean;
  setSaving: (saving: boolean) => void;
  recurringBulkEventsCount: number;
  deleteWhenCancelAppointmentsIds: number[];
  observationOfLeadEvent: any;
  getLeadEventIdWithMasterId: any;
  ObservationCountOfSeriesFromLeadEventId: any;
}

interface IBulkCancelForm {
  recurrent: string;
  cancelReason: string | null;
  cancelNote?: string;
}

const CancelAddAdminForm: React.FC<Props> = ({
  onClose,
  saving,
  setSaving,
  deleteWhenCancelAppointmentsIds,
  observationOfLeadEvent,
  getLeadEventIdWithMasterId
}: Props) => {
  const recurrentRadio: IEvent[] = [];
  const [cancelBulkEvents] = useMutation(CANCEL_BULK_EVENTS);
  const [updateEvent] = useMutation(UPDATE_EVENT);
  const [addEvents] = useMutation(ADD_EVENTS);
  const selectedItemsToCancel = useReactiveVar(
    selectedItemsToCancelAddAdminVar
  );
  const { cancelReasons, isCancelReasonsLoading } = useCancellationReasons();
  const isAllSelectedAdmin = useMemo(() => {
    const selectedEvents = [...selectedItemsToCancel.values()];
    return selectedEvents.every(
      (event: ICalendarEvent) => event.type === EventType.adminEvent
    );
  }, [selectedItemsToCancel]);
  const includeEventsToCancel = useMemo(() => {
    const selectedEvents = [...selectedItemsToCancel.values()];
    selectedItemsToCancel.forEach((event: IEvent) => {
      recurrentRadio.push({ isObservation: event.isObservation });
    });

    return selectedEvents.some(
      (event: ICalendarEvent) =>
        !deleteWhenCancelAppointmentsIds?.includes(event?.appointmentType?.id!)
    );
  }, [selectedItemsToCancel, recurrentRadio, deleteWhenCancelAppointmentsIds]);
  const getSingleEventsNumber = useCallback(() => {
    const selectedEvents = [...selectedItemsToCancel.values()];
    let singleCancelEventsNumber = 0;
    let singleDeleteEventsNumber = 0;
    for (const event of selectedEvents) {
      if (event.recurrencePattern?.recurringEvery! === 0) {
        if (
          !deleteWhenCancelAppointmentsIds?.includes(
            event?.appointmentType?.id!
          )
        ) {
          singleCancelEventsNumber++;
        } else if (
          deleteWhenCancelAppointmentsIds?.includes(event?.appointmentType?.id!)
        ) {
          singleDeleteEventsNumber++;
        }
      }
    }
    const totalSingleEventsNumber =
      singleCancelEventsNumber + singleDeleteEventsNumber;
    return {
      singleCancelEventsNumber,
      singleDeleteEventsNumber,
      totalSingleEventsNumber
    };
  }, [deleteWhenCancelAppointmentsIds, selectedItemsToCancel]);

  const includeSingleCancelEvents = useMemo(() => {
    const { singleCancelEventsNumber } = getSingleEventsNumber();
    return singleCancelEventsNumber > 0;
  }, [getSingleEventsNumber]);

  const methods = useForm<IBulkCancelForm>({
    defaultValues: {
      recurrent: GROUPED_CANCEL_OPTIONS[0].value,
      cancelReason: null,
      cancelNote: undefined
    },
    resolver: yupResolver(CANCEL_ADD_ADMIN_SCHEMA())
  });

  const mappedSelectedItemsToCancel = useCallback(() => {
    const mappedSelectedItemsToCancel: IEvent[] = [];
    selectedItemsToCancel.forEach((event: ICalendarEvent) => {
      const mappedEvent = {
        id: event.id,
        duration: event.duration,
        recurrencePattern: event.recurrencePattern,
        appointmentType: { id: event.appointmentType?.id },
        isPendingConfirmation: event.isPendingConfirmation,
        ...(event.client?.id && { client: { id: event.client.id } })
      };
      mappedSelectedItemsToCancel.push(mappedEvent);
    });
    return mappedSelectedItemsToCancel;
  }, [selectedItemsToCancel]);

  const selectedItemsStartDate = useCallback(() => {
    const firstItemKey = mappedSelectedItemsToCancel()[0].id;
    return selectedItemsToCancel.get(firstItemKey!)?.startDate;
  }, [mappedSelectedItemsToCancel, selectedItemsToCancel]);

  const { recurrent } = methods.watch(['recurrent']);

  const isNotesAndReasonAppearing = useMemo(() => {
    if (
      recurrent === 'single' ||
      recurrent === 'all' ||
      recurrent === 'deleteSingle'
    )
      return includeEventsToCancel;
    else return includeSingleCancelEvents;
  }, [includeEventsToCancel, includeSingleCancelEvents, recurrent]);

  const [showWarnPopup, setShowWarnPopup] = useState(false);

  useEffect(() => {
    setShowWarnPopup(includeSingleCancelEvents && recurrent === 'all');
  }, [recurrent, includeSingleCancelEvents, observationOfLeadEvent]);

  const selectedEvents = [...selectedItemsToCancel.values()];
  const selectedEventObj =
    observationOfLeadEvent?.observationOfSeriesLeadEvent[0];
  const singleEventsObj = selectedEvents[0];
  const provider = singleEventsObj?.provider;
  const client = singleEventsObj?.client;
  const observations = selectedEventObj?.observations;
  const providerName = formatAttendeeName(
    provider?.firstName,
    provider?.lastName,
    provider?.clinic?.abbreviation,
    provider?.speciality?.abbreviation
  );

  const clientName = formatAttendeeName(
    client?.firstName,
    client?.lastName,
    client?.clinic?.abbreviation
  );
  const eventTimes = formatDates(
    singleEventsObj?.startDate,
    singleEventsObj?.endDate,
    singleEventsObj?.isObservation
  );
  const isABA = useMemo(
    () =>
      singleEventsObj?.appointmentType?.eventType?.name ===
      APPOINTMENT_CATEGORY[0].value,
    [singleEventsObj?.appointmentType?.eventType?.name]
  );

  let schedulingFeature: any = FEATURES.SHADOW_SCHEDULING;
  if (isABA) {
    schedulingFeature = FEATURES.ABA_SHADOW_SCHEDULING;
  }
  const ShadowFeatureAvailability = getFeatureAvailability(schedulingFeature);
  const submitForm = useCallback(
    (submittedData: IBulkCancelForm) => {
      setSaving(true);
      // Cancel and Add Admin

      let reqEvent = Array.from(selectedItemsToCancel?.values())![0];
      const mappedEvent = {
        id: reqEvent?.id,
        provider: {
          id: reqEvent?.provider?.id
        },
        clinic: {
          id: reqEvent?.provider?.clinic?.id
        },
        appointmentType: {
          id: ADMIN_DIRECT_APPT_IDS[process.env.REACT_APP_STAGE!] // Admin Direct Id
        },
        startDate: reqEvent?.startDate,
        endDate: reqEvent?.endDate,
        duration: reqEvent?.duration,
        recurrencePattern: { recurringUntil: null, recurringEvery: 0 }
      };
      updateEvent({
        variables: {
          data: {
            recurrent: false,
            event: mappedEvent,
            cancelNote: submittedData?.cancelNote
              ? submittedData?.cancelNote + '-Canceled and Added Admin'
              : 'Canceled and Added Admin',
            cancelReasonId: submittedData?.cancelReason,
            updatedObserversData: []
          }
        }
      })
        .then(() => {
          updateCalendarPref(moment(selectedItemsStartDate()));
          setSaving(false);
          onClose();
        })
        .catch(() => {
          setSaving(false);
        });
      let mappedEvents: any[] = [];
      if (observations?.length > 0) {
        observations?.map((observer: any) => {
          const mappedEvent = {
            provider: {
              id: observer?.provider?.id
            },
            clinic: {
              id: observer?.provider?.clinic?.id
            },
            appointmentType: {
              id: ADMIN_IN_DIRECT_APPT_IDS[process.env.REACT_APP_STAGE!] // Admin Indirect Id
            },
            startDate: observer?.startDate,
            endDate: observer?.endDate,
            duration: observer?.duration,
            recurrencePattern: { recurringUntil: null, recurringEvery: 0 }
          };
          mappedEvents.push(mappedEvent);
        });
        addEvents({
          variables: { events: mappedEvents }
        })
          .then(() => {})
          .catch(() => {});
      }
    },
    [
      observations,
      cancelBulkEvents,
      updateEvent,
      addEvents,
      isAllSelectedAdmin,
      mappedSelectedItemsToCancel,
      onClose,
      selectedItemsStartDate,
      setSaving
    ]
  );

  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);
  return (
    <CancelModalWrapper>
      <Content loading={isCancelReasonsLoading} data={true} fullHeight={true}>
        {() => (
          <>
            <h2 className="cancel-modal-title">
              Confirm Cancel and Add Admin Appointment
            </h2>
            {observations &&
            !recurrentRadio[0]?.isObservation &&
            ShadowFeatureAvailability &&
            observations.length > 0 &&
            selectedItemsToCancel.size < 2 ? (
              <>
                <p
                  className="cancel-modal-cotent"
                  style={{ paddingBottom: '40px' }}
                >
                  Canceling this event will remove the appointment for the
                  provider, patient and observers and will create an Admin
                  Direct for the provider, Admin Indirect for the Observers.
                </p>
              </>
            ) : (
              ''
            )}
            {ShadowFeatureAvailability &&
            ((getLeadEventIdWithMasterId?.SingleEventObservations > 0 &&
              selectedItemsToCancel.size > 1) ||
              (recurrentRadio[0]?.isObservation &&
                getLeadEventIdWithMasterId?.SingleEventObservations > 0)) ? (
              <>
                <p
                  className="cancel-modal-cotent"
                  style={{ marginBottom: '18px' }}
                >
                  Some selected appointments may include observers. Please
                  review before canceling.
                </p>
              </>
            ) : (
              ''
            )}
            <FormProvider {...methods}>
              {selectedItemsToCancel.size < 2 &&
              !recurrentRadio[0]?.isObservation ? (
                <>
                  <div className="cancel-modal-attendees-div">
                    <p
                      className="cancel-modal-attendees"
                      style={{ paddingBottom: '15px' }}
                    >
                      Attendees
                    </p>
                    {provider && (
                      <CancelAttendeeRow
                        eventType={selectedEventObj?.type!}
                        displayName={providerName}
                        displayId={provider.id}
                        icon={<EyeOutlined />}
                        iconTitle={'Provider'}
                        color={PURPLE}
                        classNameVar={'provider-btn'}
                      />
                    )}
                    {client && (
                      <CancelAttendeeRow
                        eventType={selectedEventObj?.type!}
                        displayName={clientName}
                        displayId={client.id}
                        icon={<UserOutlined />}
                        iconTitle={'Patient'}
                        color={teal[100]}
                        classNameVar={'provider-btn'}
                      />
                    )}
                    {ShadowFeatureAvailability &&
                    singleEventsObj?.observationsCount !== undefined &&
                    singleEventsObj?.observationsCount > 0
                      ? observations?.map(
                          (
                            observer: {
                              provider: {
                                id: any;
                                firstName: any;
                                lastName: any;
                                clinic: { abbreviation: any };
                                speciality: { abbreviation: any };
                              };
                            },
                            index: number
                          ) => {
                            const observerName = formatAttendeeName(
                              observer.provider?.firstName,
                              observer.provider?.lastName,
                              observer.provider?.clinic?.abbreviation,
                              observer.provider?.speciality?.abbreviation
                            );
                            return (
                              <CancelAttendeeRow
                                eventType={selectedEventObj?.type!}
                                displayName={observerName}
                                displayId={observer.provider?.id}
                                icon={<EyeOutlined />}
                                iconTitle={`Observer ${index + 1}`}
                                color={PURPLE}
                                classNameVar={`provider-btn observer${index +
                                  1}-btn`}
                              />
                            );
                          }
                        )
                      : ''}
                    {eventTimes ? (
                      <>
                        <p className="cancel-modal-attendees">Event Details</p>
                        <p
                          className="event-date"
                          style={{ fontSize: '12px', marginBottom: '5px' }}
                        >
                          <Icon name="calendar outline" className="time-icon" />
                          {eventTimes?.date}
                        </p>
                        <p className="event-time" style={{ fontSize: '12px' }}>
                          <Icon name="clock outline" className="time-icon" />
                          {eventTimes?.time}
                        </p>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              ) : (
                ' '
              )}
              {/* {recurrentRadio[0]?.isObservation &&
              getLeadEventIdWithMasterId?.SingleEventObservations > 0 ? (
                <p className="observer-warning">This contain Observer</p>
              ) : null} */}
              {/* {!recurrentRadio[0]?.isObservation &&
              getLeadEventIdWithMasterId?.SingleEventObservations > 0 &&
              recurrent === 'single' ? (
                <p className="observer-warning">
                  Some selected appointments may include observers. Please
                  review before canceling.
                </p>
              ) : null} */}
              {/* {!recurrentRadio[0]?.isObservation &&
              ObservationCountOfSeriesFromLeadEventId
                ?.getObservationCountOfSeriesFromLeadEventId[0] > 0 &&
              recurrent === 'all' ? (
                <p className="observer-warning">
                  Some selected appointments may include observers. Please
                  review before canceling.
                </p>
              ) : null} */}
              {isNotesAndReasonAppearing && (
                <>
                  <BulkCancelWarn
                    showWarnPopup={showWarnPopup}
                    setShowWarnPopup={setShowWarnPopup}
                  />
                  <CancelReasonDropdown
                    cancelReasons={cancelReasons}
                    isDeleteOrAdminEvent={false}
                  />
                  <CancelNoteTextArea />
                </>
              )}
              <div className="modal-actions">
                <Button onClick={onClose} basic disabled={saving}>
                  No, GO Back
                </Button>
                <Button
                  negative
                  onClick={methods.handleSubmit(submitForm, submitError)}
                  loading={saving}
                  disabled={saving}
                >
                  Yes, Cancel
                </Button>
              </div>
            </FormProvider>
          </>
        )}
      </Content>
    </CancelModalWrapper>
  );
};

export default CancelAddAdminForm;
